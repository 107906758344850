@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300..700&display=swap");

$main-font: "Comfortaa", sans-serif;
$main-color: #ffffff;
$blue-color: #0078ae;
$grey-color: #0f0c0cc7;
$black-color: #000000;
$greytextcolor: #323232;
$greecolor: #9fad2f;
$lightbluecolor: #2bb0bf;
$pinkcolor: #c054e5;
$yellowcolor: #e9a609;
$offwhitecolor: #ede9de;
$yellowimg: #ffd22f;
$greydeepcolor: #2e2e2e;
$footerbgcolr: #070707;
$bggrey: #e9e9e9;
$bgcolor: #ffffff;
$greyvieocolor: #efefef;
$bordercolor: #d7d1d1;
$projetovrlaycolor: #000000a3;
$accorcolor: #232323;
$accorbgcolor: #e0ecf0;
$colortextfiled: #606060;
$redcolor: #cf0000;
$successcolor: #039703;
$bgcolor: #ffffff;

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body {
  font-family: $main-font !important;
  background-color: $main-color !important;
  color: $greytextcolor;
}

.navbar-expand-lg .navbar-collapse {
  justify-content: flex-end;
}

.nav-link {
  position: relative;
  display: flex;
  padding: 11px 20px 12px 20px !important;
  font-weight: 700;
  color: $grey-color !important;
  text-transform: uppercase;
  font-size: 15px;
  line-height: 21px !important;
}

// .nav-link::after {
//   content: "|";
//   display: block;
//   width: 0;
//   height: 2px;
//   background: $main-color;
//   transition: width 0.3s;
//   position: absolute;
//   right: 4px;
//   top: 8px;
// }

// .nav-last:after {
//   display: none;
// }

.main-sec-header {
  box-shadow: 0 -6px 6px 5px rgba(0, 0, 0, 0.5);
  background-color: $bgcolor;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.161);
  /* display: flex; */
  /* display: inline-block; */
  margin-bottom: -6px;
  // padding: 17px 25px 6px;
  position: fixed;
  width: 100%;
  z-index: 999;
}

.navbar-brand {
  img {
    height: 28px;
    width: 100%;
  }
}

.nav-last::after {
  display: none;
}

.nav-link:hover {
  color: $blue-color !important;
}

.nav-link.active {
  color: $blue-color !important;
}

.btn-call {
  background-color: $blue-color;
  color: $main-color;
  border-radius: 24px;
  font-size: 15px;
  padding: 11px 20px 8px 20px;
  line-height: 19px;
  border: 1px solid $blue-color;
  box-shadow: 3px 4px 3px #4d4d4d52 !important;

  img {
    height: 18px;
    position: relative;
    top: -2px;
    margin-right: 7px;
    // &:hover{
    //     color: $blue-color;
    // }
  }

  &:hover {
    background-color: $blue-color;
    color: $main-color;
    border: 1px solid $blue-color;
  }
}

.navbar-expand-lg .navbar-nav {
  margin-top: 5px;
}

.navbar {
  padding-top: 12px;
  padding-bottom: 12px;
}

.main-sec-home {
  // background: url(../img/banner-img.png) center/cover;
  // place-items: center;
  // text-align: center;
  // height:75vh;
  //  background: url(../img/banner-img.png) center/cover;
  height: 100%;
  background-size: cover;

  position: absolute;
  left: 0;
  top: 60px;
  width: 100%;
  // background: url("../img/7851255567_banner-img.png");
}

.main-sec-homes {
  width: 100%;
  position: relative;
  height: 75vh;
  overflow: hidden;
}

.banner-content {
  position: absolute;
  bottom: 17vh;
  max-width: 820px;
  left: 16%;
  text-align: left;

  h1 {
    color: $black-color;
    font-size: 87px;
    text-shadow: 2px 6px #80808099;
    font-weight: 800;
  }

  h2 {
    color: $main-color;
    font-size: 87px;
    text-shadow: 2px 4px rgb(128 128 128 / 23%);
    font-weight: 800;
    margin-bottom: 35px;
  }

  p {
    color: $main-color;
    margin-bottom: 0px;
    font-size: 33px;
    width: 100%;
    line-height: 52px;
    font-weight: 400;
  }
}

.service-desc-sec {}

.service-desc-inner {
  min-height: calc(70vh - 200px);
  border-left: 1px solid #e0e0e0;
  position: relative;
  border-right: 1px solid #e0e0e0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s ease;
}

.service-desc-sec {
  position: relative;
  display: block;
  padding: 80px 0px 60px 0px;
}

.service-desc-inner {
  h3 {
    color: $greytextcolor;
    font-size: 19px;
    margin: 15px 10px 25px 10px;
    font-weight: 800;
    line-height: 32px;

    p {
      font-size: 16px;
      line-height: 27px;
      font-weight: 600;
    }
  }
}

.no-padding {
  padding: 0px;
}

.no-padding-right {
  padding-right: 0px;
}

.no-padding-left {
  padding-left: 0px;
}

.service-heads-sec {
  width: 100%;
  max-width: 180px;
  text-align: center;
  margin: 0 auto !important;
  margin-bottom: 25px !important;
  margin-top: 11px !important;
}

.service-heads-third {
  width: 100%;
  max-width: 224px;
  text-align: center;
  margin: 0 auto !important;
  margin-bottom: 25px !important;
  margin-top: 11px !important;
}

.service-heads-four {
  width: 100%;
  max-width: 224px;
  text-align: center;
  margin: 0 auto !important;
  margin-bottom: 25px !important;
  margin-top: 11px !important;
}

.service-desc-inner {
  p {
    font-size: 16px;
    line-height: 27px;
    font-weight: 600;
    width: 100%;
    max-width: 314px;
    margin: 0 auto;
  }

  img {
    margin-bottom: 10px;
  }
}

.service-one-line {
  cursor: pointer;

  &:after {
    position: absolute;
    content: "";
    width: 3px;
    height: 80px;
    background: $greecolor;
    left: -2px;
    top: 90px;
  }

  // &:hover{

  //         background:  $greecolor;;

  // }
}

.service-two-line {
  &:after {
    position: absolute;
    content: "";
    width: 3px;
    height: 80px;
    background: $lightbluecolor;
    left: -2px;
    bottom: 70px;
  }
}

.service-three-line {
  &:after {
    position: absolute;
    content: "";
    width: 3px;
    height: 80px;
    background: $pinkcolor;
    left: -2px;
    top: 90px;
  }
}

.service-four-line {
  &:after {
    position: absolute;
    content: "";
    width: 3px;
    height: 80px;
    background: $yellowcolor;
    left: -2px;
    bottom: 70px;
  }
}

.service-sec {
  padding-bottom: 50px;
}

.service-head {
  h2 {
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 70px;
    margin-top: 50px;
  }
}

.service-inner-sec {
  width: 100%;
  position: relative;
  display: block;

  .service-iiner {
    width: 19%;
    float: left;
    position: relative;

    &:after {
      content: " ";
      display: block;
      width: -1px;
      height: 146px;
      background: $main-color;
      transition: width 0.3s;
      position: absolute;
      right: 0px;
      top: 8px;
      border-right: 1px dashed $greytextcolor;
    }
  }

  h3 {
    font-size: 19px;
    margin-top: 20px;
    line-height: 27px;
    font-weight: 600;
  }

  img {
    height: 60px;
  }
}

.service-iiner:last-child {
  &:after {
    display: none;
  }
}

.service-sec {
  background-image: url("../img/bg.png");

  background-size: 100% 105%;
  padding: 1rem;
  margin-top: 40px;
  background-repeat: no-repeat;
}

.service {
  margin-bottom: 70px;
}

.pre-expended-secs {
  width: 100%;
  position: relative;
  display: block;
}

.pre-expended-sec {
  background-image: url("../img/prep-expanded.jpg");

  padding: 1rem;

  background-repeat: no-repeat;
  height: 30vh;
}

.pre-expended-inner {
  position: absolute;
  bottom: 40px;
  text-align: center;
  left: 31%;
  transform: translate(-22%, -50%);

  p {
    color: $offwhitecolor;
    font-size: 39px;
    font-weight: 500;
    position: relative;
    line-height: 55px;

    &::after {
      content: "";
      display: block;
      width: 70px;
      height: 2px;
      background: $main-color;
      margin-top: 20px;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 50%);
    }
  }
}

.project-inner-sec {
  position: relative;
  width: 100%;
  display: block;

  img {
    height: 320px;
    width: 100%;
  }

  h3 {
    position: absolute;
    bottom: 84px;
    text-align: left;
    left: 20px;
    font-size: 30px;
    font-weight: 500;
    color: $bgcolor;
    line-height: 47px;
    width: 100%;
    max-width: 350px;
  }
}

.home-project-sec {
  width: 100%;
  position: relative;
  display: inline-block;
  margin-top: 7px;
}

.project-sec {
  margin-top: 64px;
}

.padding-sec {
  padding-left: 5px;
  padding-right: 5px;
}

.padding-sec-lft {
  padding-left: 0px;
}

.padding-sec-rft {
  padding-right: 0px;
}

.project-sec-width {
  width: 24.7%;
  float: left;
  margin-right: 5px;
  cursor: pointer;

  &:last-child {
    margin-right: 0px;
  }
}

.button-sec {
  margin-top: 20px;
  margin-bottom: 20px;
}

.btn-sec {
  background-color: $blue-color;
  color: $main-color;
  border-radius: 24px;
  font-size: 15px;
  padding: 14px 20px 12px 20px;
  line-height: 19px;
  border: 1px solid $blue-color;
  box-shadow: 3px 4px 3px rgba(77, 77, 77, 0.3215686275) !important;

  &:hover {
    background-color: $main-color;
    color: $blue-color;
    border: 1px solid $blue-color;
  }
}

.vision-sec {
  margin-top: 90px;
  margin-bottom: 90px;
}

.vision-img {
  height: 95px;
  margin: 0 auto;
  text-align: right;
  float: right;
  padding-top: 10px;
  position: relative;
  top: -3px;
}

.vision-inner {
  text-align: left;

  h2 {
    text-align: left;
    font-size: 32px;
    font-weight: 700;
    color: $greytextcolor;
  }

  h3 {
    font-size: 19px;
    color: $greytextcolor;
  }

  p {
    font-size: 16px;
    color: $greytextcolor;
    line-height: 26px;
    font-weight: 500;
  }
}

.bg-only {
  background-image: url("../img/bg.png");
  background-size: 100% 105%;
  background-repeat: no-repeat;
}

.partner-inner {
  background-image: url("../img/partner_img.png");
  background-size: 100% 105%;
  background-repeat: no-repeat;
  // height: 52vh;
  text-align: left;
  position: relative;
  display: block;
  color: $main-color;
  padding: 170px 50px 135px 90px;

  h2 {
    font-size: 28px;
    line-height: 34px;
  }

  h3 {
    font-size: 20px;
    line-height: 20px;
    font-weight: 300;
    margin-top: 15px;

    &::after {
      content: "";
      display: block;
      width: 70px;
      height: 2px;
      background: $main-color;
      margin-top: 30px;
    }
  }

  p {
    margin-bottom: 0px;
    font-size: 17px;
    line-height: 27px;
    font-weight: 200;
    margin-top: 30px;
  }
}

.talk-inner {
  background-image: url("../img/talk-us-img.png");
  background-size: 100% 105%;
  background-repeat: no-repeat;
  // height: 52vh;
  text-align: left;
  position: relative;
  display: block;
  color: $main-color;
  padding: 180px 50px 155px 90px;

  h2 {
    color: $greytextcolor;
    font-size: 30px;
    line-height: 52px;
    margin-bottom: 20px;
    width: 100%;
    max-width: 520px;
  }

  .callltoactn {
    text-decoration: none;
    cursor: pointer;

    span {
      margin-left: 11px;
      position: relative;
      top: 4px;
      font-weight: 700;
      color: $greytextcolor;
      font-size: 19px;
    }
  }
}

.no-paddings {
  padding-left: 0px;
  padding-right: 0px;
}

.unique-sec {
  background-image: url("../img/bg.png");

  // background-size: 140% 125%;
  background-repeat: no-repeat;
}

.footer-sec {
  background-color: $footerbgcolr;
  color: $offwhitecolor;
  padding: 80px 0px 10px 0px;

  .footer-left {
    float: left;
    text-align: left;

    img {
      text-align: left;
      height: 28px;
    }

    p {
      margin-bottom: 0px;
      margin-top: 20px;
      font-size: 16px;
      font-weight: 300;
      line-height: 34px;
      width: 100%;
      max-width: 340px;
    }
  }

  .footer-center {
    h3 {
      font-size: 21px;
      text-align: left;
    }

    ul {
      padding-left: 0px;
      list-style-type: none;
      text-align: left;
      margin-top: 28px;

      li {
        margin-bottom: 8px;

        a {
          font-size: 16px;
          color: $offwhitecolor;
          cursor: pointer;
          text-decoration: none;

          span {
            font-size: 16px;
            margin-left: 10px;
            font-weight: 300;
          }
        }
      }
    }
  }

  .footer-mid-center {
    h3 {
      font-size: 21px;
      text-align: left;
    }

    ul {
      padding-left: 0px;
      list-style-type: none;
      text-align: left;
      margin-top: 28px;

      li {
        margin-bottom: 8px;

        a {
          font-size: 16px;
          color: $offwhitecolor;
          text-decoration: none;

          span {
            font-size: 16px;
            margin-left: 10px;
            font-weight: 300;
          }
        }
      }
    }
  }

  .footer-right {
    h3 {
      font-size: 21px;
      text-align: left;
    }

    ul {
      padding-left: 0px;
      list-style-type: none;
      text-align: left;
      margin-top: 28px;

      li {
        margin-bottom: 8px;

        a {
          font-size: 16px;
          color: $offwhitecolor;
          text-decoration: none;

          img {
            height: 20px;
            position: relative;
            top: -4px;
          }

          span {
            font-size: 16px;
            margin-left: 7px;
          }
        }
      }
    }
  }
}

.hr-sec {
  margin: 70px 0px 50px 0px;
}

.footer-bottom-left {
  text-align: left;

  p {
    margin-bottom: 0px;
    font-weight: 400;
    font-size: 14px;
  }
}

.footer-bottom-right {
  ul {
    padding-left: 0px;
    list-style-type: none;
    display: inline-block;

    li {
      float: left;
      font-size: 14px;

      &:after {
        content: "|";
        margin: 0px 10px;
        color: $offwhitecolor;
      }

      &:last-child {
        &:after {
          display: none;
        }
      }

      a {
        color: $offwhitecolor;
        text-decoration: none;
      }
    }
  }
}

.project-acn-sec {
  .proj-actn-left {
    float: left;
  }

  .proj-actn-right {}
}

.project-acn-sec-main {

  // position: relative;
  // width: 100%;
  // display: inline-block;
  .project-acn-sec {
    width: 100%;
    position: absolute;
    display: block;
    bottom: 30px;
    left: 0px;
    color: $bgcolor;
    opacity: 0;
    padding-left: 26px;

    .proj-actn-left {
      cursor: pointer;

      a {
        span {
          margin-right: 6px;
        }
      }
    }

    .proj-actn-right {
      cursor: pointer;
      float: right;
      right: 20px;
      position: relative;

      a:hover {
        color: $bgcolor !important;
      }

      a {
        text-decoration: none;
        color: $bgcolor !important;

        span {
          margin-right: 6px;
        }

        i {
          position: relative;

          bottom: -1px;
        }
      }
    }
  }
}

.project-inner-sec:hover .project-acn-sec {
  opacity: 1;
  cursor: pointer;
}

.unique-sec {
  margin-top: 90px;
  padding-bottom: 40px;
  margin-bottom: 0px;
}

// about page code start

.about-sec-banner {
  // background: url(../img/banner-img.png) center/cover;
  // place-items: center;
  // text-align: center;
  // height:75vh;
  //  background: url(../img/about-us-banner.jpg) center/cover;
  height: 100%;
  background-size: cover;
  position: absolute;
  left: 0;
  top: 0px;
  width: 100%;
  background-position: 90% 60%;
}

.about-main {
  height: 44vh;
  top: 65px;
}

.abut-banner-cont {
  text-shadow: none;
  font-size: 71px;
  color: $main-color;

  p {
    font-size: 30px;
  }

  h1 {
    color: $main-color;
    text-shadow: none;
    font-size: 69px;
    line-height: 85px;
  }
}

.expert-sec {
  width: 100%;
  position: relative;
  display: block;
  padding: 65px 20px 80px 20px;

  h2 {
    font-size: 38px;
    font-weight: 600;
    margin-bottom: 65px;
    margin-top: 70px;
  }

  img {
    width: auto;
  }

  p {
    font-size: 26px;
    font-weight: 600;
    line-height: 44px;
    margin-top: 30px;
  }
}

.servic-visi {
  font-size: 24px;
  color: $greytextcolor;
  line-height: 25px;
  font-weight: 500;
  text-align: center;
  line-height: 37px;
  margin-bottom: 0px;
  margin-top: 0px;
  padding: 70px 0px 60px 0px;
  background-color: $bggrey;
}

.servic-inner-sec {
  p {
    font-size: 24px;
    color: $greytextcolor;
    line-height: 42px;
    text-align: center;
  }
}

.servic-btn-sec {
  text-align: center;
}

.servic-visi {
  background-image: url("../img/bg-about.png");

  background-size: 100% 125%;
  background-repeat: no-repeat;
}

.servic-njsmart-sec {
  width: 30%;
  height: 100%;
  border: 1px solid #d6d3df;
  height: 43.6vh;

  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;

  flex-direction: column;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  font-size: 16px;
  float: left;

  cursor: pointer;
  background-color: transparent;
  padding: 0px 10px 0px 10px;

  a {
    img {
      display: block;
      padding: 0px 14px 0px 10px;
    }

    p {
      margin-bottom: 0px;
      text-align: left;
      font-weight: 700;
      font-size: 15px;
      padding: 60px 2px 12px 11px;
    }
  }
}

.servic-other-sec {
  width: 70%;
}

.servic-dis-sec {
  padding: 130px 0px;
  background-image: url("../img/bg.png");
  background-size: 100% 105%;

  margin-top: 40px;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;
  display: inline-block;
  margin-top: 70px;

  h2 {
    font-size: 50px;
    text-align: left;
    line-height: 66px;
    padding-top: 75px;
  }
}

.servics-sec {
  width: 100%;
  position: relative;
  display: flex;
}

.gap-sec {
  float: left;
  border: 1px solid #d6d3df;
  height: 21vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 10px 16px 10px 18px;
  margin-left: 20px;
  margin-right: 20px;
  width: 45%;
  margin-bottom: 15px;

  a {
    text-align: left;
    display: block;
    color: $greytextcolor;
    text-decoration: none;

    p {
      margin-bottom: 0px;
      text-align: left;
      font-weight: 600;
      font-size: 15px;
      padding: 10px 11px 0px 0px;
    }
  }
}

.servic-njsmart-sec {
  a {
    color: $greytextcolor;
    text-decoration: none;
  }
}

.eff-sec {
  float: none;
}

.case-banner-below {
  padding: 80px 0px;
  margin-top: 64px;
}

.interven-sec {
  background-color: $greytextcolor;
  color: $main-color;

  h3 {
    float: left;
    padding: 60px 0px 50px 0px;
    margin-bottom: 0px;
    font-size: 28px;
  }

  img {
    position: relative;
    top: -40px;
    height: 135px;
    right: 0px;
  }
}

.new-imagess {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
}

.case-banner-below-inner {
  h3 {
    font-size: 36px;
  }
}

.case-stud-sec {
  padding: 80px 0px;
}

.case-stud-sec-inner {
  h3 {
    font-size: 40px;
    margin-bottom: 130px;
    font-weight: 600;
  }
}

.case-stud-data-sec {
  width: 100%;
  position: relative;
  display: block;

  .case-stud-icon-sec {
    width: 100%;

    .case-img {
      background-color: $blue-color;
      width: 50px;
      height: 50px;
      float: left;

      img {
        position: relative;
        top: 8px;
        height: 33px;
        left: 2px;
      }
    }
  }
}

.case-heading {
  float: left;
  border-top: 50px solid $blue-color;
  border-right: 40px solid transparent;
  height: 0;
  width: 250px;
  position: relative;
  display: inline-block;
  margin-left: 10px;

  h4 {
    position: absolute;
    bottom: 2px;
    left: 17px;
    font-size: 22px;
    color: $main-color;
    font-weight: 500;
  }
}

.case-stud-data {
  text-align: left;
  font-size: 19px;
  margin-top: 28px;
  color: $greytextcolor;

  p {
    margin-bottom: 0px;
    font-size: 18px;
    line-height: 33px;
    font-weight: 500;
  }
}

.margn-case {
  margin-top: 60px;
}

.bg-sec-new {
  background-image: url("../img/bg.png");
  background-size: 100% 105%;
  background-repeat: no-repeat;
}

.bg-sec-newss {
  background-image: url("../img/bg.png");
  background-size: 100% 105%;
  background-repeat: no-repeat;
}

.watch-video-sec {
  padding: 60px 0px 100px 0px;
  width: 100%;
  position: relative;
  display: block;

  .watcch-inner-sec {
    width: 100%;
    position: relative;
    display: block;

    .watch-sec {
      width: 47%;
      float: left;
      background-color: $greyvieocolor;
      padding: 91px 0px;
      position: relative;
      top: -4px;
      box-shadow: 0 3px 14px rgb(0 0 0 / 12%), 0 2px 6px rgb(0 0 0 / 12%);

      p {
        font-size: 52px;
        color: $greytextcolor;
        font-weight: 500;
        padding: 20px 30px;
        font-weight: 700;
      }
    }

    .watch-video-play {
      width: 53%;
      float: left;

      .watch-vid-play-inner {
        align-items: center;
        display: flex;
        height: 390px;
        justify-content: center;
        position: relative;
        width: 100%;
        box-shadow: 0 3px 14px rgba(0, 0, 0, 0.12),
          0 2px 6px rgba(0, 0, 0, 0.12);

        &::before {
          .video-inner-sec:before {
            background: #81818166;
            bottom: 0;
            content: "";
            display: block;
            height: 319px;
            left: 0;
            left: 50%;
            position: absolute;
            right: 0;
            top: 0;
            transform: translate(-50%);
            width: 680px;
          }
        }

        .video-react-poster {
          background-color: #000;
          background-position: 50% 50%;
          background-repeat: no-repeat;
          background-size: cover;
          bottom: 0;
          cursor: pointer;
          display: inline-block;
          height: 100%;
          left: 0;
          margin: 0;
          padding: 0;
          position: absolute;
          right: 0;
          top: 0;
          vertical-align: middle;

          &::before {
            background: #29292966;
            bottom: 0;
            content: "";
            display: block;
            height: 100%;
            left: 0;
            left: 50%;
            position: absolute;
            right: 0;
            top: 0;
            transform: translate(-50%);
            width: 780px;
          }
        }
      }
    }

    .watch-video-play {
      .watch-vid-play-inner {
        .media-sec {
          width: 100%;
        }
      }
    }
  }
}

.video-react-button {
  background-color: $bgcolor;
  border-radius: 50%;
  height: 100px;
  width: 100px;
  border: none;
}

.video-react-big-play-button {
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.video-react .video-react-big-play-button {
  background-color: #2b333f;
  background-color: #2b333fb3;
  border: 0.06666em solid $bgcolor;
  border-radius: 0.3em;
  cursor: pointer;
  display: block;
  font-size: 3em;
  height: 1.5em;
  left: 10px;
  line-height: 1.5em;
  opacity: 1;
  padding: 0;
  position: absolute;
  top: 10px;
  transition: all 0.4s;
  width: 3em;
}

.video-react-button {
  &::after {
    color: $blue-color;
    font-size: 32px;
    position: relative;
    top: 2px;
    content: "\f04b";
    font-family: "FontAwesome";
    right: -3px;
  }

  &::before {
    cursor: pointer;
    display: block;
    height: 126px;
    position: absolute;
    width: 126px;
    transform: matrix(1, 0, 0, 1, -48, -48);
    transform-origin: 68px 68px;
    content: "";
    border: 2px solid $main-color;
    border-radius: 50%;
    margin: 0px -64.3199996948px -24px 0px;
    transition: border 0.15s ease 0s, box-shadow 0.15s ease 0s;
    background-color: transparent;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -31%);
  }
}

.video-react-control-text {
  clip: rect(0 0 0 0);
  border: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.bg-sec-new-video {
  background-image: url("../img/bg.png");
  background-size: 100% 105%;
  background-repeat: no-repeat;
}

.case-btn-sec {
  margin-top: 70px;
}

// modal code start here
.modal-casestudy {
  .MuiDialog-container {
    .MuiPaper-root {
      margin: 0px;
      width: 100%;
      max-width: 680px;
      background-color: $bgcolor;
      border: 2px solid $blue-color;
      border-radius: 8px;
      padding: 50px 25px 45px 25px;
      overflow: initial !important;

      .MuiButtonBase-root {
        position: absolute;
        top: -30px;
        right: -20px;
        box-shadow: 3px 4px 3px rgba(77, 77, 77, 0.3215686275) !important;
        background-color: $bgcolor;
        width: 70px;
        height: 70px;

        svg {
          color: $blue-color;
          font-size: 39px;
        }
      }

      .MuiDialogContent-root {
        border-top: none !important;
        border-bottom: none !important;
        padding: 0px !important;
        overflow-y: visible !important;

        p {
          color: $greytextcolor !important;
          font-size: 23px;
          font-weight: 700;
          font-family: $main-font !important;
          margin-bottom: 20px;
          text-align: center;
          margin-bottom: 0px;
        }

        h2 {
          font-size: 30px;
          font-weight: 700;
          line-height: 40px;
        }

        .button-sec {
          margin: 0 auto;
          text-align: center;
          margin-top: 30px;

          .btn-yes {
            width: 105px;
            margin-right: 10px;
          }

          .btn-no {
            width: 105px;
            background: $bgcolor;
            color: $blue-color;

            &:hover {
              background: $blue-color;
              color: $bgcolor;
            }
          }
        }
      }
    }
  }
}

.modal-yes .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .button-sec .btn-yes {
  width: 100% !important;
  font-size: 18px !important;
}

.button-case {
  margin-top: 40px;
}

.modal-yes {
  .MuiDialog-container {
    .MuiPaper-root {
      .MuiDialogContent-root {
        p {
          margin-top: 10px !important;
          font-weight: 500;
          margin-bottom: 20px;
        }
      }
    }
  }
}

.form-modal {
  .MuiBox-root {
    .MuiFormControl-root {
      label {
        font-family: $main-font !important;
      }

      .MuiInputBase-root {
        .MuiInput-input {
          font-family: $main-font !important;
        }
      }
    }
  }
}

.modal-yes {
  .MuiDialog-container {
    .MuiPaper-root {
      margin: 0px;
      width: 100%;
      max-width: 530px;
      padding: 40px 40px 35px 40px;
    }
  }
}

.modal-no {
  .MuiDialog-container {
    .MuiPaper-root {
      margin: 0px;
      width: 100%;
      max-width: 655px;
      padding: 50px 50px 45px 50px;
    }
  }
}

.modal-no {
  .MuiDialog-container {
    .MuiPaper-root {
      .MuiDialogContent-root {
        p {
          margin-top: 10px !important;
          font-weight: 600;
          margin-bottom: 10px;
          font-size: 23px;
        }
      }
    }
  }
}

// modal code end here

.career-sec {
  padding: 0px 0px 90px 0px;
}

.project-accodion-sec {
  .MuiPaper-root {
    font-family: $main-font !important;
    margin-bottom: 15px;
    background-color: transparent;
    border: none !important;

    .MuiButtonBase-root {
      background-color: $accorbgcolor;
      border: 1px solid $accorbgcolor;

      .MuiAccordionSummary-content {
        width: 100%;

        .MuiTypography-root {
          width: 100%;

          .acordian-head {
            width: 100%;
            position: relative;
            display: block;

            .acordian-head-left {
              img {
                float: left;
                height: 27px;
                transform: rotate(-86deg);
                position: relative;
                top: 12px;
                margin-right: 16px;
                transition: transform 0.4s ease;
              }

              p {
                float: left;
                color: $blue-color;
                font-weight: 600;
                font-size: 23px;
                position: relative;
                top: 10px;
              }
            }

            .acordian-head-right {
              float: right;

              p {
                font-family: $main-font !important;
                margin-bottom: 0px;
                text-align: right;
                color: $accorcolor;
                font-size: 16px;
                font-weight: 600;
              }
            }
          }
        }

        p {
          font-family: $main-font !important;
          margin-bottom: 0px;
          text-align: right;
        }
      }

      .MuiAccordionSummary-expandIconWrapper {
        svg {
          display: none;
        }
      }
    }

    .MuiCollapse-root {
      background-color: transparent;

      .MuiCollapse-wrapper {
        .MuiCollapse-wrapperInner {
          .MuiAccordion-region {
            .MuiAccordionDetails-root {
              border: none !important;

              p {
                font-family: $main-font !important;
              }
            }
          }
        }
      }
    }
  }
}

.MuiAccordionSummary-content.Mui-expanded {
  .MuiTypography-root {
    .acordian-head {
      .acordian-head-left {
        img {
          transform: rotate(326deg) !important;
        }
      }
    }
  }
}

.accordion-inner-para {
  margin-top: 20px;

  p {
    font-size: 17px;
    text-align: left;
    font-weight: 500;
  }

  h3 {
    font-size: 22px;
    text-align: left;
    font-weight: 600;
    margin-top: 45px;

    &:after {
      content: "";
      display: block;
      width: 70px;
      height: 3px;
      background: $greytextcolor;
      margin-top: 10px;
    }
  }
}

.roles-desc-sec {
  ul {
    list-style-type: none;
    padding-left: 0px;
    text-align: left;
    margin-top: 40px;

    li {
      margin-bottom: 15px;

      img {
        float: left;
        position: relative;
        top: 5px;
      }

      span {
        display: flex;
        font-size: 17px;
        margin-left: 30px;
        line-height: 30px;
        font-weight: 500;
      }
    }
  }
}

.job-sec {
  margin-top: 50px;
}

.career-para {
  p {
    margin-bottom: 0px;
    font-size: 33px;
    font-weight: 500;
    width: 100%;
    max-width: 1250px;
    line-height: 58px;
  }
}

.career-new {
  background-image: url("../img/bg.png");
  background-size: 100% 105%;
  background-repeat: no-repeat;
}

.apply-sec {
  p {
    float: left;
  }

  .apply-btn {
    display: block;
    margin-top: -9px;
    float: left;
    margin-left: 30px;
  }
}

.contact-details {
  padding: 90px 0px;
  margin-top: 64px;

  .contact-info {
    background-color: $blue-color;
    color: $bgcolor;
    text-align: left;
    border-radius: 5px;
    padding: 60px 35px 20px 33px;
    width: 107%;
    float: left;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    margin-top: 130px;
    float: left;
    left: 50px;
    position: relative;

    h2 {
      font-size: 20px;
      margin-bottom: 60px;

      &:after {
        content: "";
        display: block;
        width: 70px;
        height: 3px;
        background: $bgcolor;
        margin-top: 10px;
        position: absolute;
      }
    }

    p {
      margin-bottom: 27px;

      span {
        margin-left: 10px;
      }

      a {
        color: $bgcolor;
        text-decoration: none;
      }

      i {
        position: relative;
        top: 4px;
      }
    }

    .margin-cont-top {
      margin-top: 40px;
      display: flex;
    }
  }
}

.img-call {
  height: 18px;
}

.social-icon {
  text-align: center;
  position: relative;
  margin-top: 100px;

  img {
    position: absolute;
    bottom: 0;
  }
}

.contact-heading {
  h2 {
    font-size: 35px;
    color: $greytextcolor;
    font-weight: 600;
    margin-bottom: 50px;
  }
}

.contact-form {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  padding: 40px 80px;
  width: 100%;
  /* float: right; */
  max-width: 1240px;
  background-color: $main-color;
}

.form-sec {
  font-family: $main-font !important;
  margin-top: 18px;

  .MuiBox-root {
    .MuiFormControl-root {
      width: 100% !important;
      margin: 0px !important;
      padding-right: 0px !important;

      label {
        font-family: $main-font !important;
        color: $colortextfiled;
        font-size: 15px !important;
      }

      .MuiInputBase-root {
        .MuiInputBase-input {
          font-family: $main-font !important;
          color: $black-color !important;
        }
      }
    }
  }
}

.submit-sec {
  float: left;
  margin-top: 30px;

  .btn-sec {
    padding: 14px 30px 11px 30px;
    line-height: 21px;
  }
}

.location-sec {
  margin-top: 0px;
}

.MuiFormLabel-root.Mui-focused {
  color: $blue-color !important;
}

.MuiInputBase-root:after {
  border-bottom: 2px solid $blue-color !important;
}

.MuiFormLabel-filled {
  font-weight: 700 !important;
  font-size: 17px !important;
  color: $blue-color !important;
}

.career-newbg {
  background-image: url("../img/bg.png");
  background-size: 100% 110%;
  background-repeat: repeat;
}

.career-new-bg {
  background-image: url("../img/bg.png");

  background-repeat: repeat;
}

.video-main-secs {
  background-color: $bgcolor;
  border-radius: 50%;
  height: 100px;
  width: 100px;
  border: none;
  box-shadow: 1px 3px 3px rgba(77, 77, 77, 0.3215686275) !important;
}

video {
  &:before {
    background: rgba(41, 41, 41, 0.4);
    bottom: 0;
    content: "";
    display: block;
    height: 100%;
    left: 0;
    left: 50%;
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(-50%);
    width: 780px;
  }
}

.custom-play-button {
  background-color: transparent;
  border: 3px solid $bgcolor;
  border-radius: 50%;
  padding: 10px;
}

.video-cse {
  opacity: 0.8;
}

.custom-play-button {
  background-color: transparent !important;
  border: 2px solid $bgcolor !important;
}

// .roles-desc-sec ul li{
//     &:before{

//     content: "";
//     width: 19px;
//     height:19px;
//     background: url(../img//check-right.png) no-repeat;
//   background-size: contain;

//     display: inline-block;
//     }
// }

.img-sc {
  height: 20px;
  position: relative;
  top: 4px;
  background-image: url("../img/check-right.png");
  background-repeat: no-repeat;
  width: 20px;
  float: left;
  margin-bottom: 0px;
}

.cloverpa {
  opacity: 1 !important;
}

.modal-watch {
  .MuiDialog-container {
    .MuiPaper-root {
      padding: 30px 20px 16px 20px !important;
    }
  }
}

.modal-casestudy .MuiDialog-container .MuiPaper-root .MuiButtonBase-root {
  top: -30px;
  right: -30px;
}

/* STRAT - Pre Loader CSS */
// .loader-wrapper {
//     position: fixed;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     z-index: 10;
//     overflow: hidden;
//     z-index: 9999;
//   }
//   .loader-wrapper .loader-section {
//     position: fixed;
//     top: 0;
//     width: 51%;
//     height: 100%;
//     background: $bgcolor;
//     z-index: 10;
//   }
//   .loader-wrapper .loader-section.section-left {
//     left: 0;
//   }
//   .loader-wrapper .loader-section.section-right {
//     right: 0;
//   }

//   .loader {
//     display: block;
//     position: relative;
//     left: 50%;
//     top: 50%;
//     width: 150px;
//     height: 150px;
//     margin: -75px 0 0 -75px;
//     border-radius: 50%;
//     border: 3px solid transparent;
//     border-top-color: #16a085;
//     animation: spin 1.7s linear infinite;
//     z-index: 11;
//   }
//   .loader:before {
//     content: "";
//     position: absolute;
//     top: 5px;
//     left: 5px;
//     right: 5px;
//     bottom: 5px;
//     border-radius: 50%;
//     border: 3px solid transparent;
//     border-top-color: #e74c3c;
//     animation: spin-reverse 0.6s linear infinite;
//   }
//   .loader:after {
//     content: "";
//     position: absolute;
//     top: 15px;
//     left: 15px;
//     right: 15px;
//     bottom: 15px;
//     border-radius: 50%;
//     border: 3px solid transparent;
//     border-top-color: #f9c922;
//     animation: spin 1s linear infinite;
//   }

//   .loaded .loader-wrapper {
//     visibility: hidden;
//     transform: translateY(-100%);
//     transition: all 0.3s 1s ease-out;
//   }
//   .loaded .loader-wrapper .loader-section.section-left {
//     transform: translateX(-100%);
//     transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
//   }
//   .loaded .loader-wrapper .loader-section.section-right {
//     transform: translateX(100%);
//     transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
//   }
//   .loaded .loader {
//     opacity: 0;
//     transition: all 0.3s ease-out;
//   }

//   @keyframes spin {
//     0% {
//       transform: rotate(0deg);
//     }
//     100% {
//       transform: rotate(360deg);
//     }
//   }
//   @keyframes spin-reverse {
//     0% {
//       transform: rotate(0deg);
//     }
//     100% {
//       transform: rotate(-360deg);
//     }
//   }
/* END - Pre Loader CSS */

.custom-play-button {
  position: absolute;
  /* Position it absolutely within the MediaPlayer */
  top: 50%;
  /* Center vertically */
  left: 50%;
  /* Center horizontally */
  transform: translate(-50%,
      -50%);
  /* Adjust the position to be perfectly centered */

  background: none;
  /* Remove any background */
  border: none;
  /* Remove any borders */
  z-index: 10;
  /* Ensure it's above other elements */
}

.custom-play-button svg {
  fill: $blue-color;
  position: absolute;
  left: 50%;
  transform: translate(-38%, 76%);
}

.proj-sec-para {
  padding: 80px 0px 85px 0px;

  p {
    margin-bottom: 0px;
    font-size: 37px;
    font-weight: 500;
    width: 100%;
    max-width: 1250px;
    line-height: 61px;
  }
}

// project page section
.item-project img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}

.project-overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: $blue-color;
  overflow: hidden;
  width: 100%;
  height: 100%;
  transition: 0.5s ease;
  border-radius: 10px;
}

.item-project {
  position: relative;
  overflow: hidden;
  margin: 10px 0;
  cursor: pointer;
}

.item-project:hover .project-overlay {
  bottom: 0;
  height: 100%;
  width: 100%;
}

.top-overlay {
  bottom: 100%;
  height: 0;
}

.item-project:hover .top-overlay {
  bottom: 0;
}

.fade-overlay {
  height: 100%;
  opacity: 0;
}

.item:hover .fade-overlay {
  opacity: 1;
}

.title-overlay {
  height: auto;
  top: auto;
  opacity: 0;
}

.item:hover .title-overlay {
  opacity: 1;
  height: auto;
  padding: 50px 0;
}

.text-projt {
  color: $bgcolor;
  font-size: 1.2vw;
  position: absolute;
  top: 10%;
  left: 7%;
  /* transform: translate(-50%, -50%); */
  text-align: center;
  right: 5%;

  p {
    font-size: 16px;
    text-align: left;
    width: 100%;
    line-height: 30px;
  }
}

.overlay-main {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* border-radius: 10px; */
  background-color: $projetovrlaycolor;
  display: block;
  justify-content: left;
  align-items: end;
  border-radius: 10px;
  bottom: 0;
}

.overlay-text {
  color: $bgcolor;
  font-size: 19px;
  text-align: left;
  padding: 10px 22px 30px 22px;
  position: absolute;
  bottom: 0;
}

.main-projt-watch {
  position: absolute;
  // bottom: 30px;
  top: 200px;
  width: 100%;
  padding: 0px 0px;
  transition: 0.8s ease;
  opacity: 0;

  .projet-over-left {
    float: left;
    color: $bgcolor;

    a {
      font-size: 15px;
      cursor: pointer;

      i {
        margin-left: 5px;
        top: 2px;
        position: relative;
      }
    }
  }

  .projet-over-right {
    float: right;
    color: $bgcolor;

    a:hover {
      color: $bgcolor !important;
    }

    a {
      text-decoration: none;
      color: $bgcolor !important;
      font-size: 15px;
      cursor: pointer;

      i {
        margin-left: 5px;
        top: 2px;
        position: relative;
      }
    }
  }
}

.item-project:hover .main-projt-watch {
  opacity: 1;
}

.multi-proj-sec {
  padding-bottom: 100px;
}

.margin-captch {
  margin-top: 20px;
  margin-left: -5px;
}

.invalid-feedback {
  width: 100% !important;
  text-align: left;
  position: relative;
  margin: 0px 0px 0px 11px !important;

  .error-msg {
    position: absolute;
    color: $redcolor;
    font-size: 14px;
    padding-top: 3px;
  }
}

.mob-no {
  display: block;
}

.navbar-collapse {
  border-top: none;
  margin-top: 0px;
}

.footer-bottom-right {
  float: right;
}

// responsive code start

@media only screen and (max-width: 1400px) {
  .bok-iner-head h2 {
    font-size: 25px !important;
    line-height: 35px !important;
    margin-bottom: 30px !important;
  }

  body .final-modal .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .new-demo-head {
    margin-top: 3px !important;
  }

  .bg-book {
    padding: 60px 0px 90px 0px !important;
  }

  .servicess-inner-para p {
    font-size: 18px !important;
    line-height: 34px !important;
  }

  .point-sec-service {
    padding: 120px 0px 60px 0px !important;
  }

  .servicess-divid .srvicess-right h2 {
    font-size: 30px !important;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .button-case {
    margin: 0 auto;
    text-align: center;
    margin-top: 30px !important;
    padding-bottom: 0px;
    margin-bottom: 0px;
    display: block;
  }

  .main-sec-homes {
    width: 100%;
    position: relative;
    height: 90vh;
    overflow: hidden;
  }

  .banner-content {
    position: absolute;
    bottom: 15vh;
    max-width: 820px;
    left: 8%;
    text-align: left;
  }

  .pre-expended-inner {
    position: absolute;
    bottom: -10px;
    text-align: center;
    left: 31%;
    transform: translate(-22%, -50%);

    p {
      color: $offwhitecolor;
      font-size: 39px;
      font-weight: 500;
      position: relative;
      line-height: 55px;

      &::after {
        content: "";
        display: block;
        width: 70px;
        height: 2px;
        background: $main-color;
        margin-top: 20px;
        position: absolute;
        left: 50%;
        transform: translate(-50%, 50%);
      }
    }
  }

  .vision-inner {
    text-align: left;

    h2 {
      text-align: left;
      font-size: 33px;
      font-weight: 700;
      color: $greytextcolor;
    }

    h3 {
      font-size: 19px;
      color: $greytextcolor;
    }

    p {
      font-size: 16px;
      color: $greytextcolor;
    }
  }
}

@media only screen and (max-width: 320px) {
  .home-project-sec {
    padding: 20px 10px 0px 10px !important;
  }

  .project-inner-sec h3 {
    max-width: 270px;
    font-size: 21px !important;
    line-height: 35px !important;
  }

  .project-sec-width {
    margin-bottom: 10px !important;
  }

  .unique-sec .partner-sec .partner-inner h2 {
    font-size: 17px !important;
  }

  .partner-sec .talk-inner h2 {
    font-size: 18px !important;
    line-height: 36px !important;
  }

  .expert-sec .row p {
    font-size: 15px !important;
    line-height: 27px !important;
    padding: 0px 3px !important;
  }

  .vision-sec {
    padding: 15px 13px !important;
  }

  .vision-inner {
    text-align: left;
    margin-top: 30px !important;
    padding: 20px 0px !important;
  }

  body .servic-visi {
    padding: 20px 0px 20px 0px !important;
  }

  .servic-dis-sec .servic-dis-inner h2 {
    font-size: 18px !important;
  }

  body .proj-sec-para p {
    font-size: 16px !important;
    line-height: 29px !important;
    padding-top: 0px !important;
    margin-bottom: 10px !important;
    padding: 0px 6px !important;
  }

  .overlay-text {
    padding: 10px 10px 30px 10px !important;
    font-size: 16px !important;
  }

  body .case-banner-below .case-banner-below-inner h3 {
    font-size: 17px !important;
  }

  .interven-sec img {
    right: 10px !important;
    height: 60px !important;
  }

  .case-stud-sec {
    padding: 40px 0px 40px 0px !important;
  }

  .case-heading {
    width: 215px !important;
    border-top: 45px solid #0078ae !important;
  }

  .case-stud-data-sec .case-stud-icon-sec .case-img img {
    position: relative;
    top: 8px;
    height: 29px;
    left: 2px;
  }

  .case-stud-data-sec .case-stud-icon-sec .case-img {
    width: 45px;
    height: 45px;
  }

  .case-heading h4 {
    font-size: 17px !important;
    bottom: 3px !important;
  }

  body .main-sec-homes .banner-content h1 {
    font-size: 28px !important;
  }

  body .main-sec-homes .banner-content h2 {
    font-size: 28px !important;
  }

  body .contact-form {
    padding: 20px 20px 30px 20px !important;
  }

  .watch-video-sec .watcch-inner-sec .mobile-watch {
    background-color: transparent;
    padding: 13px 0px !important;
    box-shadow: none;
  }

  .watch-video-sec .watcch-inner-sec .mobile-watch p {
    font-size: 27px !important;
    padding: 0px 30px !important;
    margin-bottom: 5px;
  }

  .watch-video-sec .watcch-inner-sec .watch-video-play .watch-vid-play-inner .media-sec {
    width: 100% !important;
    height: auto !important;
  }

  .desk-watch {
    display: none !important;
  }

  .mobile-watch {
    display: block !important;
  }

  .service-btn-sec .sev-mob-btn {
    padding: 9px 14px 7px 14px !important;
    font-size: 13px;
  }

  .service-mob-banner {
    bottom: 4vh !important;
  }

  .service-banner .service-btn-sec {
    margin-top: 3px;
  }

  .main-sec-homes .service-mob-banner h1 {
    font-size: 25px !important;
    line-height: 33px !important;
  }

  .leader-sec a {
    font-size: 16px !important;
  }

  .prev-sec-service {
    width: 100%;
    position: relative;

    display: block;
    margin-bottom: 14px;
    font-size: 14px !important;
  }

  .btn-ser-explre {
    margin-top: 40px !important;
  }

  .ser-page-sec {
    font-size: 15px !important;
  }

  .prev-sec-service {
    margin-right: 0px !important;
  }

  .final-modal .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .MuiTypography-root .form-sec-main .form-radio-sec .MuiFormControl-root label .MuiRadio-root {
    margin-right: 5px !important;
  }

  body .final-modal .MuiDialog-container .MuiPaper-root {
    max-width: 300px !important;
  }

  body .thankyou-sec .thank-you-inner {
    width: 100%;
    max-width: 280px !important;
    padding: 28px 22px !important;
  }

  .thankyou-sec h2 {
    font-size: 23px !important;
    margin: 15px 0px 8px 0px !important;
  }

  .thankyou-sec p {
    font-size: 15px !important;
    line-height: 23px !important;
  }

  .vasestremco {
    font-size: 12px !important;
    line-height: 47px !important;
    color: #0078ae !important;
    margin-top: 20px !important;
  }

  .thankyou-sec {
    padding: 114px 0px 55px 0px !important;
  }

  .thank-you-inner svg {
    width: 85px;
    height: 85px;
  }

  .thankyou-sec p {
    font-size: 14px !important;
    line-height: 25px !important;
  }

  .vasestremco {
    font-size: 12px !important;
    line-height: 47px !important;
    color: #0078ae !important;
    margin-top: 20px !important;
  }

}


@media only screen and (max-width: 340px) {
  body .thankyou-sec p {
    font-size: 14px !important;
    line-height: 25px !important;
  }

  body .vasestremco {
    font-size: 11px !important;
    line-height: 47px !important;
    color: #0078ae !important;
    margin-top: 20px !important;
  }

  body .thankyou-sec .thank-you-inner {
    width: 100%;

    max-width: 295px !important;
    padding: 28px 12px !important;
  }

  body .thankyou-sec h2 {
    font-size: 23px !important;
    margin: 13px 0px 9px 0px !important;
  }

  .thankyou-sec p {
    font-size: 15px !important;
    line-height: 23px !important;
  }

  .vasestremco {
    font-size: 11px !important;
    line-height: 47px !important;
    color: #0078ae !important;
    margin-top: 20px !important;
  }

  .thankyou-sec {
    padding: 114px 0px 55px 0px !important;
  }

  .thank-you-inner svg {
    width: 85px;
    height: 85px;
  }

  .final-modal .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .MuiTypography-root .form-sec-main .form-radio-sec .MuiFormControl-root label .MuiRadio-root {
    margin-right: 5px !important;
  }

  .service-btn-sec .sev-mob-btn {
    padding: 10px 15px 9px 15px !important;
    font-size: 14px;
  }

  .watch-video-sec .watcch-inner-sec .mobile-watch {
    background-color: transparent;
    padding: 13px 0px !important;
    box-shadow: none;
  }

  body .watch-video-sec .watcch-inner-sec .mobile-watch p {
    font-size: 21px !important;
    padding: 0px 0px !important;
    margin-bottom: 5px;
  }

  .watch-video-sec .watcch-inner-sec .watch-video-play .watch-vid-play-inner .media-sec {
    width: 100% !important;
    height: auto !important;
  }

  .desk-watch {
    display: none !important;
  }

  .mobile-watch {
    display: block !important;
  }

  .recaptcha-sec {
    iframe {
      transform: scale(0.77);
      -webkit-transform: scale(0.77);
      transform-origin: 0 0;
      -webkit-transform-origin: 0 0;
    }
  }

  .service-sec .service-desc-sec {
    padding: 50px 10px 50px 10px !important;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root .MuiButtonBase-root {
    right: -9px !important;
  }

  .partner-sec .partner-inner {
    padding: 40px 20px 45px 20px !important;
  }

  .vision-sec {
    padding: 0px 10px !important;
  }

  .navbar-brand img {
    height: 21px;
  }

  body .expert-sec .row h2 {
    font-size: 18px !important;
    line-height: 35px;
  }

  .expert-sec {
    padding: 65px 15px 10px 15px !important;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root .MuiDialogContent-root p {
    font-size: 14px !important;
  }

  .navbar-brand img {
    height: 23px !important;
  }

  body .MuiDialog-container .MuiPaper-root {
    max-width: 290px !important;
  }

  body .modal-cases .MuiDialog-container .MuiPaper-root .MuiDialogContent-dividers {
    padding: 10px 5px !important;
    max-width: 290px !important;
  }

  .connect-sec-new .contact-info-new .mail-sec-new {
    margin-bottom: 10px !important;
  }

  body .final-modal .MuiDialog-container .MuiDialog-paperScrollPaper {
    max-width: 315px !important;
  }

  body .final-modal .MuiDialog-scrollPaper .MuiPaper-root .MuiDialogContent-root {
    padding: 10px 10px !important;
  }
}

@media only screen and (max-width: 360px) {
  body .thankyou-sec p {
    font-size: 14px !important;
    line-height: 25px !important;
  }

  body .vasestremco {
    font-size: 11px !important;
    line-height: 47px !important;
    color: #0078ae !important;
    margin-top: 20px !important;
  }

  .tooltipnew .tooltiptext {
    right: -7px !important;
  }

  .overlay-text {
    font-size: 16px !important;
  }

  .navbar-brand {
    padding: 0px 2px !important;
  }

  .service-desc-sec .service-desc-inner h3 {
    font-size: 17px;
    margin: 15px 20px 17px 20px;
    line-height: 29px;
  }

  .service .service-head h2 {
    font-size: 22px !important;
  }

  .pre-expended-sec-new .pre-expended-inner-new p {
    font-size: 17px !important;
    line-height: 31px !important;
  }

  body .modal-casestudy .MuiDialog-container .MuiPaper-root {
    max-width: 300px !important;
    padding: 15px 15px 10px 15px !important;
  }

  .project-inner-sec h3 {
    line-height: 34px !important;
    font-size: 20px !important;
  }

  .vision-inner h3 {
    font-size: 18px;
    line-height: 27px;
    margin-top: 12px;
  }

  .unique-sec .partner-sec .partner-inner h2 {
    font-size: 18px !important;
  }

  .unique-sec .partner-sec .partner-inner p {
    font-size: 15px;
    line-height: 26px;
  }

  .unique-sec .vision-inner h2 {
    font-size: 20px !important;
  }

  body .footer-sec .footer-left img {
    text-align: left;
    height: 20px;
  }

  .project-overlay .main-projt-watch {
    top: 149px !important;
  }

  .main-projt-watch .projet-over-left a {
    font-size: 14px !important;
    cursor: pointer;
  }

  .main-projt-watch .projet-over-right a {
    font-size: 14px !important;
    cursor: pointer;
  }

  body .career-new-bg .career-para p {
    font-size: 15px !important;
    line-height: 27px !important;
    padding: 0px 12px !important;
  }

  .vision-sec {
    padding: 10px 15px !important;
  }

  .footer-sec {
    padding: 50px 5px 10px 5px !important;
  }

  .project-overlay .main-projt-watch {
    top: 139px !important;
  }

  .main-projt-watch .projet-over-left a {
    font-size: 13px !important;
    cursor: pointer;
  }

  .main-projt-watch .projet-over-right a {
    font-size: 13px !important;
    cursor: pointer;
  }

  .text-projt p {
    font-size: 14px !important;
    line-height: 23px;
  }

  .footer-sec .footer-width .footer-center h3 {
    font-size: 16px !important;
  }

  .case-banner-below .case-banner-below-inner h3 {
    font-size: 19px !important;
  }

  .interven-sec img {
    right: 0px;
    height: 63px !important;
  }

  .case-stud-sec .row .case-stud-sec-inner h3 {
    font-size: 16px !important;
  }

  .case-heading h4 {
    font-size: 16px !important;
    bottom: 5px !important;
  }

  .case-stud-data p {
    font-size: 14px !important;
    line-height: 26px !important;
  }

  .video-main-secs {
    height: 55px !important;
    width: 55px !important;
  }

  .custom-play-button svg {
    height: 23px !important;
    transform: translate(-43%, 76%);
  }

  .custom-play-button {
    top: 39% !important;
  }

  .case-btn-sec {
    margin-top: 30px !important;
  }

  .footer-sec .footer-right h3 {
    font-size: 16px !important;
  }

  .footer-sec .footer-mid-center h3 {
    font-size: 16px !important;
  }

  .job-sec {
    margin-top: 35px;
  }

  .apply-sec .apply-btn {
    margin-left: 0px !important;
  }

  .contact-form {
    padding: 20px 20px 35px 20px !important;
  }

  .contact-details {
    padding: 20px 0px 40px 0px !important;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root {
    margin: 0px;
    width: 100%;
    max-width: 340px !important;
    padding: 16px 15px 21px 15px !important;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root .MuiDialogContent-root h2 {
    font-size: 21px;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root .MuiDialogContent-root p {
    font-size: 15px !important;
    margin-bottom: 0px !important;
    margin-top: 10px !important;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .button-sec {
    margin-top: 15px;
  }

  .modal-yes .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .button-sec .btn-yes {
    width: 100% !important;
    font-size: 15px !important;
  }

  .form-sec .MuiBox-root .MuiFormControl-root label {
    font-size: 14px;
  }

  .btn-sec {
    padding: 10px 19px 8px 19px;
  }

  .recaptcha-sec iframe {
    transform: scale(0.77);
    -webkit-transform: scale(0.8);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }

  .contact-details .contact-heading h2 {
    margin-top: 20px !important;
  }

  .service-desc-new .service-headss p {
    font-size: 16px !important;
    line-height: 28px !important;
    margin-bottom: 1px !important;
  }

  .head-para {
    font-size: 17px !important;
  }

  .loader {
    margin: 60% auto !important
  }

  .view-proj .text-projt p .view-proj-sec {
    font-size: 14px !important;
  }

  body .servics-sec .servic-njsmart-sec-new {
    height: 24.3vh !important;
  }

  body .servic-other-sec .gap-new-sec {
    height: 23vh !important;
  }

  .connect-sec-new .contact-info-new .mail-sec-new {
    margin-bottom: 10px !important;
  }

  .service-btn-sec .sev-mob-btn {
    padding: 9px 14px 7px 14px !important;
    font-size: 13px;
  }

  .service-mob-banner {
    bottom: 4vh !important;
  }

  .service-banner .service-btn-sec {
    margin-top: 3px;
  }

  .main-sec-homes .service-mob-banner h1 {
    font-size: 25px !important;
    line-height: 33px !important;
  }

  .leader-sec a {
    font-size: 16px !important;
  }

  .ser-page-sec .prev-sec-service {
    width: 100%;
    position: relative;

    display: block;
    margin-bottom: 14px;
    font-size: 15px !important;
  }

  .btn-ser-explre {
    margin-top: 40px !important;
  }

  .ser-page-sec {
    font-size: 15px !important;
  }

  .ser-page-sec .next-sec-service {
    font-size: 15px !important;
  }

  .prev-sec-service {
    margin-right: 0px !important;
  }

  body .final-modal .MuiDialog-scrollPaper .MuiPaper-root .MuiDialogContent-root {
    padding: 10px 10px !important;
  }

  .otp-modal-new .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .otp-head {
    font-size: 15px !important;
    line-height: 24px !important;
  }

  .otp-modal-new .MuiDialogContent-root .validate-sec-mode .error-msg-sec .error-msg {
    font-size: 14px !important;
    margin-top: 5px !important;
  }

  .otp-modal-new .MuiDialog-container .MuiPaper-root {
    width: 100% !important;
    max-width: 350px !important;
  }

  .otp-modal-new .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .button-case {
    margin-top: 20px !important;
  }

  .otp-modal-new .MuiDialogContent-root .validate-sec-mode .error-msg-sec .error-msg {
    font-size: 13px !important;
  }

  .otp-modal-new .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .btn-otp .btn-sub {
    font-size: 15px !important;
    padding: 10px 0px 7px 0px !important;
    margin-right: 0px !important;
  }

  .otp-form .otp-sec-input {
    padding: 8px 0px !important;
    margin-right: 14px !important;
  }

  .otp-main-secss {
    width: 100% !important;
    max-width: 270px !important;
    margin: 0 auto !important;
  }

  .otp-modal-new .MuiDialogContent-root .otp-main-secss .width-otp .error-msg-sec .error-msg {
    font-size: 13px !important;
  }

  .otp-modal-new .MuiDialog-container .MuiPaper-root .MuiDialogContent-root p {
    margin-top: 0px !important;
  }

  body .otp-modal-new .MuiDialog-scrollPaper .MuiPaper-elevation .MuiDialogContent-dividers {
    padding: 10px 20px !important;
  }

  body .otp-modal-new .MuiDialog-container .MuiPaper-root {
    max-width: 322px !important;
  }

  body .otp-modal-new .MuiDialog-container .MuiPaper-root .MuiButtonBase-root {
    right: -11px !important;
  }

  body .otp-modal-new .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .otp-head {
    font-size: 14px !important;
    line-height: 24px !important;
  }

  body .otp-modal-new .MuiDialog-container .MuiPaper-root {
    max-width: 300px !important;
    padding: 15px 15px 10px 15px !important;
  }

  body .modal-thanku-connet .MuiDialog-container .MuiPaper-root {
    max-width: 300px !important;
    padding: 21px 11px 21px 11px !important;
  }

  .modal-thanku-connet .MuiDialog-container .MuiPaper-root .MuiDialogContent-root p {
    font-size: 14px !important;
    line-height: 22px !important;
  }

  body .ser-page-sec a {
    font-size: 14px !important;
  }

  .thankyou-sec .thank-you-inner {
    width: 100%;
    max-width: 310px !important;
    padding: 28px 22px !important;
  }

  .thankyou-sec h2 {
    font-size: 24px !important;
    margin: 15px 0px 8px 0px !important;
  }

  .thankyou-sec p {
    font-size: 15px !important;
    line-height: 23px !important;
  }

  .vasestremco {
    font-size: 11px !important;
    line-height: 47px !important;
    color: #0078ae !important;
    margin-top: 20px !important;
  }

  .thankyou-sec {
    padding: 114px 0px 55px 0px !important;
  }

  .thank-you-inner svg {
    width: 85px;
    height: 85px;
  }

  .book-sec-project .container .book-inner-projt {
    max-width: 310px !important;
    padding: 30px 29px !important;
  }

  .book-sec-project .bok-iner-head h2 {
    font-size: 18px !important;
    margin-bottom: 13px !important;
  }

}




@media only screen and (max-width: 384px) {
  .MuiMenuItem-root {
    font-size: 14px !important;
  }

  .modal-careerss .MuiDialog-container .MuiPaper-root {
    max-width: 290px !important;
  }

  .modal-careerss .MuiDialog-container .MuiPaper-root .MuiDialogContent-root h2 {
    font-size: 19px !important;
  }

  .book-demo-sec .btn-book-sec {
    padding: 10px 19px 7px 19px !important;
  }

  body .modal-yes .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .MuiTypography-root .form-sec-main .form-sec .veri-secss {
    margin-top: 13px !important;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root .MuiButtonBase-root svg {
    font-size: 27px !important;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root .MuiButtonBase-root {
    width: 45px !important;
    height: 45px !important;
  }

  .connect-sec-new .contact-info-new .mail-sec-new {
    margin-bottom: 10px !important;
  }

  .pos-sec {
    margin-left: 7px !important;
  }

  .modal-career .MuiDialog-container .MuiPaper-root .MuiDialogContent-root h2 {
    font-size: 21px !important;
  }

  .footer-sec .footer-mid-center ul {
    margin-top: 20px !important;
  }

  .footer-sec .footer-right ul {
    margin-top: 20px !important;
  }

  .view-proj .text-projt p .view-proj-sec {
    font-size: 14px !important;
  }

  .connect-sec-new .wth-contact {
    width: 100% !important;
    margin-bottom: 15px !important;
  }

  body .banner-content {
    left: 5% !important;
  }

  .privacy-inner p {
    font-size: 15px !important;
    line-height: 30px !important;
    margin-bottom: 10px !important;
  }

  .privacy-main-sec {
    padding: 30px 0px !important;
  }

  .privacy-inner .privcy-head-sec .after-head {
    font-size: 21px !important;
    margin-bottom: 30px !important;
  }

  .privacy-inner ul li {
    margin-bottom: 3px !important;
    font-size: 15px !important;
  }

  .privacy-inner .privcy-head-sec h2 {
    font-size: 20px !important;
    margin-bottom: 7px !important;
  }

  .cookies-next {
    font-size: 19px !important;
  }

  .privacy-inner .privcy-head-sec h3 {
    font-size: 19px !important;
  }

  .privacy-inner .personal-data-align-sec .data-align-left {
    width: 100% !important;
  }

  .privacy-inner .personal-data-align-sec {
    display: block !important;
  }

  .privacy-inner .personal-data-align-sec .data-align-md {
    width: 2%;
    display: none !important;
  }

  .privacy-inner .personal-data-align-sec .data-align-right {
    width: 100% !important;
    font-size: 15px !important;
  }

  .privacy-inner .privcy-head-sec h2 {
    font-size: 19px !important;
  }

  .main-sec-homes .banner-content p {
    font-size: 14px !important;
  }

  .main-sec-homes .abut-banner-cont h1 {
    font-size: 25px !important;
    line-height: 43px !important;
  }

  .main-sec-homes .banner-content h2 {
    font-size: 31px !important;
  }

  .service-desc-inner p {
    line-height: 26px;
  }

  .service-desc-inner img {
    height: 43px;
  }

  .service-desc-inner h3 {
    font-size: 16px;
    margin: 15px 17px 17px 17px;
  }

  .servics-sec .servic-njsmart-sec-new {
    height: 22.3vh !important;
  }

  .servic-other-sec .gap-new-sec {
    height: 22vh !important;
  }

  .servic-njsmart-sec a p {
    padding: 20px 2px 15px 1px !important;
  }

  .service-sec {
    background-size: 100% 45%;
    margin-top: 10px;
  }

  .service .service-head h2 {
    margin-top: 0px !important;
    margin-bottom: 31px !important;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root {
    max-width: 330px !important;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root .MuiButtonBase-root svg {
    font-size: 29px;
    right: 1px;
    top: -10px;
  }

  .project-inner-sec h3 {
    line-height: 34px !important;
  }

  .vision-sec {
    padding: 0px 15px !important;
  }

  .vision-sec .vision-inner h3 {
    font-size: 15px !important;
    line-height: 26px !important;
  }

  .partner-sec .partner-inner h2 {
    font-size: 20px !important;
  }

  .partner-sec .partner-inner {
    padding: 60px 25px 65px 25px;
  }

  .partner-sec .partner-inner p {
    font-size: 15px;
    line-height: 29px;
  }

  .partner-sec .talk-inner h2 {
    font-size: 19px !important;
    line-height: 36px !important;
  }

  .talk-inner .callltoactn span {
    font-size: 17px;
  }

  body .vision-img {
    height: 54px !important;
  }

  body .unique-sec .vision-inner p {
    margin-bottom: 50px;
  }

  .vision-inner h2 {
    font-size: 20px !important;
    margin-bottom: 10px !important;
  }

  .footer-sec .footer-left p {
    max-width: 100% !important;
  }

  .expert-sec .row h2 {
    font-size: 20px !important;
    line-height: 37px;
  }

  .expert-sec .row p {
    font-size: 15px !important;
    line-height: 28px !important;
  }

  .servic-visi .servic-inner-sec p {
    font-size: 15px !important;
    line-height: 28px !important;
  }

  .button-sec .btn-sec {
    font-size: 13px;
  }

  .footer-sec .footer-center h3 {
    font-size: 16px !important;
  }

  .navbar-brand img {
    height: 21px;
  }

  .navbar-brand {
    padding: 0px 8px;
  }

  .servic-dis-sec .servic-dis-inner h2 {
    font-size: 19px !important;
  }

  .servics-sec {
    width: 100%;
    position: relative;
    display: inline-block;
  }

  .servic-njsmart-sec {
    width: 94%;
    height: 23.6vh;
    margin: 0 auto !important;
    display: block;
    position: relative;
    margin: 0px 10px !important;
  }

  .servic-other-sec {
    width: 100%;
    margin-top: 20px;
    display: inline-block;
  }

  .servic-njsmart-sec a img {
    padding: 0px 14px 0px 2px;
    margin-top: 20px;
  }

  .servic-njsmart-sec a p {
    padding: 20px 2px 15px 1px;
  }

  .proj-sec-para p {
    font-size: 17px !important;
    line-height: 27px !important;
  }

  .main-projt-watch {
    top: 167px !important;
  }

  .case-banner-below-inner h3 {
    font-size: 20px !important;
  }

  .interven-sec h3 {
    font-size: 15px !important;
    line-height: 23px !important;
  }

  .interven-sec img {
    right: 0px;
    height: 73px !important;
  }

  .case-stud-sec .case-stud-sec-inner h3 {
    font-size: 17px !important;
    font-weight: 700;
  }

  .case-heading h4 {
    font-size: 17px !important;
    bottom: 5px !important;
  }

  .career-sec .career-para p {
    font-size: 16px !important;
    line-height: 29px !important;
    padding: 0px 7px !important;
  }

  .project-accodion-sec .accordion-inner-para h3 {
    font-size: 16px !important;
    margin-top: 15px;
  }

  .form-sec .MuiBox-root .MuiFormControl-root label {
    font-size: 15px !important;
  }

  .contact-details .contact-heading h2 {
    font-size: 22px !important;
    margin-bottom: 26px;
  }

  .location-sec iframe {
    width: 100%;
    height: auto;
  }

  // .rc-anchor-light {

  //     color: #000;
  //     transform: scale(0.77) !important;
  //     -webkit-transform: scale(0.77);
  //     transform-origin: 0 0;
  //     -webkit-transform-origin: 0 0;
  // }
  .recaptcha-sec iframe {
    transform: scale(0.77);
    -webkit-transform: scale(0.8);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root {
    margin: 0px;
    width: 100%;
    max-width: 340px !important;
    padding: 16px 15px 21px 15px !important;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root .MuiDialogContent-root h2 {
    font-size: 21px;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root .MuiDialogContent-root p {
    font-size: 15px !important;
    margin-bottom: 0px !important;
    margin-top: 10px !important;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .button-sec {
    margin-top: 15px;
  }

  .modal-yes .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .button-sec .btn-yes {
    width: 100% !important;
    font-size: 15px !important;
  }

  .form-sec .MuiBox-root .MuiFormControl-root label {
    font-size: 14px !important;
  }

  .btn-sec {
    padding: 10px 19px 8px 19px;
  }

  .happy-modal .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .MuiTypography-root .form-sec-main .form-sec {
    margin-bottom: 20px !important;
  }

  .invalid-feedback .error-msg {
    font-size: 13px;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root .MuiDialogContent-root p {
    font-size: 14px !important;
    margin-bottom: 10px !important;
    margin-top: 10px !important;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .button-case {
    margin-bottom: 0px !important;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .button-sec {
    margin-top: 10px !important;
  }

  .btn-sec {
    padding: 10px 19px 6px 19px !important;
  }

  .final-modal .btn-sec {
    padding: 10px 19px 8px 19px !important;
  }

  .btn-yes {
    margin-bottom: 0px !important;
  }

  .btn-no {
    margin-bottom: 0px !important;
  }

  .case-btn-sec {
    .btn-sec {
      padding: 13px 19px 9px 19px !important;
    }
  }

  .service-iew-sec {
    padding-left: 1px !important;
  }

  .service-iew-sec .serv-left-view a span {
    font-size: 14px !important;
  }


  .desk-sec-new {
    display: none !important;
  }

  .mob-sec-new {
    display: block !important;
  }

  .about-main {
    display: none !important;
  }

  .main-sec-homes {
    height: 35vh !important;
  }

  .mob-sec-new .main-sec-home {
    background-position: 81% 100% !important;
    top: 60px !important;
  }

  .banner-content {
    bottom: 5vh !important;
    left: 8% !important;
  }

  .service-headss p {
    font-size: 19px !important;
    font-weight: 600;
    line-height: 31px !important;
    margin-bottom: 5px !important;
  }

  .service-seccc {
    margin-top: 0px !important;
  }

  .service-sec .service-desc-sec {
    padding: 30px 0px 30px 0px !important;
  }

  .loader {
    margin: 62% auto !important;
  }

  .service-desc-inner {
    min-height: calc(70vh - 200px) !important;
  }

  .service-headss {
    margin-bottom: 40px !important;
  }

  .service-desc-inner h3 {
    font-size: 17px !important;
  }

  .service-head h2 {
    font-size: 24px !important;
  }

  .service-two {
    height: auto !important;
  }

  .project-inner-sec h3 {
    max-width: 270px;
    font-size: 21px !important;
    line-height: 35px !important;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root {
    max-width: 330px !important;
  }

  .vision-inner p {
    font-size: 15px;
  }

  .button-sec {
    margin-top: 6px !important;
    margin-bottom: 20px !important;
  }

  .servic-inner-sec {
    padding: 16px 0px 5px 0px !important;
  }


  .desk-sec-new {
    display: none !important;
  }

  body .mob-sec-new {
    display: block !important;
  }

  .about-main {
    display: none !important;
  }

  .mob-sec-new .about-sec-banner {
    top: 0px !important;
    background-position: 83% 20% !important;
  }

  .servicess-inner-para p {
    font-size: 15px !important;
    line-height: 31px !important;
  }

  .servicess-inner-sec {
    padding: 40px 0px !important;
  }

  body .about-main {
    height: 27vh !important;
  }

  .servicess-inner-para p {
    font-size: 15px !important;
    line-height: 32px !important;
  }

  .srvicess-left:after {
    display: none !important;
  }

  .servicess-divid .srvicess-right h2 {
    font-size: 22px !important;
    margin-bottom: 14px !important;
  }

  .serv-inner-desk {
    display: none !important;
  }

  .gap-sec .small-serv-left-view {
    padding-left: 1px !important;
  }

  .serv-inner-mob {
    display: block !important;
  }

  .servicess-inner-para p {
    font-size: 15px !important;
    line-height: 31px !important;
  }

  .servicess-divid {
    padding: 30px 0px 10px 0px !important;
  }

  .case-stud-data .reol-ul-sec li p {
    display: block !important;
  }

  .srvicess-right ul li {
    font-size: 17px !important;
  }

  .progrme-info-sec img {
    width: 100% !important
  }

  .progrme-info-sec h2 {
    font-size: 22px !important;
    margin-bottom: 13px !important;
  }

  .bg-sec-new {
    background-size: 100% 53% !important;
  }

  .case-btn-sec {
    margin-top: 40px !important;
    margin-bottom: 30px !important;
  }

  .progrme-info-sec {
    padding: 50px 0px 40px 0px !important;
  }

  .progrme-info-sec p {
    font-size: 15px !important;
    line-height: 27px !important;
  }

  .modal-career .MuiDialog-container .MuiPaper-root {
    max-width: 330px !important;

  }

  .servicess-divid .srvicess-left img {
    height: 74px !important;
    top: 0px !important;
  }

  .servicess-divid .srvicess-left {
    margin-bottom: 40px !important;
  }

  .point-sec-service {
    padding: 40px 20px 0px 20px !important;
  }

  .servicess-divid .srvicess-right p {
    font-size: 15px !important;
    line-height: 29px !important;
  }

  .reol-ul-sec .case-left {
    width: 100% !important;
    font-size: 17px !important;
    float: none !important;
  }

  .reol-ul-sec .case-right {
    width: 100% !important;
    font-size: 15px !important;
    margin-top: 4px !important;
  }

  .out-ul-sec li {
    font-size: 15px !important;
  }

  .reol-ul-sec li {
    margin-bottom: 23px !important;
  }

  .privacy-cont-sec {
    text-align: left;
    margin-bottom: 13px !important;
    font-size: 14px !important;
    font-weight: 600;
  }

  .wth-contact {
    width: 50% !important;
  }

  .connect-mail-new {
    font-size: 14px !important;
  }

  .contact-form {
    padding: 20px 20px 20px 20px !important;
  }

  .CookieConsent {
    font-size: 14px !important;
    line-height: 25px !important;
    margin: 0px 0px !important;

    div {
      margin: 10px auto 0px auto !important;
      width: 100% !important;
    }

  }

  // body .modal-cases .MuiDialog-container .MuiPaper-root .MuiDialogContent-root{
  //   padding: 10px 25px !important;
  // }
  body .modal-cases .MuiDialog-container .MuiPaper-root {
    max-width: 307px !important;
  }

  body .final-modal .MuiDialog-container .MuiPaper-root {
    max-width: 310px !important;
  }

  // body .final-modal .MuiDialog-container .MuiPaper-root .MuiDialogContent-root {
  //   padding: 10px 25px !important;
  // }
  .service-btn-sec .sev-mob-btn {
    padding: 9px 14px 7px 14px !important;
    font-size: 13px;
  }

  .service-mob-banner {
    bottom: 4vh !important;
  }

  .service-banner .service-btn-sec {
    margin-top: 3px;
  }

  .main-sec-homes .service-mob-banner h1 {
    font-size: 25px !important;
    line-height: 33px !important;
  }

  .service .touch-sec {
    margin-top: 20px !important;
  }

  .leader-sec a {
    font-size: 16px !important;
  }

  .prev-sec-service {
    width: 100%;
    position: relative;

    display: block;
    margin-bottom: 14px;
    font-size: 15px !important;
  }

  .btn-ser-explre {
    margin-top: 40px !important;
  }

  .ser-page-sec {
    font-size: 15px !important;
  }

  .book-demo-sec .btn-book-sec {
    position: relative !important;

    float: left !important;
  }

  .case-new-insd h3 {
    margin-bottom: 40px !important;
  }

  .case-new-mar {
    margin-bottom: 30px !important;
  }

  .final-modal .MuiDialog-container .MuiPaper-root {
    max-width: 400px !important;
  }

  .final-modal .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .button-sec {
    margin-top: 20px !important;
  }

  .final-modal .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .MuiTypography-root .button-case .btn-yes {
    font-size: 15px !important;
  }

  body .final-modal .MuiDialog-scrollPaper .MuiPaper-elevation {
    height: 81vh !important;
  }

  .final-modal .form-sec .MuiBox-root .MuiFormControl-root label {
    font-size: 14px !important;
  }

  .final-modal .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .MuiTypography-root .form-sec-main .form-radio-sec .MuiFormControl-root label {
    font-size: 15px !important;
    font-weight: 600 !important;
  }

  .final-modal .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .MuiTypography-root .form-sec-main .form-radio-sec .MuiFormControl-root label .MuiTypography-root {
    font-size: 14px !important;
  }

  .final-modal .MuiDialog-container .MuiPaper-root .MuiDialogContent-root p .form-sec-main .form-sec .case-privacy {
    font-size: 14px !important;
  }

  .otp-modal-new .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .otp-head {
    font-size: 15px !important;
    line-height: 24px !important;
  }

  .otp-modal-new .MuiDialogContent-root .validate-sec-mode .error-msg-sec .error-msg {
    font-size: 14px !important;
    margin-top: 5px !important;
  }

  .otp-modal-new .MuiDialog-container .MuiPaper-root {
    width: 100% !important;
    max-width: 350px !important;
  }

  .otp-modal-new .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .button-case {
    margin-top: 20px !important;
  }

  .otp-modal-new .MuiDialogContent-root .validate-sec-mode .error-msg-sec .error-msg {
    font-size: 13px !important;
  }

  .otp-modal-new .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .btn-otp .btn-sub {
    font-size: 15px !important;
    padding: 10px 0px 7px 0px !important;
    margin-right: 0px !important;
  }

  .otp-form .otp-sec-input {
    padding: 8px 0px !important;
    margin-right: 14px !important;
  }

  .otp-main-secss {
    width: 100% !important;
    max-width: 270px !important;
    margin: 0 auto !important;
  }

  .otp-modal-new .MuiDialogContent-root .otp-main-secss .width-otp .error-msg-sec .error-msg {
    font-size: 13px !important;
  }

  .otp-modal-new .MuiDialog-container .MuiPaper-root .MuiDialogContent-root p {
    margin-top: 0px !important;
  }

  body .otp-modal-new .MuiDialog-scrollPaper .MuiPaper-elevation .MuiDialogContent-dividers {
    padding: 10px 20px !important;
  }

  .otp-modal-new .MuiDialog-container .MuiPaper-root {
    max-width: 300px !important;
  }

  .modal-thanku-connet .MuiDialog-container .MuiPaper-root {
    max-width: 320px !important;
    padding: 24px 10px 24px 10px !important;
  }

  .modal-thanku-connet .MuiDialog-container .MuiPaper-root .MuiDialogContent-root p {
    font-size: 15px !important;
    line-height: 23px !important;
  }

  .modal-thanku-connet .MuiDialog-container .MuiPaper-root .MuiDialogContent-root h2 {
    margin-bottom: 7px !important;
    font-size: 23px !important;
  }

  .case-pre-new {
    width: 100%;
    position: relative;
    display: block;
    margin-bottom: 15px;
    font-size: 15px !important;
  }

  .case-pre-new a {
    margin-right: 0px;
    margin-bottom: 10px;
  }

  .case-back-new a {
    font-size: 15px !important;
  }

  .case-main-arow-sec {
    margin-bottom: 40px !important;
  }

  body .ser-page-sec {
    font-size: 14px !important;
  }

  .case-btn-sec .more-btn {
    padding: 15px 18px 13px 18px !important;
    font-size: 15px !important;
  }

  .final-modal .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .new-demo-para {
    font-size: 15px !important;
    line-height: 23px !important;
  }

  .final-modal .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .new-demo-head {
    font-size: 15px !important;
    margin-bottom: 4px !important;
  }

  .thank-you-inner {
    width: 100%;
    max-width: 330px !important;
    padding: 28px 30px !important;
  }

  .thankyou-sec h2 {
    font-size: 24px !important;
    margin: 15px 0px 9px 0px !important;
  }

  .thankyou-sec p {
    font-size: 15px !important;
    line-height: 23px !important;
  }

  .vasestremco {
    font-size: 11px !important;
    line-height: 47px !important;
    color: #0078ae !important;
    margin-top: 20px !important;
  }

  .thankyou-sec {
    padding: 114px 0px 60px 0px !important;
  }

  .thank-you-inner svg {
    width: 90px;
    height: 90px;
  }

  .bg-book {
    padding: 40px 0px 65px 0px !important;
  }

  .cse-new .btn-submit {
    font-size: 16px !important;
  }

  .bok-iner-head h2 {
    font-size: 22px !important;
  }

  .book-sec-project .book-inner-projt {
    max-width: 410px !important;
  }

  .info-privacy-sec {
    text-align: left;
    font-size: 15px !important;
    line-height: 23px !important;
  }

  .bg-book {
    padding: 20px 0px 57px 0px !important;
  }

  .cse-new .btn-submit {
    font-size: 15px !important;
    padding: 9px 19px 7px 19px !important;
  }

  .bok-iner-head h2 {
    font-size: 19px !important;
    margin-bottom: 16px !important;
  }

  .book-sec-project .book-inner-projt {
    max-width: 330px !important;
    padding: 30px 34px !important;
  }

  .info-privacy-sec {
    text-align: left;
    font-size: 14px !important;
    line-height: 23px !important;
  }

  .bok-iner-head {
    width: 100%;
    max-width: 330px !important;
    margin: 0 auto;
  }

  .recaptcha-sec iframe {
    transform: scale(0.77);
    -webkit-transform: scale(0.8);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }

  .cse-new {
    margin-top: 20px !important;
  }

  .cse-new .btn-submit {
    max-width: 250px !important;
    padding: 9px 19px 8px 19px;
  }

  .form-sec-main .form-select-sec .MuiFormControl-root .MuiFormLabel-root {
    font-size: 14px !important;
  }

  .thankyou-sec p {
    font-size: 14px !important;
    line-height: 25px !important;
  }

  .vasestremco {
    font-size: 11px !important;
    line-height: 47px !important;
    color: #0078ae !important;
    margin-top: 20px !important;
  }
}


@media only screen and (max-width: 419px) and (min-width: 385px) {

  .modal-careerss .MuiDialog-container .MuiPaper-root {
    max-width: 320px !important;
  }

  .book-demo-sec .btn-book-sec {
    padding: 10px 19px 7px 19px !important;
  }

  .connect-sec-new .contact-info-new .mail-sec-new {
    margin-bottom: 10px !important;
  }

  .CookieConsent {
    font-size: 14px !important;
    line-height: 25px !important;
    margin: 0px 0px !important;

    div {
      margin: 10px auto 0px auto !important;
      width: 100% !important;
    }

  }

  .view-proj .text-projt p .view-proj-sec {
    font-size: 14px !important;
  }

  .connect-sec-new .wth-contact {
    width: 100% !important;
    margin-bottom: 15px !important;
  }

  body .banner-content {
    left: 5% !important;
  }

  .privacy-inner p {
    font-size: 15px !important;
    line-height: 30px !important;
    margin-bottom: 10px !important;
  }

  .privacy-main-sec {
    padding: 30px 0px !important;
  }

  .privacy-inner .privcy-head-sec .after-head {
    font-size: 21px !important;
    margin-bottom: 30px !important;
  }

  .privacy-inner ul li {
    margin-bottom: 3px !important;
    font-size: 15px !important;
  }

  .privacy-inner .privcy-head-sec h2 {
    font-size: 20px !important;
    margin-bottom: 7px !important;
  }

  .cookies-next {
    font-size: 19px !important;
  }

  .privacy-inner .privcy-head-sec h3 {
    font-size: 19px !important;
  }

  .privacy-inner .personal-data-align-sec .data-align-left {
    width: 100% !important;
  }

  .privacy-inner .personal-data-align-sec {
    display: block !important;
  }

  .privacy-inner .personal-data-align-sec .data-align-md {
    width: 2%;
    display: none !important;
  }

  .privacy-inner .personal-data-align-sec .data-align-right {
    width: 100% !important;
    font-size: 15px !important;
  }

  .privacy-inner .privcy-head-sec h2 {
    font-size: 19px !important;
  }

  .main-sec-homes .banner-content p {
    font-size: 14px !important;
  }

  .main-sec-homes .abut-banner-cont h1 {
    font-size: 25px !important;
    line-height: 48px !important;
  }

  .main-sec-homes .banner-content h2 {
    font-size: 31px !important;
  }

  .service-desc-inner p {
    line-height: 26px;
  }

  .service-desc-inner img {
    height: 43px;
  }

  .service-desc-inner h3 {
    font-size: 16px;
    margin: 15px 17px 17px 17px;
  }

  .servics-sec .servic-njsmart-sec-new {
    height: 22.3vh !important;
  }

  .servic-other-sec .gap-new-sec {
    height: 22vh !important;
  }

  .servic-njsmart-sec a p {
    padding: 20px 2px 15px 1px !important;
  }

  .service-sec {
    background-size: 100% 45%;
    margin-top: 10px;
  }

  .service .service-head h2 {
    margin-top: 0px !important;
    margin-bottom: 31px !important;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root {
    max-width: 330px !important;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root .MuiButtonBase-root svg {
    font-size: 29px;
    right: 1px;
    top: -10px;
  }

  .project-inner-sec h3 {
    line-height: 34px !important;
  }

  .vision-sec {
    padding: 0px 15px !important;
  }

  .vision-sec .vision-inner h3 {
    font-size: 15px !important;
    line-height: 26px !important;
  }

  .partner-sec .partner-inner h2 {
    font-size: 20px !important;
  }

  .partner-sec .partner-inner {
    padding: 60px 25px 65px 25px;
  }

  .partner-sec .partner-inner p {
    font-size: 15px;
    line-height: 29px;
  }

  .partner-sec .talk-inner h2 {
    font-size: 19px !important;
    line-height: 36px !important;
  }

  .talk-inner .callltoactn span {
    font-size: 17px;
  }

  body .vision-img {
    height: 54px !important;
  }

  body .unique-sec .vision-inner p {
    margin-bottom: 50px;
  }

  .vision-inner h2 {
    font-size: 20px !important;
    margin-bottom: 10px !important;
  }

  .footer-sec .footer-left p {
    max-width: 100% !important;
  }

  .expert-sec .row h2 {
    font-size: 20px !important;
    line-height: 37px;
  }

  .expert-sec .row p {
    font-size: 15px !important;
    line-height: 28px !important;
  }

  .servic-visi .servic-inner-sec p {
    font-size: 15px !important;
    line-height: 28px !important;
  }

  .button-sec .btn-sec {
    font-size: 13px;
  }

  .footer-sec .footer-center h3 {
    font-size: 16px !important;
  }

  .navbar-brand img {
    height: 21px;
  }

  .navbar-brand {
    padding: 0px 8px;
  }

  .servic-dis-sec .servic-dis-inner h2 {
    font-size: 19px !important;
  }

  .servics-sec {
    width: 100%;
    position: relative;
    display: inline-block;
  }

  .servic-njsmart-sec {
    width: 94%;
    height: 23.6vh;
    margin: 0 auto !important;
    display: block;
    position: relative;
    margin: 0px 10px !important;
  }

  .servic-other-sec {
    width: 100%;
    margin-top: 20px;
    display: inline-block;
  }

  .servic-njsmart-sec a img {
    padding: 0px 14px 0px 2px;
    margin-top: 20px;
  }

  .servic-njsmart-sec a p {
    padding: 20px 2px 15px 1px;
  }

  .proj-sec-para p {
    font-size: 17px !important;
    line-height: 27px !important;
  }

  .main-projt-watch {
    top: 167px !important;
  }

  .case-banner-below-inner h3 {
    font-size: 20px !important;
  }

  .interven-sec h3 {
    font-size: 15px !important;
    line-height: 23px !important;
  }

  .interven-sec img {
    right: 0px;
    height: 73px !important;
  }

  .case-stud-sec .case-stud-sec-inner h3 {
    font-size: 18px !important;
  }

  .case-heading h4 {
    font-size: 17px !important;
    bottom: 5px !important;
  }

  .career-sec .career-para p {
    font-size: 16px !important;
    line-height: 29px !important;
    padding: 0px 7px !important;
  }

  .project-accodion-sec .accordion-inner-para h3 {
    font-size: 16px !important;
    margin-top: 15px;
  }

  .form-sec .MuiBox-root .MuiFormControl-root label {
    font-size: 15px !important;
  }

  .contact-details .contact-heading h2 {
    font-size: 22px !important;
    margin-bottom: 26px;
  }

  .location-sec iframe {
    width: 100%;
    height: auto;
  }

  // .rc-anchor-light {

  //     color: #000;
  //     transform: scale(0.77) !important;
  //     -webkit-transform: scale(0.77);
  //     transform-origin: 0 0;
  //     -webkit-transform-origin: 0 0;
  // }
  .recaptcha-sec iframe {
    transform: scale(0.77);
    -webkit-transform: scale(0.8);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root {
    margin: 0px;
    width: 100%;
    max-width: 340px !important;
    padding: 16px 15px 21px 15px !important;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root .MuiDialogContent-root h2 {
    font-size: 21px;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root .MuiDialogContent-root p {
    font-size: 15px !important;
    margin-bottom: 0px !important;
    margin-top: 10px !important;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .button-sec {
    margin-top: 15px;
  }

  .modal-yes .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .button-sec .btn-yes {
    width: 100% !important;
    font-size: 15px !important;
  }

  .form-sec .MuiBox-root .MuiFormControl-root label {
    font-size: 14px !important;
  }

  .btn-sec {
    padding: 10px 19px 8px 19px;
  }

  .happy-modal .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .MuiTypography-root .form-sec-main .form-sec {
    margin-bottom: 20px !important;
  }

  .invalid-feedback .error-msg {
    font-size: 13px;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root .MuiDialogContent-root p {
    font-size: 14px !important;
    margin-bottom: 10px !important;
    margin-top: 10px !important;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .button-case {
    margin-bottom: 0px !important;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .button-sec {
    margin-top: 10px !important;
  }

  .btn-sec {
    padding: 10px 19px 6px 19px !important;
  }

  .btn-yes {
    margin-bottom: 0px !important;
  }

  .btn-no {
    margin-bottom: 0px !important;
  }

  .case-btn-sec {
    .btn-sec {
      padding: 13px 19px 9px 19px !important;
    }
  }

  .service-iew-sec {
    padding-left: 1px !important;
  }

  .service-iew-sec .serv-left-view a span {
    font-size: 14px !important;
  }


  .desk-sec-new {
    display: none !important;
  }

  .mob-sec-new {
    display: block !important;
  }

  .about-main {
    display: none !important;
  }

  .main-sec-homes {
    height: 35vh !important;
  }

  .mob-sec-new .main-sec-home {
    background-position: 81% 100% !important;
    top: 60px !important;
  }

  .banner-content {
    bottom: 5vh !important;
    left: 8% !important;
  }

  .service-headss p {
    font-size: 19px !important;
    font-weight: 600;
    line-height: 31px !important;
    margin-bottom: 5px !important;
  }

  .service-seccc {
    margin-top: 0px !important;
  }

  .service-sec .service-desc-sec {
    padding: 30px 0px 30px 0px !important;
  }

  .loader {
    margin: 62% auto !important;
  }

  .service-desc-inner {
    min-height: calc(70vh - 200px) !important;
  }

  .service-headss {
    margin-bottom: 40px !important;
  }

  .service-desc-inner h3 {
    font-size: 17px !important;
  }

  .service-head h2 {
    font-size: 24px !important;
  }

  .service-two {
    height: auto !important;
  }

  .project-inner-sec h3 {
    max-width: 270px;
    font-size: 21px !important;
    line-height: 35px !important;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root {
    max-width: 330px !important;
  }

  .vision-inner p {
    font-size: 15px;
  }

  .button-sec {
    margin-top: 6px !important;
    margin-bottom: 20px !important;
  }

  .servic-inner-sec {
    padding: 16px 0px 5px 0px !important;
  }


  .desk-sec-new {
    display: none !important;
  }

  body .mob-sec-new {
    display: block !important;
  }

  .about-main {
    display: none !important;
  }

  .mob-sec-new .about-sec-banner {
    top: 0px !important;
    background-position: 83% 20% !important;
  }

  .servicess-inner-para p {
    font-size: 15px !important;
    line-height: 31px !important;
  }

  .servicess-inner-sec {
    padding: 50px 0px !important;
  }

  body .about-main {
    height: 27vh !important;
  }

  .servicess-inner-para p {
    font-size: 15px !important;
    line-height: 32px !important;
  }

  .srvicess-left:after {
    display: none !important;
  }

  .servicess-divid .srvicess-right h2 {
    font-size: 22px !important;
    margin-bottom: 14px !important;
  }

  .serv-inner-desk {
    display: none !important;
  }

  .gap-sec .small-serv-left-view {
    padding-left: 1px !important;
  }

  .serv-inner-mob {
    display: block !important;
  }

  .servicess-inner-para p {
    font-size: 15px !important;
    line-height: 31px !important;
  }

  .servicess-divid {
    padding: 30px 0px 10px 0px !important;
  }

  .srvicess-right ul li {
    font-size: 17px !important;
  }

  .progrme-info-sec img {
    width: 100% !important
  }

  .progrme-info-sec h2 {
    font-size: 22px !important;
    margin-bottom: 13px !important;
  }

  .bg-sec-new {
    background-size: 100% 53% !important;
  }

  .case-btn-sec {
    margin-top: 40px !important;
    margin-bottom: 30px !important;
  }

  .progrme-info-sec {
    padding: 50px 0px 40px 0px !important;
  }

  .progrme-info-sec p {
    font-size: 15px !important;
    line-height: 27px !important;
  }

  .modal-career .MuiDialog-container .MuiPaper-root {
    max-width: 380px !important;

  }

  .servicess-divid .srvicess-left img {
    height: 74px !important;
    top: 0px !important;
  }

  .servicess-divid .srvicess-left {
    margin-bottom: 40px !important;
  }

  .point-sec-service {
    padding: 40px 20px 0px 20px !important;
  }

  .servicess-divid .srvicess-right p {
    font-size: 15px !important;
    line-height: 29px !important;
  }

  .reol-ul-sec .case-left {
    width: 100% !important;
    font-size: 17px !important;
    float: none !important;
  }

  .reol-ul-sec .case-right {
    width: 100% !important;
    font-size: 15px !important;
    margin-top: 4px !important;
  }

  .out-ul-sec li {
    font-size: 15px !important;
  }

  .reol-ul-sec li {
    margin-bottom: 23px !important;
  }

  .privacy-cont-sec {
    text-align: left;
    margin-bottom: 13px !important;
    font-size: 14px !important;
    font-weight: 600;
  }

  .wth-contact {
    width: 50% !important;
  }

  .connect-mail-new {
    font-size: 14px !important;
  }

  .contact-form {
    padding: 20px 20px 20px 20px !important;
  }

  .case-stud-data .reol-ul-sec li p {
    display: block !important;
  }

  .modal-cases .MuiDialog-container .MuiPaper-root {
    max-width: 360px !important;
  }

  .service-btn-sec .sev-mob-btn {
    padding: 9px 14px 7px 14px !important;
    font-size: 13px;
  }

  .service-mob-banner {
    bottom: 4vh !important;
  }

  .service-banner .service-btn-sec {
    margin-top: 3px;
  }

  .main-sec-homes .service-mob-banner h1 {
    font-size: 25px !important;
    line-height: 33px !important;
  }

  .service .touch-sec {
    margin-top: 20px !important;
  }

  .leader-sec a {
    font-size: 16px !important;
  }

  .prev-sec-service {
    width: 100%;
    position: relative;

    display: block;
    margin-bottom: 14px;
    font-size: 15px !important;
  }

  .btn-ser-explre {
    margin-top: 40px !important;
  }

  .ser-page-sec {
    font-size: 15px !important;
  }

  .book-demo-sec .btn-book-sec {
    position: relative !important;

    float: left !important;
  }

  .case-new-insd h3 {
    margin-bottom: 40px !important;
  }

  .case-new-mar {
    margin-bottom: 30px !important;
  }

  .final-modal .MuiDialog-container .MuiPaper-root {
    max-width: 400px !important;
  }

  .final-modal .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .button-sec {
    margin-top: 20px !important;
  }

  .final-modal .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .MuiTypography-root .button-case .btn-yes {
    font-size: 16px !important;
  }

  body .final-modal .MuiDialog-scrollPaper .MuiPaper-elevation {
    height: 81vh !important;
  }

  .final-modal .form-sec .MuiBox-root .MuiFormControl-root label {
    font-size: 14px !important;
  }

  .final-modal .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .MuiTypography-root .form-sec-main .form-radio-sec .MuiFormControl-root label {
    font-size: 15px !important;
    font-weight: 600 !important;
  }

  .final-modal .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .MuiTypography-root .form-sec-main .form-radio-sec .MuiFormControl-root label .MuiTypography-root {
    font-size: 14px !important;
  }

  .final-modal .MuiDialog-container .MuiPaper-root .MuiDialogContent-root p .form-sec-main .form-sec .case-privacy {
    font-size: 14px !important;
  }

  .otp-modal-new .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .otp-head {
    font-size: 15px !important;
    line-height: 24px !important;
  }

  .otp-modal-new .MuiDialogContent-root .validate-sec-mode .error-msg-sec .error-msg {
    font-size: 14px !important;
    margin-top: 5px !important;
  }

  .otp-modal-new .MuiDialog-container .MuiPaper-root {
    width: 100% !important;
    max-width: 350px !important;
  }

  .otp-modal-new .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .button-case {
    margin-top: 20px !important;
  }

  .otp-modal-new .MuiDialogContent-root .validate-sec-mode .error-msg-sec .error-msg {
    font-size: 13px !important;
  }

  .otp-modal-new .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .btn-otp .btn-sub {
    font-size: 15px !important;
    padding: 10px 0px 7px 0px !important;
    margin-right: 0px !important;
  }

  .otp-form .otp-sec-input {
    padding: 8px 0px !important;
    margin-right: 14px !important;
  }

  .otp-main-secss {
    width: 100% !important;
    max-width: 270px !important;
    margin: 0 auto !important;
  }

  .otp-modal-new .MuiDialogContent-root .otp-main-secss .width-otp .error-msg-sec .error-msg {
    font-size: 13px !important;
  }

  .otp-modal-new .MuiDialog-container .MuiPaper-root .MuiDialogContent-root p {
    margin-top: 0px !important;
  }

  body .otp-modal-new .MuiDialog-scrollPaper .MuiPaper-elevation .MuiDialogContent-dividers {
    padding: 10px 20px !important;
  }

  .modal-thanku-connet .MuiDialog-container .MuiPaper-root {
    max-width: 350px !important;
    padding: 32px 16px 30px 16px !important;
  }

  .modal-thanku-connet .MuiDialog-container .MuiPaper-root .MuiDialogContent-root p {
    font-size: 15px !important;
    line-height: 23px !important;
  }

  .modal-thanku-connet .MuiDialog-container .MuiPaper-root .MuiDialogContent-root h2 {
    margin-bottom: 7px !important;
    font-size: 24px !important;
  }

  .case-pre-new {
    width: 100%;
    position: relative;
    display: block;
    margin-bottom: 15px;
    font-size: 15px !important;
  }

  .case-pre-new a {
    margin-right: 0px;
    margin-bottom: 10px;
  }

  .case-back-new a {
    font-size: 15px !important;
  }

  .case-main-arow-sec {
    margin-bottom: 40px !important;
  }

  .case-btn-sec .more-btn {
    padding: 15px 18px 13px 18px !important;
    font-size: 15px !important;
  }

  .final-modal .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .new-demo-para {
    font-size: 15px !important;
    line-height: 23px !important;
  }

  .final-modal .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .new-demo-head {
    font-size: 15px !important;
  }

  .thank-you-inner {
    width: 100%;
    max-width: 380px !important;
  }

  .thankyou-sec h2 {
    font-size: 26px !important;
    margin: 15px 0px 9px 0px !important;
  }

  .thankyou-sec p {
    font-size: 15px !important;
    line-height: 25px !important;
    margin-top: 15px !important;
  }

  .vasestremco {
    font-size: 11px !important;
    line-height: 47px   !important;
    color: #0078ae  !important;
    margin-top: 20px  !important;
  }

  .thankyou-sec {
    padding: 130px 0px 70px 0px !important;
  }

  .thank-you-inner svg {
    width: 95px;
    height: 95px;
  }

  .bg-book {
    padding: 30px 0px 57px 0px !important;
  }

  .cse-new .btn-submit {
    font-size: 16px !important;
  }

  .bok-iner-head h2 {
    font-size: 20px !important;
    margin-bottom: 20px !important;
  }

  .book-sec-project .book-inner-projt {
    max-width: 350px !important;
  }

  .info-privacy-sec {
    text-align: left;
    font-size: 15px !important;
    line-height: 23px !important;
  }

  .recaptcha-sec iframe {
    transform: scale(0.77);
    -webkit-transform: scale(0.8);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }

  .cse-new {
    margin-top: 20px !important;
  }

  .cse-new .btn-submit {
    max-width: 250px !important;
    padding: 9px 19px 8px 19px;
  }

  .form-sec-main .form-select-sec .MuiFormControl-root .MuiFormLabel-root {
    font-size: 14px !important;
  }


}

@media only screen and (max-width: 420px) {
  .modal-careerss .MuiDialog-container .MuiPaper-root {
    max-width: 332px !important;
  }

  .view-proj .text-projt p .view-proj-sec {
    font-size: 14px !important;
  }

  .privacy-inner p {
    font-size: 15px !important;
    line-height: 28px !important;
    margin-bottom: 10px !important;
  }

  .privacy-main-sec {
    padding: 30px 0px !important;
  }

  .privacy-inner .privcy-head-sec .after-head {
    font-size: 21px !important;
    margin-bottom: 30px !important;
  }

  .privacy-inner ul li {
    margin-bottom: 3px !important;
    font-size: 15px !important;
  }

  .privacy-inner .privcy-head-sec h2 {
    font-size: 20px !important;
    margin-bottom: 7px !important;
  }

  .cookies-next {
    font-size: 19px !important;
  }

  .privacy-inner .privcy-head-sec h3 {
    font-size: 19px !important;
  }

  .privacy-inner .personal-data-align-sec .data-align-left {
    width: 100% !important;
  }

  .privacy-inner .personal-data-align-sec {
    display: block !important;
  }

  .privacy-inner .personal-data-align-sec .data-align-md {
    width: 2%;
    display: none !important;
  }

  .privacy-inner .personal-data-align-sec .data-align-right {
    width: 100% !important;
    font-size: 15px !important;
  }

  .privacy-inner .privcy-head-sec h2 {
    font-size: 19px !important;
  }

  .watch-video-sec .watcch-inner-sec .mobile-watch {
    background-color: transparent;
    padding: 13px 0px !important;
    box-shadow: none;
  }

  .watch-video-sec .watcch-inner-sec .mobile-watch p {
    font-size: 24px !important;
    padding: 0px 0px !important;
    margin-bottom: 5px;
  }

  .watch-video-sec .watcch-inner-sec .watch-video-play .watch-vid-play-inner .media-sec {
    width: 100% !important;
    height: auto !important;
  }

  .desk-watch {
    display: none !important;
  }

  .mobile-watch {
    display: block !important;
  }

  .location-sec iframe {
    border: 0px;
    width: 100%;
    height: auto;
  }

  .project-accodion-sec .MuiPaper-root .MuiButtonBase-root .MuiAccordionSummary-content .MuiTypography-root .acordian-head .acordian-head-left p {
    font-size: 15px;
  }

  .main-sec-header {
    padding: 0px 10px;
  }

  .service-inner-sec .service-iiner:after {
    display: block;
  }

  .project-width {
    width: 100%;
  }

  .service-desc-sec {
    padding: 50px 20px 50px 20px !important;
  }

  .no-padding-right {
    padding-right: 0px;
    padding-left: 0px;
  }

  .service-head h2 {
    margin-bottom: 60px;
  }

  .no-padding-left {
    padding-right: 0px;
    padding-left: 0px;
  }

  .service-desc-inner {
    margin-bottom: 30px !important;
  }

  .navbar-toggler {
    border: none;
  }

  .nav-item {
    .nav-link {
      .fa-house {
        position: relative;
        top: 2px;
      }
    }
  }

  .project-acn-sec-main .project-acn-sec {
    padding-left: 19px;
    bottom: 20px;
  }

  .project-acn-sec .proj-actn-left {
    margin-bottom: 3px;
  }

  .btn-call {
    font-size: 13px;
    padding: 9px 13px 7px 17px;
  }

  .watch-video-sec .watcch-inner-sec .watch-video-play .watch-vid-play-inner {
    box-shadow: none;
    height: auto;
  }

  .watch-video-sec .watcch-inner-sec .watch-sec {
    padding: 53px 0px;
    width: 100%;
  }

  .watch-video-sec {
    padding: 20px 0px 0px 0px;
  }

  .watch-video-sec .watcch-inner-sec .watch-video-play .watch-vid-play-inner .media-sec {}

  .watch-video-sec .watcch-inner-sec .watch-video-play {
    width: 100%;
  }

  .main-sec-homes {
    height: 45vh;
  }

  .navbar {
    padding-top: 11px;
    padding-bottom: 11px;
  }

  .banner-content {
    bottom: 5vh !important;
    left: 8% !important;
  }

  .pre-expended-sec {
    height: 39vh !important;
  }

  .pre-expended-inner {
    bottom: 75px !important;
    position: absolute;
    bottom: -10px;
    text-align: center;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }

  body .about-main {
    height: 30vh !important;
  }

  .service-inner-sec img {
    height: 55px;
  }

  .banner-content {
    h1 {
      font-size: 34px !important;
      margin-bottom: 3px;
    }

    h2 {
      font-size: 34px !important;
      margin-bottom: 16px;
    }

    p {
      font-size: 15px !important;
      line-height: 31px !important;
    }
  }

  .service-sec {
    background-size: 100% 55%;
    margin-top: 10px;
  }

  .expert-sec h2 {
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 67px;
    margin-top: 74px;
  }

  .expert-sec p {
    font-size: 25px;
    line-height: 43px;
    margin-top: 30px;
  }

  .servic-dis-sec h2 {
    font-size: 21px !important;
    line-height: 33px !important;
    padding-top: 15px !important;
    margin-bottom: 40px;
  }

  .gap-sec {
    margin-left: 12px;
    margin-right: 12px;
  }

  .gap-sec a p {
    padding: 10px 0px 0px 0px;
    font-size: 14px;
    font-weight: 700;
  }

  .abut-banner-cont {
    h1 {
      font-size: 76px !important;
      text-shadow: 2px 2px rgba(128, 128, 128, 0.6) !important;
    }
  }

  .proj-sec-para p {
    font-size: 17px !important;
    line-height: 28px !important;
    padding-top: 0px !important;
    margin-bottom: 10px;
    padding: 0px 15px;
  }

  .multi-proj-sec {
    padding-bottom: 70px;
  }

  .text-projt p {
    font-size: 15px;
    line-height: 25px;
  }

  .career-para p {
    font-size: 17px !important;
    line-height: 31px !important;
    padding: 0px 10px;
  }

  .project-accodion-sec .MuiPaper-root .MuiButtonBase-root .MuiAccordionSummary-content .MuiTypography-root .acordian-head .acordian-head-right p {
    font-size: 14px;

  }

  .career-sec {
    padding: 0px 0px 60px 0px;
  }

  .project-accodion-sec .MuiPaper-root .MuiButtonBase-root .MuiAccordionSummary-content .MuiTypography-root .acordian-head .acordian-head-left img {
    height: 24px;
    margin-right: 6px;
  }

  .pre-expended-inner {
    p {
      font-size: 37px;
      line-height: 54px;
    }
  }

  .project-accodion-sec .MuiPaper-root .MuiButtonBase-root {
    padding: 0px 8px;
  }

  .proj-sec-para {
    padding: 60px 0px 15px 0px;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root {
    max-width: 350px;
  }

  .case-banner-below-inner h3 {
    font-size: 20px !important;
  }

  .case-stud-data p {
    font-size: 15px;
    line-height: 29px;
  }

  .roles-desc-sec ul li span {
    font-size: 15px;
    line-height: 27px;
  }

  .case-banner-below {
    padding: 60px 0px 50px 0px;
  }

  .project-accodion-sec .MuiPaper-root .MuiButtonBase-root .MuiAccordionSummary-content .MuiTypography-root .acordian-head .acordian-head-left p {
    font-size: 15px;
    top: 13px;
  }

  .accordion-inner-para p {
    font-size: 15px;
    line-height: 27px;
    margin-bottom: 30px;
  }

  .acordian-head-left {
    width: 100%;
    display: block;
    position: relative;
  }

  .project-accodion-sec .MuiPaper-root .MuiButtonBase-root .MuiAccordionSummary-content .MuiTypography-root .acordian-head .acordian-head-right {
    float: none;
    width: 100%;
    position: relative;
    display: flex;
    padding-top: 20px;
  }

  .img-sc {
    top: 7px;
  }

  .roles-desc-sec ul {
    margin-top: 25px;
  }

  .serveff-se {
    a {
      p {
        margin-bottom: 9px;
      }
    }
  }

  .margn-case {
    margin-top: 30px;
  }

  .servic-dis-sec {
    padding: 41px 0px 51px 0px;
  }

  .case-stud-sec-inner h3 {
    font-size: 20px !important;
    margin-bottom: 36px !important;
  }

  .accordion-inner-para h3 {
    font-size: 17px !important;
    margin-top: 15px;
  }

  .apply-sec .apply-btn {
    margin-left: 10px;
  }

  .contact-heading h2 {
    font-size: 25px !important;
    margin-bottom: 15px;
  }

  .career-newbgss {
    margin-top: 0px;
  }

  .nav-link::after {
    display: none;
  }

  .main-sec-home {
    background-position: 81% 100% !important;
    top: 0px !important;
  }

  .service-head h2 {
    font-size: 26px !important;
    margin-top: 0px !important;
    margin-bottom: 41px !important;
  }

  .service-inner-sec h3 {
    font-size: 15px;
    line-height: 23px;
  }

  .home-project-sec {
    padding: 20px 20px 0px 20px;
  }

  .service-inner-sec .service-iiner {
    width: 50%;
    margin-bottom: 10px !important;
  }

  .service-five {
    margin-top: 30px;
  }

  .service-two {
    height: 20vh;
  }

  .pre-expended-inner p {
    font-size: 20px !important;
    line-height: 41px !important;
  }

  .project-inner-sec h3 {
    max-width: 270px;
    font-size: 22px !important;
    line-height: 35px;
  }

  .project-sec-width {
    margin-right: 2px;
    margin-bottom: 5px;
    width: 100%;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root .MuiButtonBase-root {
    width: 44px;
    height: 43px;
    right: -16px;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root .MuiButtonBase-root svg {
    font-size: 33px;
    right: 1px;
    top: 0px;
  }

  .button-sec {
    margin-top: 26px;
    margin-bottom: 20px;
  }

  .project-acn-sec-main .project-acn-sec .proj-actn-left a span {
    margin-right: 3px;
  }

  .partner-inner h2 {
    font-size: 20px;
  }

  .partner-inner h3 {
    font-size: 16px !important;
  }

  .footer-sec {
    padding: 60px 0px 10px 0px;
  }

  .partner-inner h3::after {
    margin-top: 22px;
  }

  .talk-inner h2 {
    font-size: 22px !important;
    line-height: 39px !important;
  }

  .partner-inner p {
    font-size: 15px;
    line-height: 30px;
  }

  .callltoactn {
    img {
      height: 23px;
    }
  }

  .vision-inner {
    text-align: left;
    margin-top: 10px !important;
  }

  .vision-img {
    height: 61px !important;
    margin-bottom: 5px;
    float: left;
  }

  .vision-inner h2 {
    font-size: 21px !important;
  }

  .footer-width {
    width: 100%;
    margin-bottom: 10px;
  }

  .footer-sec .footer-left p {
    line-height: 25px !important;
    font-size: 14px !important;
    margin-bottom: 10px !important;
  }

  .navbar-brand img {
    height: 23px;
  }

  .footer-sec .footer-left img {
    height: 25px;
  }

  .footer-sec .footer-center h3 {
    font-size: 17px !important;
  }

  .footer-sec .footer-mid-center h3 {
    font-size: 17px !important;
  }

  .footer-sec .footer-right h3 {
    font-size: 17px !important;
  }

  .footer-mid-center {
    margin-bottom: 30px !important;
  }

  .footer-center {
    margin-bottom: 30px !important;
  }

  .footer-bottom-left p {
    text-align: left !important;
  }

  .abut-banner-cont {
    bottom: 6vh !important;

    h1 {
      font-size: 34px !important;
    }

    p {
      font-size: 17px !important;
    }
  }

  .expert-sec h2 {
    margin-bottom: 57px;
    margin-top: 54px;
  }

  .expert-sec h2 {
    font-size: 21px !important;
    line-height: 38px;
    margin-bottom: 40px !important;
    margin-top: 56px !important;
  }

  .expert-sec p {
    font-size: 16px !important;
    line-height: 30px !important;
  }

  .servic-inner-sec p {
    font-size: 16px !important;
    line-height: 31px !important;
  }

  .servic-visi {
    padding: 40px 0px 40px 0px;
  }

  .interven-sec img {
    right: 0px;
    height: 83px;
  }

  .expert-sec {
    padding: 65px 20px 10px 20px;
  }

  .case-heading h4 {
    font-size: 18px;
    bottom: 5px;
  }

  .watch-video-sec .watcch-inner-sec .watch-sec p {
    font-size: 32px;
    padding: 0px 30px;
  }

  .interven-sec h3 {
    font-size: 17px;
    line-height: 26px;
    padding: 20px 0px 20px 0px;
  }

  .nav-link {
    padding: 10px 9px !important;
    font-size: 14px;
  }

  .btn-call img {
    top: -1px;
    height: 15px;
  }

  .service-desc-inner h3 {
    margin: 15px 20px 25px 20px;
    line-height: 30px;
    font-size: 17px;
  }

  .service-desc-inner p {
    font-size: 15px;
    padding: 0px 15px;
    max-width: 500px;
  }

  .project-acn-sec-main .project-acn-sec .proj-actn-right {
    right: 7px;
    float: right;
  }

  .project-acn-sec-main .project-acn-sec {
    padding-left: 11px;
  }

  .project-acn-sec-main .project-acn-sec .proj-actn-right a {
    font-size: 14px;
  }

  .project-acn-sec-main .project-acn-sec .proj-actn-left a {
    font-size: 14px;
  }

  .project-acn-sec-main .project-acn-sec .proj-actn-left {
    margin-top: 0px;
  }

  .btn-sec {
    font-size: 14px;
  }

  .unique-sec {
    margin-top: 40px;
    padding-bottom: 15px;
  }

  .project-inner-sec img {
    height: 240px;
  }

  .vision-inner h3 {
    font-size: 16px !important;
    line-height: 27px !important;
    margin-top: 12px;
  }

  .partner-inner {
    padding: 40px 20px 40px 30px;
  }

  .partner-inner h2 {
    font-size: 22px !important;
  }

  .talk-inner {
    padding: 71px 20px 79px 40px;
  }

  .footer-sec .footer-center ul li a span {
    font-size: 14px;
    font-weight: 500;
  }

  .footer-sec .footer-mid-center ul li a span {
    font-size: 14px;
    margin-left: 4px;
    font-weight: 500;
  }

  .footer-sec .footer-right ul li a span {
    font-size: 14px;
  }

  .hr-sec {
    margin: 10px 0px 40px 0px;
  }

  .btn-sec {
    padding: 9px 19px 5px 19px;
    margin-bottom: 6px;
  }

  .gap-sec {
    padding: 10px 6px 10px 6px;

    img {
      height: 35px;
    }
  }

  .servic-njsmart-sec a img {
    height: 35px;
  }

  .footer-bottom-right {
    float: left;
    margin-top: 14px;
  }

  .servic-njsmart-sec a p {
    padding: 30px 2px 15px 1px;
    font-size: 14px;
  }

  .servic-njsmart-sec a img {
    padding: 0px 14px 0px 2px;
  }

  .overlay-text {
    padding: 10px 10px 30px 10px;
    font-size: 17px;
  }

  .main-projt-watch {
    top: 187px;
  }

  .custom-play-button {
    top: 44%;
  }

  .case-btn-sec {
    margin-top: 40px;
    margin-bottom: 60px;
  }

  .case-stud-sec {
    padding: 60px 0px 20px 0px;
  }

  .video-main-secs {
    height: 65px;
    width: 65px;
  }

  .custom-play-button svg {
    height: 27px;
    transform: translate(-43%, 76%);
  }

  .contact-details .contact-info {
    left: 0px;
    width: 100%;
    padding: 40px 15px 10px 17px;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .career-newbgss {
    padding-top: 30px;
  }

  .contact-details {
    padding: 40px 10px;
  }

  .contact-details .contact-info h2 {
    font-size: 18px;
    margin-bottom: 45px;
  }

  .invalid-feedback .error-msg {
    padding-top: 0px;
  }

  .contact-details .contact-info .margin-cont-top {
    font-size: 15px;
  }

  .contact-details .contact-info p {
    margin-bottom: 17px;
    font-size: 15px;
  }

  .submit-sec .btn-sec {
    padding: 12px 25px 9px 25px;
    line-height: 21px;
  }

  .contact-details .contact-info p i {
    top: 1px;
  }

  .contact-form {
    padding: 20px 30px 35px 30px;
  }

  .footer-sec .footer-right ul li a img {
    height: 18px;
  }

  .text-projt {
    left: 5%;
  }

  .main-projt-watch .projet-over-right a {
    font-size: 15px;
  }

  .main-projt-watch .projet-over-left a {
    font-size: 15px;
    cursor: pointer;
  }

  .navbar-collapse {
    border-top: 1px solid #b5b5b54f;
    margin-top: 10px;
  }

  .navbar-toggler:focus {
    box-shadow: none;
  }

  .mob-no {
    display: none;
  }

  .pre-expended-inner-new p {
    font-size: 17px !important;
    line-height: 30px !important;
  }

  .vision-sec {
    margin-top: 30px !important;
    margin-bottom: 0px !important;
  }

  .service-one {
    margin-bottom: 0px !important;
  }

  .service-five {
    margin-top: 0px;
  }

  .service-iiner {
    margin-top: 20px !important;
  }

  .service {
    margin-bottom: 30px;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root .MuiDialogContent-root p {
    font-size: 17px;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .button-sec {
    margin-top: 20px;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .button-sec .btn-yes {
    width: 90px;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .button-sec .btn-no {
    width: 90px;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root {
    padding: 30px 20px 25px 21px;
  }

  .CookieConsent {
    font-size: 14px !important;
    line-height: 25px !important;
    margin: 0px 0px !important;

    div {
      margin: 10px auto 0px auto !important;
      width: 100% !important;
    }

  }

  .case-stud-data .reol-ul-sec li p {
    display: block !important;
  }

  .modal-cases .MuiDialog-container .MuiPaper-root {
    max-width: 360px !important;
  }

  .service-btn-sec .sev-mob-btn {
    padding: 9px 14px 7px 14px !important;
    font-size: 13px;
  }

  .service-mob-banner {
    bottom: 4vh !important;

}
.service-banner .service-btn-sec {
  margin-top: 3px;
}
.main-sec-homes .service-mob-banner h1 {
  font-size: 25px !important;
  line-height: 33px !important;
}

.prev-sec-service{
  width: 100%;
  position: relative;
 
  display: block;
  margin-bottom: 14px;
  font-size: 15px !important;
}
.btn-ser-explre {
  margin-top: 40px !important;
}
.ser-page-sec{
font-size: 15px !important;
}
.otp-modal-new .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .otp-head {
  font-size: 15px !important;
  line-height: 24px !important;
}
.otp-modal-new .MuiDialogContent-root .validate-sec-mode .error-msg-sec .error-msg {
  font-size: 14px !important;
  margin-top: 5px !important;
}
.otp-modal-new .MuiDialog-container .MuiPaper-root{
  width: 100% !important;
  max-width: 322px !important;
}
.otp-modal-new .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .button-case{
margin-top: 20px !important;}
.otp-modal-new .MuiDialogContent-root .validate-sec-mode .error-msg-sec .error-msg {
  font-size: 13px !important;
}
.otp-modal-new .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .btn-otp .btn-sub {
  font-size: 15px !important;
  padding: 10px 0px 7px 0px !important;
  margin-right: 0px !important;
}
.otp-form .otp-sec-input {
  padding: 8px 0px !important;
  margin-right: 14px !important;
}
.otp-main-secss{
  width: 100% !important;
  max-width: 270px !important;
  margin: 0 auto !important;
}
.otp-modal-new .MuiDialogContent-root .otp-main-secss .width-otp .error-msg-sec .error-msg {
    font-size: 13px !important;
}
.otp-modal-new .MuiDialog-container .MuiPaper-root .MuiDialogContent-root p {
  margin-top: 0px !important;
}
body .otp-modal-new .MuiDialog-scrollPaper .MuiPaper-elevation .MuiDialogContent-dividers {
  padding: 10px 15px !important;
}
.date-modal .invalid-feedback .error-msg{
  font-size: 13px !important;
}
.date-modal-case .MuiStack-root .MuiTextField-root .MuiFormLabel-root {
  font-size: 14px !important;
}
.invalid-feedbackcontact .error-msg{
  font-size: 13px !important;
}

.case-btn-sec .more-btn{
  padding: 15px 18px 13px 18px !important;
  font-size: 15px !important;
}
.form-sec-main .form-select-sec .MuiFormControl-root .MuiFormLabel-root{
  font-size: 14px !important;
}
.prev-sec-service:after{
  display: none !important;
}
.line-after-case:after{
  display: none !important;
}
.service-sec {
  background-size: initial !important;
  background-repeat: repeat-y !important;
}
.bg-only {
   
  background-size: initial !important;
  background-repeat: repeat-y !important;
}
.bg-sec-newss{
  background-size: initial !important;
  background-repeat: repeat-y !important;
}
.career-newbg{
  background-size: initial !important;
  background-repeat: repeat-y !important;
}
.servic-dis-sec{
  background-size: initial !important;
  background-repeat: repeat-y !important;
}
.bg-sec-new{
  background-size: initial !important;
  background-repeat: repeat-y !important;
}
.bg-sec-new-video{
  background-size: initial !important;
  background-repeat: repeat-y !important;
}
.nav-new-menu a.active:before{
  display: none;
}
body .nav-new {
  padding: 10px 9px !important;
}
.nav-new{
  margin-left: 0px !important;
}
.nav-link:hover:before{
  display: none !important;
}
.nav-link:hover:before{
  display: none !important;
}
.book-caes{
  margin-bottom: 15px !important;
}

.book-new-thanyou{
  margin-bottom: 20px !important;
}

}

@media only screen and (min-width: 420px) and (max-width: 480px) {
  .nav-link:hover:before{
    display: none !important;
  }
  .nav-new-menu a.active:before{
    display: none;
  }
  .bg-sec-new-video{
    background-size: initial !important;
    background-repeat: repeat-y !important;

  }

  .service-banner .service-btn-sec {
    margin-top: 3px;
  }

  .main-sec-homes .service-mob-banner h1 {
    font-size: 25px !important;
    line-height: 33px !important;
  }

  .prev-sec-service {
    width: 100%;
    position: relative;

    display: block;
    margin-bottom: 14px;
    font-size: 15px !important;
  }

  .btn-ser-explre {
    margin-top: 40px !important;
  }

  .ser-page-sec {
    font-size: 15px !important;
  }

  .otp-modal-new .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .otp-head {
    font-size: 15px !important;
    line-height: 24px !important;
  }

  .otp-modal-new .MuiDialogContent-root .validate-sec-mode .error-msg-sec .error-msg {
    font-size: 14px !important;
    margin-top: 5px !important;
  }

  .otp-modal-new .MuiDialog-container .MuiPaper-root {
    width: 100% !important;
    max-width: 322px !important;
  }

  .otp-modal-new .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .button-case {
    margin-top: 20px !important;
  }

  .otp-modal-new .MuiDialogContent-root .validate-sec-mode .error-msg-sec .error-msg {
    font-size: 13px !important;
  }

  .otp-modal-new .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .btn-otp .btn-sub {
    font-size: 15px !important;
    padding: 10px 0px 7px 0px !important;
    margin-right: 0px !important;
  }

  .otp-form .otp-sec-input {
    padding: 8px 0px !important;
    margin-right: 14px !important;
  }

  .otp-main-secss {
    width: 100% !important;
    max-width: 270px !important;
    margin: 0 auto !important;
  }

  .otp-modal-new .MuiDialogContent-root .otp-main-secss .width-otp .error-msg-sec .error-msg {
    font-size: 13px !important;
  }

  .otp-modal-new .MuiDialog-container .MuiPaper-root .MuiDialogContent-root p {
    margin-top: 0px !important;
  }

  body .otp-modal-new .MuiDialog-scrollPaper .MuiPaper-elevation .MuiDialogContent-dividers {
    padding: 10px 15px !important;
  }

  .date-modal .invalid-feedback .error-msg {
    font-size: 13px !important;
  }

  .date-modal-case .MuiStack-root .MuiTextField-root .MuiFormLabel-root {
    font-size: 14px !important;
  }

  .invalid-feedbackcontact .error-msg {
    font-size: 13px !important;
  }

  .case-btn-sec .more-btn {
    padding: 15px 18px 13px 18px !important;
    font-size: 15px !important;
  }

  .form-sec-main .form-select-sec .MuiFormControl-root .MuiFormLabel-root {
    font-size: 14px !important;
  }

  .prev-sec-service:after {
    display: none !important;
  }

  .line-after-case:after {
    display: none !important;
  }

  .service-sec {
    background-size: initial !important;
    background-repeat: repeat-y !important;
  }

  .bg-only {

    background-size: initial !important;
    background-repeat: repeat-y !important;
  }

  .bg-sec-newss {
    background-size: initial !important;
    background-repeat: repeat-y !important;
  }

  .career-newbg {
    background-size: initial !important;
    background-repeat: repeat-y !important;
  }

  .servic-dis-sec {
    background-size: initial !important;
    background-repeat: repeat-y !important;
  }

  .bg-sec-new {
    background-size: initial !important;
    background-repeat: repeat-y !important;
  }

  .bg-sec-new-video {
    background-size: initial !important;
    background-repeat: repeat-y !important;
  }

}

@media only screen and (min-width: 420px) and (max-width: 480px) {
  .bg-sec-new-video {
    background-size: initial !important;
    background-repeat: repeat-y !important;
  }

  .case-btn-sec .more-btn {
    padding: 15px 18px 13px 18px !important;
    font-size: 15px !important;
  }

  .invalid-feedbackcontact .error-msg {
    font-size: 13px !important;
  }

  .date-modal-case .MuiStack-root .MuiTextField-root .MuiFormLabel-root {
    font-size: 14px !important;
  }

  .modal-cases .MuiDialog-container .MuiPaper-root {
    max-width: 360px !important;
  }

  .case-stud-data .reol-ul-sec li p {
    display: block !important;
  }

  .CookieConsent {
    font-size: 14px !important;
    line-height: 25px !important;
    margin: 0px 0px !important;

    div {
      margin: 10px auto 0px auto !important;
      width: 100% !important;
    }

  }

  .view-proj .text-projt p .view-proj-sec {
    font-size: 14px !important;
  }

  .privacy-inner p {
    font-size: 15px !important;
    line-height: 30px !important;
    margin-bottom: 10px !important;
  }

  .privacy-main-sec {
    padding: 30px 0px !important;
  }

  .privacy-inner .privcy-head-sec .after-head {
    font-size: 21px !important;
    margin-bottom: 30px !important;
  }

  .privacy-inner ul li {
    margin-bottom: 3px !important;
    font-size: 15px !important;
  }

  .privacy-inner .privcy-head-sec h2 {
    font-size: 20px !important;
    margin-bottom: 7px !important;
  }

  .cookies-next {
    font-size: 19px !important;
  }

  .privacy-inner .privcy-head-sec h3 {
    font-size: 19px !important;
  }

  .privacy-inner .personal-data-align-sec .data-align-left {
    width: 100% !important;
  }

  .privacy-inner .personal-data-align-sec {
    display: block !important;
  }

  .privacy-inner .personal-data-align-sec .data-align-md {
    width: 2%;
    display: none !important;
  }

  .privacy-inner .personal-data-align-sec .data-align-right {
    width: 100% !important;
    font-size: 15px !important;
  }

  .privacy-inner .privcy-head-sec h2 {
    font-size: 19px !important;
  }

  .watch-video-sec .watcch-inner-sec .mobile-watch {
    background-color: transparent;
    padding: 13px 0px !important;
    box-shadow: none;
  }

  .watch-video-sec .watcch-inner-sec .mobile-watch p {
    font-size: 24px !important;
    padding: 0px 30px !important;
    margin-bottom: 5px;
  }

  .watch-video-sec .watcch-inner-sec .watch-video-play .watch-vid-play-inner .media-sec {
    width: 100% !important;
    height: auto !important;
  }

  .desk-watch {
    display: none !important;
  }

  .mobile-watch {
    display: block !important;
  }

  .project-accodion-sec .MuiPaper-root .MuiButtonBase-root .MuiAccordionSummary-content .MuiTypography-root .acordian-head .acordian-head-left p {
    font-size: 15px;
  }

  .main-sec-header {
    padding: 0px 10px;
  }

  .service-inner-sec .service-iiner:after {
    display: block;
  }

  .project-width {
    width: 100%;
  }

  .service-desc-sec {
    padding: 20px 5px 20px 5px !important;
  }

  .no-padding-right {
    padding-right: 0px;
    padding-left: 0px;
  }

  .service-head h2 {
    margin-bottom: 60px;
  }

  .no-padding-left {
    padding-right: 0px;
    padding-left: 0px;
  }

  .service-desc-inner {
    margin-bottom: 30px !important;
  }

  .service-headss p {

    font-size: 17px !important;
    font-weight: 600 !important;
    line-height: 29px !important;
    margin-bottom: 6px !important;
  }

  .service-headss {
    margin-bottom: 30px !important;
  }

  .service-desc-inner {
    min-height: calc(70vh - 200px) !important;
  }

  .navbar-toggler {
    border: none;
  }

  .nav-item {
    .nav-link {
      .fa-house {
        position: relative;
        top: 2px;
      }
    }
  }

  .project-acn-sec-main .project-acn-sec {
    padding-left: 19px;
    bottom: 20px;
  }

  .project-acn-sec .proj-actn-left {
    margin-bottom: 3px;
  }

  .btn-call {
    font-size: 13px;
    padding: 9px 13px 7px 17px;
  }

  .watch-video-sec .watcch-inner-sec .watch-video-play .watch-vid-play-inner {
    box-shadow: none;
    height: auto;
  }

  .watch-video-sec .watcch-inner-sec .watch-sec {
    padding: 53px 0px;
    width: 100%;
  }

  .watch-video-sec {
    padding: 20px 0px 0px 0px;
  }

  .watch-video-sec .watcch-inner-sec .watch-video-play .watch-vid-play-inner .media-sec {}

  .watch-video-sec .watcch-inner-sec .watch-video-play {
    width: 100%;
  }

  .main-sec-homes {
    height: 45vh;
  }

  .navbar {
    padding-top: 11px;
    padding-bottom: 11px;
  }

  .banner-content {
    bottom: 5vh !important;
    left: 8% !important;
  }

  .pre-expended-sec {
    height: 39vh !important;
  }

  .pre-expended-inner {
    bottom: 75px !important;
    position: absolute;
    bottom: -10px;
    text-align: center;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }

  body .about-main {
    height: 26vh !important;
  }

  .service-inner-sec img {
    height: 55px;
  }

  .banner-content {
    h1 {
      font-size: 34px !important;
      margin-bottom: 3px;
    }

    h2 {
      font-size: 34px !important;
      margin-bottom: 16px;
    }

    p {
      font-size: 15px !important;
      line-height: 31px !important;
    }
  }

  .service-sec {
    background-size: 100% 40%;
    margin-top: 10px;
  }

  .expert-sec h2 {
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 67px;
    margin-top: 74px;
  }

  .expert-sec p {
    font-size: 25px;
    line-height: 43px;
    margin-top: 30px;
  }

  .servic-dis-sec h2 {
    font-size: 21px !important;
    line-height: 33px !important;
    padding-top: 15px !important;
    margin-bottom: 40px;
  }

  .gap-sec {
    margin-left: 12px;
    margin-right: 12px;
  }

  .gap-sec a p {
    padding: 10px 0px 0px 0px;
    font-size: 14px;
    font-weight: 700;
  }

  .abut-banner-cont {
    h1 {
      font-size: 76px !important;
      text-shadow: 2px 4px rgba(128, 128, 128, 0.6) !important;
    }
  }

  .proj-sec-para p {
    font-size: 16px !important;
    line-height: 28px !important;
    padding-top: 0px !important;
    margin-bottom: 10px;
    padding: 0px 15px;
  }

  .multi-proj-sec {
    padding-bottom: 70px;
  }

  .text-projt p {
    font-size: 15px;
    line-height: 25px;
  }

  .career-para p {
    font-size: 16px !important;
    line-height: 31px !important;
    padding: 0px 10px;
  }

  .project-accodion-sec .MuiPaper-root .MuiButtonBase-root .MuiAccordionSummary-content .MuiTypography-root .acordian-head .acordian-head-right p {
    font-size: 15px;
  }

  .career-sec {
    padding: 0px 0px 60px 0px;
  }

  .project-accodion-sec .MuiPaper-root .MuiButtonBase-root .MuiAccordionSummary-content .MuiTypography-root .acordian-head .acordian-head-left img {
    height: 24px;
    margin-right: 6px;
  }

  .pre-expended-inner {
    p {
      font-size: 37px;
      line-height: 54px;
    }
  }

  .project-accodion-sec .MuiPaper-root .MuiButtonBase-root {
    padding: 0px 8px;
  }

  .proj-sec-para {
    padding: 60px 0px 15px 0px;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root {
    max-width: 350px;
  }

  .case-banner-below-inner h3 {
    font-size: 21px !important;
  }

  .case-stud-data p {
    font-size: 15px;
    line-height: 29px;
  }

  .roles-desc-sec ul li span {
    font-size: 15px;
    line-height: 27px;
  }

  .case-banner-below {
    padding: 60px 0px 50px 0px;
  }

  .project-accodion-sec .MuiPaper-root .MuiButtonBase-root .MuiAccordionSummary-content .MuiTypography-root .acordian-head .acordian-head-left p {
    font-size: 15px;
    top: 13px;
  }

  .accordion-inner-para p {
    font-size: 15px;
    line-height: 27px;
    margin-bottom: 30px;
  }

  .acordian-head-left {
    width: 100%;
    display: block;
    position: relative;
  }

  .project-accodion-sec .MuiPaper-root .MuiButtonBase-root .MuiAccordionSummary-content .MuiTypography-root .acordian-head .acordian-head-right {
    float: none;
    width: 100%;
    position: relative;
    display: flex;
    padding-top: 22px;
  }

  .img-sc {
    top: 7px;
  }

  .roles-desc-sec ul {
    margin-top: 25px;
  }

  .serveff-se {
    a {
      p {
        margin-bottom: 9px;
      }
    }
  }

  .book-new-thanyou{
    margin-bottom: 30px !important;
  }

  .wth-contact {
    width: 50% !important;
  }

  .connect-mail-new {
    font-size: 15px !important;
  }

  .privacy-cont-sec {
    font-size: 14px !important;
    line-height: 24px !important;
  }

  .margn-case {
    margin-top: 30px;
  }

  .servic-dis-sec {
    padding: 41px 0px 51px 0px;
  }

  .case-stud-sec-inner h3 {
    font-size: 20px !important;
    margin-bottom: 36px !important;
  }

  .accordion-inner-para h3 {
    font-size: 17px !important;
    margin-top: 15px;
  }

  .apply-sec .apply-btn {
    margin-left: 10px;
  }

  .contact-heading h2 {
    font-size: 25px !important;
    margin-bottom: 15px;
  }

  .career-newbgss {
    margin-top: 0px;
  }

  .nav-link::after {
    display: none;
  }

  .main-sec-home {
    background-position: 81% 0% !important;
    top: 56px !important;
  }

  .service-head h2 {
    font-size: 26px !important;
    margin-top: 0px !important;
    margin-bottom: 41px !important;
  }

  .main-sec-homes {

    height: 33vh !important;

  }

  .service-inner-sec h3 {
    font-size: 16px;
    line-height: 25px;
  }

  .home-project-sec {
    padding: 20px 20px 0px 20px;
  }

  .service-inner-sec .service-iiner {
    width: 50%;
    margin-bottom: 10px !important;
  }

  .service-five {
    margin-top: 30px;
  }

  .service-two {
    height: auto;
  }

  .pre-expended-inner p {
    font-size: 20px !important;
    line-height: 41px !important;
  }

  .project-inner-sec h3 {
    max-width: 270px;
    font-size: 22px !important;
    line-height: 35px;
  }

  .project-sec-width {
    margin-right: 2px;
    margin-bottom: 5px;
    width: 100%;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root .MuiButtonBase-root {
    width: 44px;
    height: 43px;
    right: -26px;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root .MuiButtonBase-root svg {
    font-size: 29px;
    right: 1px;
    top: 0px;
  }

  .button-sec {
    margin-top: 21px;
    margin-bottom: 20px;
  }

  .project-acn-sec-main .project-acn-sec .proj-actn-left a span {
    margin-right: 3px;
  }

  .partner-inner h2 {
    font-size: 20px;
  }

  .partner-inner h3 {
    font-size: 16px !important;
  }

  .footer-sec {
    padding: 60px 0px 10px 0px;
  }

  .partner-inner h3::after {
    margin-top: 22px;
  }

  .talk-inner h2 {
    font-size: 21px !important;
    line-height: 38px !important;
  }

  .partner-inner p {
    font-size: 15px;
    line-height: 30px;
  }

  .callltoactn {
    img {
      height: 23px;
    }
  }

  .vision-inner {
    text-align: left;
    margin-top: 10px !important;
  }

  .vision-img {
    height: 61px !important;
    margin-bottom: 5px;
    float: left;
  }

  .unique-sec .vision-inner p {
    margin-bottom: 40px !important;
  }

  .vision-inner h2 {
    font-size: 21px !important;
  }

  .footer-width {
    width: 100%;
    margin-bottom: 10px;
  }

  .footer-sec .footer-left p {
    line-height: 25px !important;
    font-size: 14px !important;
    margin-bottom: 10px !important;
    max-width: 100% !important;
  }

  .navbar-brand img {
    height: 23px;
  }

  .about-sec-banner {
    top: 0px !important;
  }

  .footer-sec .footer-left img {
    height: 25px;
  }

  .footer-sec .footer-center h3 {
    font-size: 17px !important;
  }

  .footer-sec .footer-mid-center h3 {
    font-size: 17px !important;
  }

  .footer-sec .footer-right h3 {
    font-size: 17px !important;
  }

  .footer-mid-center {
    margin-bottom: 30px !important;
  }

  .footer-center {
    margin-bottom: 30px !important;
  }

  .footer-bottom-left p {
    text-align: left !important;
  }

  .abut-banner-cont {
    bottom: 8vh !important;

    h1 {
      font-size: 31px !important;
      line-height: 47px !important;
    }

    p {
      font-size: 15px !important;
    }
  }

  .about-sec-banner {
    background-position: 81% 60% !important;
  }

  .expert-sec h2 {
    margin-bottom: 57px;
    margin-top: 54px;
  }

  .expert-sec h2 {
    font-size: 19px !important;
    line-height: 36px;
    margin-bottom: 40px !important;
    margin-top: 56px !important;
  }

  .expert-sec p {
    font-size: 15px !important;
    line-height: 29px !important;
  }

  .servic-inner-sec p {
    font-size: 16px !important;
    line-height: 31px !important;
  }

  .servic-visi {
    padding: 40px 0px 40px 0px;
  }

  .interven-sec img {
    right: 0px;
    height: 83px;
  }

  .expert-sec {
    padding: 65px 20px 10px 20px;
  }

  .case-heading h4 {
    font-size: 18px;
    bottom: 5px;
  }

  .watch-video-sec .watcch-inner-sec .watch-sec p {
    font-size: 32px;
    padding: 0px 30px;
  }

  .interven-sec h3 {
    font-size: 16px;
    line-height: 26px;
    padding: 20px 0px 20px 0px;
  }

  .nav-link {
    padding: 10px 9px !important;
    font-size: 14px;
  }

  .btn-call img {
    top: -1px;
    height: 15px;
  }

  .service-desc-inner h3 {
    margin: 15px 20px 25px 20px;
    line-height: 30px;
    font-size: 17px;
  }

  .service-desc-inner p {
    font-size: 15px;
    padding: 0px 15px;
    max-width: 500px;
  }

  .project-acn-sec-main .project-acn-sec .proj-actn-right {
    right: 7px;
    float: right;
  }

  .project-acn-sec-main .project-acn-sec {
    padding-left: 11px;
  }

  .project-acn-sec-main .project-acn-sec .proj-actn-right a {
    font-size: 14px;
  }

  .project-acn-sec-main .project-acn-sec .proj-actn-left a {
    font-size: 14px;
  }

  .project-acn-sec-main .project-acn-sec .proj-actn-left {
    margin-top: 0px;
  }

  .btn-sec {
    font-size: 14px;
  }

  .desk-sec-new {
    display: none !important;
  }

  body .mob-sec-new {
    display: block !important;
  }

  .about-main {
    display: none !important;
  }

  .unique-sec {
    margin-top: 40px;
    padding-bottom: 15px;
  }

  .project-inner-sec img {
    height: 240px;
  }

  .vision-inner h3 {
    font-size: 15px !important;
    line-height: 27px !important;
    margin-top: 12px;
  }

  .vision-inner p {
    font-size: 15px !important;
  }

  .partner-inner {
    padding: 40px 20px 40px 30px;
  }

  .partner-inner h2 {
    font-size: 22px !important;
  }

  .talk-inner {
    padding: 71px 20px 79px 40px;
  }

  .footer-sec .footer-center ul li a span {
    font-size: 14px;
    font-weight: 500;
  }

  .footer-sec .footer-mid-center ul li a span {
    font-size: 14px;
    margin-left: 4px;
    font-weight: 500;
  }

  .footer-sec .footer-right ul li a span {
    font-size: 14px;
  }

  .hr-sec {
    margin: 10px 0px 40px 0px;
  }

  .btn-sec {
    padding: 12px 19px 9px 19px;
  }

  .gap-sec {
    padding: 10px 6px 10px 6px;

    img {
      height: 35px;
    }
  }

  .servic-njsmart-sec a img {
    height: 35px;
  }

  .footer-bottom-right {
    float: left;
    margin-top: 14px;
  }

  .servic-njsmart-sec a p {
    padding: 30px 2px 15px 1px;
    font-size: 14px;
  }

  .servic-njsmart-sec a img {
    padding: 0px 14px 0px 2px;
  }

  .overlay-text {
    padding: 10px 10px 30px 10px;
    font-size: 17px;
  }

  .main-projt-watch {
    top: 187px;
  }

  .custom-play-button {
    top: 49%;
  }

  .case-btn-sec {
    margin-top: 20px;
    margin-bottom: 60px;
  }

  .case-stud-sec {
    padding: 60px 0px 30px 0px;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root .MuiDialogContent-root p {
    font-size: 16px;
  }

  .video-main-secs {
    height: 65px;
    width: 65px;
  }

  .custom-play-button svg {
    height: 27px;
    transform: translate(-43%, 76%);
  }

  .contact-details .contact-info {
    left: 0px;
    width: 100%;
    padding: 40px 15px 10px 17px;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .button-sec {
    margin-top: 20px;
  }

  .career-newbgss {
    padding-top: 30px;
  }

  .contact-details {
    padding: 40px 10px;
  }

  .contact-details .contact-info h2 {
    font-size: 18px;
    margin-bottom: 45px;
  }

  .contact-details .contact-info .margin-cont-top {
    font-size: 15px;
  }

  .contact-details .contact-info p {
    margin-bottom: 17px;
    font-size: 15px;
  }

  .submit-sec .btn-sec {
    padding: 12px 25px 9px 25px;
    line-height: 21px;
  }

  .contact-details .contact-info p i {
    top: 1px;
  }

  .contact-form {
    padding: 20px 50px 35px 30px;
  }

  .footer-sec .footer-right ul li a img {
    height: 18px;
  }

  .text-projt {
    left: 5%;
  }

  .main-projt-watch .projet-over-right a {
    font-size: 15px;
  }

  .main-projt-watch .projet-over-left a {
    font-size: 15px;
    cursor: pointer;
  }

  .navbar-collapse {
    border-top: 1px solid #b5b5b54f;
    margin-top: 10px;
  }

  .navbar-toggler:focus {
    box-shadow: none;
  }

  .mob-no {
    display: none;
  }

  .pre-expended-inner-new p {
    font-size: 18px !important;
    line-height: 32px !important;
  }

  .vision-sec {
    margin-top: 30px !important;
    margin-bottom: 0px !important;
  }

  .service-one {
    margin-bottom: 0px !important;
  }

  .service-five {
    margin-top: 0px;
  }

  .service-iiner {
    margin-top: 20px !important;
  }

  .service {
    margin-bottom: 45px;
  }

  .invalid-feedback .error-msg {
    padding-top: 0px;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root {
    margin: 0px;
    width: 100%;
    max-width: 340px;
    padding: 16px 15px 21px 15px;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root .MuiDialogContent-root h2 {
    font-size: 21px;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root .MuiDialogContent-root p {
    font-size: 15px;
    margin-bottom: 0px;
    margin-top: 10px;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .button-sec {
    margin-top: 15px;
  }

  .modal-yes .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .button-sec .btn-yes {
    width: 100% !important;
    font-size: 16px !important;
  }

  .form-sec .MuiBox-root .MuiFormControl-root label {
    font-size: 14px;
  }

  .service-iew-sec {
    padding-left: 1px !important;
  }

  .service-iew-sec .serv-left-view a span {
    font-size: 13px !important;
  }

  .loader {
    margin: 52% auto !important;
  }

  .small-serv-left-view {
    padding-left: 1px !important;
  }

  .servic-njsmart-sec {
    padding: 0px 8px 0px 8px !important;
  }

  .servic-other-sec .gap-new-sec {
    height: 22vh !important;
  }

  .servics-sec .servic-njsmart-sec-new {
    height: 45.8vh !important;
  }

  .servicess-inner-para p {
    font-size: 15px !important;
    line-height: 32px !important;
  }

  .srvicess-left:after {
    display: none !important;
  }

  .servicess-divid .srvicess-right h2 {
    font-size: 22px !important;
    margin-bottom: 14px !important;
  }

  .serv-inner-desk {
    display: none !important;
  }

  .serv-inner-mob {
    display: block !important;
  }

  .servicess-inner-para p {
    font-size: 15px !important;
    line-height: 31px !important;
  }

  .servicess-divid {
    padding: 30px 0px 10px 0px !important;
  }

  .srvicess-right ul li {
    font-size: 17px !important;
  }

  .progrme-info-sec img {
    width: 100% !important
  }

  .progrme-info-sec h2 {
    font-size: 22px !important;
    margin-bottom: 13px !important;
  }

  .progrme-info-sec {
    padding: 50px 0px 40px 0px !important;
  }

  .progrme-info-sec p {
    font-size: 15px !important;
    line-height: 27px !important;
  }

  .modal-career .MuiDialog-container .MuiPaper-root {
    max-width: 380px !important;

  }

  .servicess-divid .srvicess-left img {
    height: 90px !important;
    margin-bottom: 40px !important;
    top: 0px !important;
  }

  .servicess-inner-sec {
    padding: 50px 0px !important;
  }

  .servicess-divid .srvicess-right p {

    font-size: 15px !important;
    line-height: 30px !important;

  }

  .point-sec-service {
    padding: 30px 15px 0px 17px !important;
  }

  .reol-ul-sec .case-left {
    width: 100% !important;
    font-size: 17px !important;
    float: none !important;
  }

  .reol-ul-sec .case-right {
    width: 100% !important;
    font-size: 15px !important;
    margin-top: 4px !important;
  }

  .out-ul-sec li {
    font-size: 15px !important;
  }

  .reol-ul-sec li {
    margin-bottom: 23px !important;
  }

  .service-btn-sec .sev-mob-btn {
    padding: 9px 14px 7px 14px !important;
    font-size: 13px;
  }

  .service-mob-banner {
    bottom: 4vh !important;
  }

  .service-banner .service-btn-sec {
    margin-top: 3px;
  }

  .main-sec-homes .service-mob-banner h1 {
    font-size: 25px !important;
    line-height: 33px !important;
  }

  .talk-sec .talk-btn-sec {
    padding: 11px 15px 9px 15px !important;
    line-height: 22px !important;
    width: 100%;
    // max-width: 205px;
    margin-bottom: 18px;
    float: left;
  }

  .talk-sec .explore-btn {
    padding: 11px 15px 9px 15px !important;
    line-height: 22px !important;
    width: 100%;
    // max-width: 205px;
    margin-bottom: 18px;
    float: left;
  }

  .service .touch-sec {
    margin-top: 20px !important;
  }

  .leader-sec a {
    font-size: 16px !important;
  }

  .prev-sec-service {
    width: 100%;
    position: relative;

    display: block;
    margin-bottom: 14px;
    font-size: 15px !important;
  }

  .btn-ser-explre {
    margin-top: 40px !important;
  }

  .ser-page-sec {
    font-size: 15px !important;
  }


  .book-demo-sec .btn-book-sec {
    position: relative !important;

    float: left !important;
  }

  .case-new-insd h3 {
    margin-bottom: 40px !important;
  }

  .case-new-mar {
    margin-bottom: 30px !important;
  }

  .final-modal .MuiDialog-container .MuiPaper-root {
    max-width: 365px !important;
  }

  .final-modal .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .button-sec {
    margin-top: 20px !important;
  }

  .final-modal .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .MuiTypography-root .button-case .btn-yes {
    font-size: 16px !important;
  }

  body .final-modal .MuiDialog-scrollPaper .MuiPaper-elevation {
    height: 81vh !important;
  }

  .final-modal .form-sec .MuiBox-root .MuiFormControl-root label {
    font-size: 14px !important;
  }

  .final-modal .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .MuiTypography-root .form-sec-main .form-radio-sec .MuiFormControl-root label {
    font-size: 15px !important;
    font-weight: 600 !important;
  }

  .final-modal .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .MuiTypography-root .form-sec-main .form-radio-sec .MuiFormControl-root label .MuiTypography-root {
    font-size: 14px !important;
  }

  .final-modal .MuiDialog-container .MuiPaper-root .MuiDialogContent-root p .form-sec-main .form-sec .case-privacy {
    font-size: 14px !important;
  }

  .otp-modal-new .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .otp-head {
    font-size: 15px !important;
    line-height: 24px !important;
  }

  .otp-modal-new .MuiDialogContent-root .validate-sec-mode .error-msg-sec .error-msg {
    font-size: 14px !important;
    margin-top: 5px !important;
  }

  .otp-modal-new .MuiDialog-container .MuiPaper-root {
    width: 100% !important;
    max-width: 350px !important;
  }

  .otp-modal-new .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .button-case {
    margin-top: 20px !important;
  }

  .otp-modal-new .MuiDialogContent-root .validate-sec-mode .error-msg-sec .error-msg {
    font-size: 13px !important;
  }

  .otp-modal-new .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .btn-otp .btn-sub {
    font-size: 15px !important;
    padding: 10px 0px 7px 0px !important;
    margin-right: 0px !important;
  }

  .otp-form .otp-sec-input {
    padding: 8px 0px !important;
    margin-right: 14px !important;
  }

  .otp-main-secss {
    width: 100% !important;
    max-width: 270px !important;
    margin: 0 auto !important;
  }

  .otp-modal-new .MuiDialogContent-root .otp-main-secss .width-otp .error-msg-sec .error-msg {
    font-size: 13px !important;
  }

  .otp-modal-new .MuiDialog-container .MuiPaper-root .MuiDialogContent-root p {
    margin-top: 0px !important;
  }

  body .otp-modal-new .MuiDialog-scrollPaper .MuiPaper-elevation .MuiDialogContent-dividers {
    padding: 10px 20px !important;
  }

  .modal-thanku-connet .MuiDialog-container .MuiPaper-root {
    max-width: 350px !important;
    padding: 32px 16px 30px 16px !important;
  }

  .modal-thanku-connet .MuiDialog-container .MuiPaper-root .MuiDialogContent-root p {
    font-size: 15px !important;
    line-height: 23px !important;
  }

  .modal-thanku-connet .MuiDialog-container .MuiPaper-root .MuiDialogContent-root h2 {
    margin-bottom: 7px !important;
    font-size: 24px !important;
  }

  .case-pre-new {
    width: 100%;
    position: relative;
    display: block;
    margin-bottom: 15px;
    font-size: 15px !important;
  }

  .case-pre-new a {
    margin-right: 0px;
    margin-bottom: 10px;
  }

  .case-back-new a {
    font-size: 15px !important;
  }

  .case-main-arow-sec {
    margin-bottom: 40px !important;
  }

  .date-modal .invalid-feedback .error-msg {
    font-size: 13px !important;
  }

  .final-modal .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .new-demo-para {
    font-size: 15px !important;
    line-height: 23px !important;
  }

  .final-modal .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .new-demo-head {
    font-size: 15px !important;
  }

  .thank-you-inner {
    width: 100%;
    max-width: 380px !important;
  }

  .thankyou-sec h2 {
    font-size: 26px !important;
    margin: 15px 0px 9px 0px !important;
  }

  .thankyou-sec p {
    font-size: 15px !important;
    line-height: 28px !important;
    margin-top: 16px;

  }
  .thankyou-sec .thank-you-inner .vasestremco{
    padding-top: 0px !important;
  }
.book-caes{
  margin-bottom: 15px !important;
}
  .vasestremco {
    font-size: 12px !important;
    line-height: 47px   !important;
    color: #0078ae  !important;
    margin-top: 20px  !important;
  }

  .thankyou-sec {
    padding: 130px 0px 70px 0px !important;
  }

  .thank-you-inner svg {
    width: 95px;
    height: 95px;
  }

  .bg-book {
    padding: 30px 0px 57px 0px !important;
  }

  .cse-new .btn-submit {
    font-size: 16px !important;
  }

  .bok-iner-head h2 {
    font-size: 20px !important;
    margin-bottom: 20px !important;
  }

  .book-sec-project .book-inner-projt {
    max-width: 350px !important;
    padding: 30px 34px !important;
  }

  .info-privacy-sec {
    text-align: left;
    font-size: 15px !important;
    line-height: 23px !important;
  }

  .recaptcha-sec iframe {
    transform: scale(0.77);
    -webkit-transform: scale(0.8);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }

  .cse-new {
    margin-top: 20px !important;
  }

  .cse-new .btn-submit {
    max-width: 250px !important;
    padding: 9px 19px 8px 19px;
  }

  .form-sec-main .form-select-sec .MuiFormControl-root .MuiFormLabel-root {
    font-size: 14px !important;
  }

  .prev-sec-service:after {
    display: none !important;
  }

  .line-after-case:after {
    display: none !important;
  }

  .service-sec {
    background-size: initial !important;
    background-repeat: repeat-y !important;
  }

  .bg-only {

    background-size: initial !important;
    background-repeat: repeat-y !important;
  }

  .bg-sec-newss {
    background-size: initial !important;
    background-repeat: repeat-y !important;
  }

  .career-newbg {
    background-size: initial !important;
    background-repeat: repeat-y !important;
  }

  .servic-dis-sec {
    background-size: initial !important;
    background-repeat: repeat-y !important;
  }

  .servic-dis-sec {
    background-size: initial !important;
    background-repeat: repeat-y !important;
  }

  .bg-sec-new {
    background-size: initial !important;
    background-repeat: repeat-y !important;
  }
  .nav-new {
    padding: 10px 9px !important;
}
body .nav-new {
  padding: 10px 9px !important;
}
.nav-new{
  margin-left: 0px !important;
}
.nav-link:hover:before{
  display: none !important;
}
.thank-you-inner .thank-amaze{
  line-height: 26px !important;
}
}

@media only screen and (min-width: 481px) and (max-width: 575px) {
  .bg-sec-new-video {
    background-size: initial !important;
    background-repeat: repeat-y !important;
  }

  .bg-sec-new {
    background-size: initial !important;
    background-repeat: repeat-y !important;
  }

  .bg-sec-newss {
    background-size: initial !important;
    background-repeat: repeat-y !important;
  }

  .servic-dis-sec {
    background-size: initial !important;
    background-repeat: repeat-y !important;
  }

  .bg-only {

    background-size: initial !important;
    background-repeat: repeat-y !important;
  }

  .form-sec-main .form-select-sec .MuiFormControl-root .MuiFormLabel-root {
    font-size: 14px !important;
  }

  .final-modal .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .new-demo-para {
    font-size: 15px !important;
    line-height: 23px !important;
  }

  .final-modal .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .new-demo-head {
    font-size: 15px !important;
  }

  .invalid-feedbackcontact .error-msg {
    font-size: 13px !important;
  }

  .date-modal-case .MuiStack-root .MuiTextField-root .MuiFormLabel-root {
    font-size: 14px !important;
  }

  .invalid-feedbackcontact .error-msg {
    font-size: 13px !important;
  }

  .date-modal .invalid-feedback .error-msg {
    font-size: 13px !important;
  }

  .modal-thanku-connet .MuiDialog-container .MuiPaper-root {
    max-width: 410px !important;
    padding: 35px 20px 35px 20px !important;
  }

  .modal-thanku-connet .MuiDialog-container .MuiPaper-root .MuiDialogContent-root p {
    font-size: 15px !important;
    line-height: 23px !important;
  }

  .modal-cases .MuiDialog-container .MuiPaper-root {
    max-width: 420px !important;
  }

  .case-stud-data .reol-ul-sec li p {
    display: block !important;
  }

  .privacy-inner p {
    font-size: 15px !important;
    line-height: 30px !important;
    margin-bottom: 10px !important;
  }

  .privacy-main-sec {
    padding: 30px 0px !important;
  }

  .privacy-inner .privcy-head-sec .after-head {
    font-size: 21px !important;
    margin-bottom: 30px !important;
  }

  .privacy-inner ul li {
    margin-bottom: 3px !important;
    font-size: 15px !important;
  }

  .privacy-inner .privcy-head-sec h2 {
    font-size: 20px !important;
    margin-bottom: 7px !important;
  }

  .cookies-next {
    font-size: 19px !important;
  }

  .privacy-inner .privcy-head-sec h3 {
    font-size: 19px !important;
  }

  .privacy-inner .personal-data-align-sec .data-align-left {
    width: 100% !important;
  }

  .privacy-inner .personal-data-align-sec {
    display: block !important;
  }

  .privacy-inner .personal-data-align-sec .data-align-md {
    width: 2%;
    display: none !important;
  }

  .privacy-inner .personal-data-align-sec .data-align-right {
    width: 100% !important;
    font-size: 15px !important;
  }

  .privacy-inner .privcy-head-sec h2 {
    font-size: 19px !important;
  }

  .watch-video-sec .watcch-inner-sec .mobile-watch {
    background-color: transparent;
    padding: 13px 0px !important;
    box-shadow: none;
  }

  .watch-video-sec .watcch-inner-sec .mobile-watch p {
    font-size: 27px !important;
    padding: 0px 30px !important;
    margin-bottom: 5px;
  }

  .watch-video-sec .watcch-inner-sec .watch-video-play .watch-vid-play-inner .media-sec {
    width: 100% !important;
    height: auto !important;
  }

  .desk-watch {
    display: none !important;
  }

  .mobile-watch {
    display: block !important;
  }

  .main-sec-header {
    padding: 0px 20px;
  }

  .service-inner-sec .service-iiner:after {
    display: block;
  }

  .project-width {
    width: 100%;
  }

  .service-desc-sec {
    padding: 50px 50px 50px 50px;
  }

  .no-padding-right {
    padding-right: 0px;
    padding-left: 0px;
  }

  .service-head h2 {
    margin-bottom: 60px;
  }

  .no-padding-left {
    padding-right: 0px;
    padding-left: 0px;
  }

  .service-desc-inner {
    margin-bottom: 30px;
  }

  .navbar-toggler {
    border: none;
  }

  .nav-item {
    .nav-link {
      .fa-house {
        position: relative;
        top: 2px;
      }
    }
  }

  .project-acn-sec-main .project-acn-sec {
    padding-left: 19px;
    bottom: 20px;
  }

  .project-acn-sec .proj-actn-left {
    margin-bottom: 3px;
  }

  .btn-call {
    font-size: 13px;
    padding: 9px 13px 7px 17px;
  }

  .watch-video-sec .watcch-inner-sec .watch-video-play .watch-vid-play-inner {
    box-shadow: none;
    height: auto;
  }

  .watch-video-sec .watcch-inner-sec .watch-sec {
    padding: 53px 0px;
    width: 100%;
  }

  .watch-video-sec {
    padding: 20px 0px 60px 0px;
  }

  .watch-video-sec .watcch-inner-sec .watch-video-play .watch-vid-play-inner .media-sec {}

  .watch-video-sec .watcch-inner-sec .watch-video-play {
    width: 100%;
  }

  .main-sec-homes {
    height: 45vh;
  }

  .navbar {
    padding-top: 11px;
    padding-bottom: 11px;
  }

  .banner-content {
    bottom: 4vh !important;
    left: 8% !important;
  }

  .pre-expended-sec {
    height: 39vh !important;
  }

  .pre-expended-inner {
    bottom: 75px !important;
    position: absolute;
    bottom: -10px;
    text-align: center;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }

  body .about-main {
    height: 30vh !important;
  }

  .service-inner-sec img {
    height: 55px;
  }

  .banner-content {
    h1 {
      font-size: 34px !important;
      margin-bottom: 3px;
      text-shadow: 2px 2px rgba(128, 128, 128, 0.6) !important;
    }

    h2 {
      font-size: 34px !important;
      margin-bottom: 16px;
    }

    p {
      font-size: 16px !important;
      line-height: 31px !important;
    }
  }

  .service-sec {
    background-size: 100% 55%;
    margin-top: 10px;
  }

  .expert-sec h2 {
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 67px;
    margin-top: 74px;
  }

  .expert-sec p {
    font-size: 25px;
    line-height: 43px;
    margin-top: 30px;
  }

  .servic-dis-sec h2 {
    font-size: 23px !important;
    line-height: 36px !important;
    padding-top: 25px !important;
    margin-bottom: 30px;
  }

  .gap-sec {
    margin-left: 12px;
    margin-right: 12px;
  }

  .gap-sec a p {
    padding: 10px 0px 0px 0px;
    font-size: 14px;
    font-weight: 700;
  }

  .abut-banner-cont {
    h1 {
      font-size: 76px;
    }
  }

  .proj-sec-para p {
    font-size: 20px !important;
    line-height: 38px !important;
  }

  .multi-proj-sec {
    padding-bottom: 70px;
  }

  .text-projt p {
    font-size: 15px;
    line-height: 25px;
  }

  .career-para p {
    font-size: 18px !important;
    line-height: 32px !important;
    padding: 0px 10px;
  }

  .project-accodion-sec .MuiPaper-root .MuiButtonBase-root .MuiAccordionSummary-content .MuiTypography-root .acordian-head .acordian-head-right p {
    font-size: 15px;
  }

  .career-sec {
    padding: 0px 0px 60px 0px;
  }

  .project-accodion-sec .MuiPaper-root .MuiButtonBase-root .MuiAccordionSummary-content .MuiTypography-root .acordian-head .acordian-head-left img {
    height: 24px;
  }

  .pre-expended-inner {
    p {
      font-size: 37px;
      line-height: 54px;
    }
  }

  .proj-sec-para {
    padding: 60px 0px 15px 0px;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root {
    max-width: 400px;
  }

  .case-banner-below-inner h3 {
    font-size: 23px !important;
  }

  .case-stud-data p {
    font-size: 15px;
    line-height: 29px;
  }

  .roles-desc-sec ul li span {
    font-size: 15px;
    line-height: 29px;
  }

  .case-banner-below {
    padding: 60px 0px 50px 0px;
  }

  .project-accodion-sec .MuiPaper-root .MuiButtonBase-root .MuiAccordionSummary-content .MuiTypography-root .acordian-head .acordian-head-left p {
    font-size: 17px;
    top: 13px;
  }

  .accordion-inner-para p {
    font-size: 15px;
    line-height: 27px;
  }

  .img-sc {
    top: 7px;
  }

  .roles-desc-sec ul {
    margin-top: 25px;
  }

  .serveff-se {
    a {
      p {
        margin-bottom: 9px;
      }
    }
  }

  .margn-case {
    margin-top: 30px;
  }

  .servic-dis-sec {
    padding: 26px 0px 35px 0px;
  }

  .case-stud-sec-inner h3 {
    font-size: 23px !important;
    margin-bottom: 40px !important;
  }

  .accordion-inner-para h3 {
    font-size: 17px !important;
    margin-top: 15px;
  }

  .apply-sec .apply-btn {
    margin-left: 10px;
  }

  .contact-heading h2 {
    font-size: 25px !important;
    margin-bottom: 25px;
  }

  .career-newbgss {
    margin-top: 0px;
  }

  .nav-link::after {
    display: none;
  }

  .main-sec-home {
    background-position: 73% 100%;
  }

  .service-head h2 {
    font-size: 27px !important;
    margin-top: 0px;
    margin-bottom: 31px;
  }

  .service-inner-sec h3 {
    font-size: 15px;
    line-height: 23px;
  }

  .home-project-sec {
    padding: 20px 30px 0px 30px;
  }

  .service-inner-sec .service-iiner {
    width: 50%;
    margin-bottom: 20px;
  }

  .service-five {
    margin-top: 30px;
  }

  .service-two {
    height: 20vh;
  }

  .pre-expended-inner p {
    font-size: 20px !important;
    line-height: 41px !important;
  }

  .project-inner-sec h3 {
    max-width: 270px;
    font-size: 22px !important;
    line-height: 35px;
  }

  .project-sec-width {
    margin-right: 2px;
    margin-bottom: 5px;
    width: 100%;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root .MuiButtonBase-root {
    width: 50px;
    height: 50px;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root .MuiButtonBase-root svg {
    font-size: 33px;
    right: 1px;
    top: 0px;
  }

  .project-acn-sec-main .project-acn-sec .proj-actn-left a span {
    margin-right: 3px;
  }

  .partner-inner h2 {
    font-size: 20px;
  }

  .partner-inner h3 {
    font-size: 18px;
  }

  .footer-sec {
    padding: 60px 10px 10px 10px;
  }

  .partner-inner h3::after {
    margin-top: 22px;
  }

  .talk-inner h2 {
    font-size: 20px;
    line-height: 41px;
  }

  .partner-inner p {
    font-size: 15px;
    line-height: 30px;
  }

  .callltoactn {
    img {
      height: 23px;
    }
  }

  .vision-img {
    height: 60px;
    margin-bottom: 17px;
    float: left;
  }

  .vision-inner h2 {
    font-size: 21px;
  }

  .footer-width {
    width: 100%;
    margin-bottom: 20px;
  }

  .footer-sec .footer-left p {
    line-height: 25px;
    font-size: 14px;
  }

  .navbar-brand img {
    height: 23px;
  }

  .footer-sec .footer-left img {
    height: 25px;
  }

  .footer-sec .footer-center h3 {
    font-size: 17px;
  }

  .footer-sec .footer-mid-center h3 {
    font-size: 17px;
  }

  .footer-sec .footer-right h3 {
    font-size: 17px;
  }

  .abut-banner-cont {
    bottom: 6vh !important;

    h1 {
      font-size: 34px !important;
      line-height: 45px !important;
    }

    p {
      font-size: 17px !important;
    }
  }

  .expert-sec h2 {
    margin-bottom: 57px;
    margin-top: 54px;
  }

  .expert-sec h2 {
    font-size: 23px !important;
    line-height: 40px;
    margin-bottom: 40px !important;
    margin-top: 56px !important;
  }

  .expert-sec p {
    font-size: 16px !important;
    line-height: 32px !important;
  }

  .servic-inner-sec p {
    font-size: 17px !important;
    line-height: 31px !important;
  }

  .servic-visi {
    padding: 40px 0px 40px 0px;
  }

  .interven-sec img {
    right: 0px;
    height: 83px;
  }

  .expert-sec {
    padding: 65px 20px 10px 20px;
  }

  .case-heading h4 {
    font-size: 19px;
    bottom: 4px;
  }

  .watch-video-sec .watcch-inner-sec .watch-sec p {
    font-size: 32px;
    padding: 0px 30px;
  }

  .interven-sec h3 {
    font-size: 18px;
    line-height: 31px;
    padding: 25px 0px 26px 0px;
  }

  .nav-link {
    padding: 10px 9px !important;
    font-size: 14px;
  }

  .btn-call img {
    top: -1px;
    height: 15px;
  }

  .service-desc-inner h3 {
    margin: 15px 20px 25px 20px;
    line-height: 27px;
    font-size: 17px;
  }

  .service-desc-inner p {
    font-size: 15px;
    padding: 0px 15px;
    max-width: 500px;
  }

  .project-acn-sec-main .project-acn-sec .proj-actn-right {
    right: 7px;
    float: right;
  }

  .project-acn-sec-main .project-acn-sec {
    padding-left: 11px;
  }

  .project-acn-sec-main .project-acn-sec .proj-actn-right a {
    font-size: 14px;
  }

  .project-acn-sec-main .project-acn-sec .proj-actn-left a {
    font-size: 14px;
  }

  .project-acn-sec-main .project-acn-sec .proj-actn-left {
    margin-top: 0px;
  }

  .btn-sec {
    font-size: 14px;
  }

  .unique-sec {
    margin-top: 40px;
    padding-bottom: 15px;
  }

  .project-inner-sec img {
    height: 240px;
  }

  .vision-inner h3 {
    font-size: 15px;
    line-height: 25px;
    margin-top: 14px;
  }

  .partner-inner {
    padding: 40px 20px 40px 30px;
  }

  .talk-inner {
    padding: 71px 20px 79px 40px;
  }

  .footer-sec .footer-center ul li a span {
    font-size: 14px;
    font-weight: 500;
  }

  .footer-sec .footer-mid-center ul li a span {
    font-size: 14px;
    margin-left: 4px;
    font-weight: 500;
  }

  .footer-sec .footer-right ul li a span {
    font-size: 14px;
  }

  .hr-sec {
    margin: 10px 0px 40px 0px;
  }

  .btn-sec {
    padding: 12px 19px 9px 19px;
  }

  .gap-sec {
    padding: 10px 6px 10px 6px;

    img {
      height: 35px;
    }
  }

  .servic-njsmart-sec a img {
    height: 35px;
  }

  .footer-bottom-right {
    float: left;
    margin-top: 14px;
  }

  .servic-njsmart-sec a p {
    padding: 30px 2px 15px 1px;
    font-size: 14px;
  }

  .servic-njsmart-sec a img {
    padding: 0px 14px 0px 2px;
  }

  .overlay-text {
    padding: 10px 10px 30px 10px;
    font-size: 17px;
  }

  .main-projt-watch {
    top: 205px;
  }

  .custom-play-button {
    top: 46%;
  }

  .case-btn-sec {
    margin-top: 30px;
  }

  .case-stud-sec {
    padding: 60px 0px 60px 0px;
  }

  .video-main-secs {
    height: 65px;
    width: 65px;
  }

  .custom-play-button svg {
    height: 27px;
    transform: translate(-43%, 76%);
  }

  .contact-details .contact-info {
    left: 0px;
    width: 100%;
    padding: 40px 15px 10px 17px;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .career-newbgss {
    padding-top: 30px;
  }

  .contact-details {
    padding: 40px 0px;
  }

  .contact-details .contact-info h2 {
    font-size: 18px;
    margin-bottom: 45px;
  }

  .contact-details .contact-info .margin-cont-top {
    font-size: 15px;
  }

  .contact-details .contact-info p {
    margin-bottom: 17px;
    font-size: 15px;
  }

  .modal-no .MuiDialog-container .MuiPaper-root {
    padding: 30px 30px 25px 30px;
  }

  .contact-details .contact-info p i {
    top: 1px;
  }

  .contact-form {
    padding: 20px 50px 35px 30px;
  }

  .footer-sec .footer-right ul li a img {
    height: 18px;
  }

  .text-projt {
    left: 5%;
  }

  .main-projt-watch .projet-over-right a {
    font-size: 15px;
  }

  .main-projt-watch .projet-over-left a {
    font-size: 15px;
    cursor: pointer;
  }

  .navbar-collapse {
    border-top: 1px solid #b5b5b54f;
    margin-top: 10px;
  }

  .navbar-toggler:focus {
    box-shadow: none;
  }

  .mob-no {
    display: none;
  }

  .pre-expended-inner-new p {
    font-size: 19px !important;
    line-height: 31px !important;
  }

  .vision-sec {
    margin-top: 30px;
    margin-bottom: 0px;
  }

  .service-one {
    margin-bottom: 0px !important;
  }

  .service-five {
    margin-top: 0px;
  }

  .service-iiner {
    margin-top: 20px !important;
  }

  .service {
    margin-bottom: 10px;
  }

  .invalid-feedback .error-msg {
    padding-top: 0px;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root .MuiDialogContent-root p {
    font-size: 18px;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .button-sec {
    margin: 0 auto;
    text-align: center;
    margin-top: 20px;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root {
    margin: 0px;
    width: 100%;
    max-width: 400px;
    padding: 16px 25px 21px 25px;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root .MuiDialogContent-root h2 {
    font-size: 22px;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root .MuiDialogContent-root p {
    font-size: 16px;
    margin-bottom: 0px;
    margin-top: 10px;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .button-sec {
    margin-top: 15px;
  }

  .modal-yes .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .button-sec .btn-yes {
    width: 100% !important;
    font-size: 16px !important;
  }

  .desk-sec-new {
    display: none !important;
  }

  .mob-sec-new {
    display: block !important;
  }

  .mob-sec-new {
    height: 35vh;
  }

  .mob-sec-new .main-sec-home {
    background-position: 73% 2%;
  }

  .service-headss p {
    font-size: 17px !important;
    line-height: 39px !important;
    margin-bottom: 0px !important;
  }

  .service-headss {
    margin-bottom: 40px !important;
  }

  .service-seccc {
    margin-top: 0px !important;
  }

  .servics-main-secsss {
    padding: 0px 35px !important;
  }

  .service-desc-inner {
    min-height: calc(70vh - 200px) !important;
  }

  .service-five:after {
    display: none !important;
  }

  .loader {
    margin: 50% auto !important;
  }

  .vision-inner p {
    font-size: 15px !important;
    line-height: 24px;
  }

  .footer-sec .footer-center ul {
    margin-top: 20px;
  }

  .footer-sec .footer-left p {
    margin-top: 20px;
  }

  .footer-sec .footer-mid-center ul {
    margin-top: 20px;
  }

  .footer-sec .footer-right ul {
    margin-top: 20px;
  }

  .about-main {
    display: none !important;
  }

  .mob-sec-new {
    display: block !important;
  }

  .mob-sec-new {
    .about-sec-banner {
      background-position: 70% 53% !important;
    }
  }

  .service-iew-sec {
    padding-left: 1px !important;
  }

  .service-iew-sec .serv-left-view a span {
    font-size: 14px !important;
  }

  .servic-other-sec .gap-new-sec {
    height: 23vh !important;
  }

  .servics-sec .servic-njsmart-sec-new {
    height: 47.9vh !important;
  }

  .footer-sec .footer-left p {
    width: 100% !important;
    max-width: 100% !important;
  }

  .servicess-divid .srvicess-left img {
    height: 90px !important;
    margin-bottom: 40px !important;
    top: 0px !important;
  }

  .servicess-divid .srvicess-right p {
    font-size: 15px !important;
    line-height: 29px !important;
  }

  .point-sec-service {
    padding: 30px 0px 10px 0px !important;
  }

  .servicess-inner-sec {
    padding: 50px 0px !important;
  }

  .srvicess-left:after {
    display: none !important;
  }

  .servicess-divid .srvicess-right h2 {
    font-size: 24px !important;
    margin-bottom: 14px !important;
  }

  .serv-inner-desk {
    display: none !important;
  }

  .serv-inner-mob {
    display: block !important;
  }

  .servicess-inner-para p {
    font-size: 15px !important;
    line-height: 31px !important;
  }

  .servicess-divid {
    padding: 40px 15px 10px 15px !important;
  }

  .srvicess-right ul li {
    font-size: 17px !important;
  }

  .progrme-info-sec img {
    width: 100% !important
  }

  .progrme-info-sec h2 {
    font-size: 25px !important;
    margin-bottom: 13px !important;
  }

  .progrme-info-sec {
    padding: 50px 0px 40px 0px !important;
  }

  .progrme-info-sec p {
    font-size: 15px !important;
    line-height: 27px !important;
  }

  .modal-career .MuiDialog-container .MuiPaper-root {
    max-width: 380px !important;

  }

  .modal-career .MuiDialog-container .MuiPaper-root .MuiDialogContent-root h2 {
    font-size: 21px !important;
  }

  .project-accodion-sec .MuiPaper-root .MuiButtonBase-root .MuiAccordionSummary-content .MuiTypography-root .acordian-head .acordian-head-left img {
    margin-right: 10px !important;
  }

  .wth-contact {
    width: 50% !important;
  }

  .connect-mail-new {
    font-size: 15px !important;
  }

  .contact-info-new .mail-sec-new {
    margin-bottom: 10px !important;
  }

  .reol-ul-sec .case-left {
    width: 100% !important;
    font-size: 17px !important;
    float: none !important;
  }

  .reol-ul-sec .case-right {
    width: 100% !important;
    font-size: 15px !important;
    margin-top: 4px !important;
  }

  .out-ul-sec li {
    font-size: 15px !important;
  }

  .reol-ul-sec li {
    margin-bottom: 23px !important;
  }

  .CookieConsent {
    font-size: 14px !important;
    line-height: 25px !important;
    margin: 0px 0px !important;

    div {
      margin: 10px auto 0px auto !important;
      width: 100% !important;
    }

  }

  .service-btn-sec .sev-mob-btn {
    padding: 10px 15px 9px 15px !important;
    font-size: 14px;
  }

  .talk-sec .talk-btn-sec {
    padding: 11px 15px 9px 15px !important;
    line-height: 22px !important;
  }

  .talk-sec .explore-btn {
    padding: 11px 15px 9px 15px !important;
    line-height: 22px !important;
    margin-left: 5px !important;
  }

  .service {
    margin-bottom: 45px !important;
  }

  .service .touch-sec {
    margin-top: 20px !important;
  }

  .leader-sec a {
    font-size: 16px !important;
  }

  .prev-sec-service {
    margin-right: 14px !important;
    width: 100% !important;
    display: block;
    margin-bottom: 12px;

  }

  .prev-sec-service:after {
    display: none !important;
  }

  .ser-page-sec a {
    font-size: 15px !important;
    margin-top: 0px !important;
  }

  .btn-ser-explre {
    margin-top: 20px !important;
  }

  .book-demo-sec .btn-book-sec {
    position: relative !important;

    float: left !important;
  }

  .case-new-insd h3 {
    margin-bottom: 50px !important;
  }

  .case-new-mar {
    margin-bottom: 30px !important;
  }

  .final-modal .MuiDialog-container .MuiPaper-root {
    max-width: 400px !important;
  }

  .final-modal .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .button-sec {
    margin-top: 20px !important;
  }

  .final-modal .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .MuiTypography-root .button-case .btn-yes {
    font-size: 16px !important;
  }

  body .final-modal .MuiDialog-scrollPaper .MuiPaper-elevation {
    height: 81vh !important;
  }

  .final-modal .form-sec .MuiBox-root .MuiFormControl-root label {
    font-size: 14px !important;
  }

  .final-modal .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .MuiTypography-root .form-sec-main .form-radio-sec .MuiFormControl-root label {
    font-size: 15px !important;
    font-weight: 600 !important;
  }

  .final-modal .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .MuiTypography-root .form-sec-main .form-radio-sec .MuiFormControl-root label .MuiTypography-root {
    font-size: 14px !important;
  }

  .final-modal .MuiDialog-container .MuiPaper-root .MuiDialogContent-root p .form-sec-main .form-sec .case-privacy {
    font-size: 14px !important;
  }

  .otp-modal-new .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .otp-head {
    font-size: 15px !important;
    line-height: 24px !important;
  }

  .otp-modal-new .MuiDialogContent-root .validate-sec-mode .error-msg-sec .error-msg {
    font-size: 14px !important;
    margin-top: 5px !important;
  }

  .otp-modal-new .MuiDialog-container .MuiPaper-root {
    width: 100% !important;
    max-width: 400px !important;
  }

  .otp-modal-new .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .button-case {
    margin-top: 20px !important;
  }

  .otp-modal-new .MuiDialogContent-root .validate-sec-mode .error-msg-sec .error-msg {
    font-size: 13px !important;
  }

  .otp-modal-new .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .btn-otp .btn-sub {
    font-size: 15px !important;
    padding: 10px 0px 7px 0px !important;
    margin-right: 0px !important;
  }

  .otp-form .otp-sec-input {
    padding: 8px 0px !important;
    margin-right: 14px !important;
  }

  .otp-main-secss {
    width: 100% !important;
    max-width: 270px !important;
    margin: 0 auto !important;
  }

  .otp-modal-new .MuiDialogContent-root .otp-main-secss .width-otp .error-msg-sec .error-msg {
    font-size: 13px !important;
  }

  .case-pre-new {
    width: 100%;
    position: relative;
    display: block;
    margin-bottom: 15px;
    font-size: 15px !important;
  }

  .case-pre-new a {
    margin-right: 0px;
    margin-bottom: 10px;
  }

  .case-back-new a {
    font-size: 15px !important;
  }

  .final-modal .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .new-demo-para {
    font-size: 16px !important;
    line-height: 26px !important;
  }

  .final-modal .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .new-demo-head {
    font-size: 16px !important;
  }

  .thank-you-inner {
    width: 100%;
    max-width: 410px !important;
  }

  .thankyou-sec h2 {
    font-size: 27px !important;
    margin: 15px 0px 10px 0px !important;
  }

  .thankyou-sec p {
    font-size: 15px !important;
    line-height: 25px !important;
    margin-top: 16px !important;
  }

  .vasestremco {
    font-size: 12px !important;
    line-height: 47px   !important;
    color: #0078ae  !important;
    margin-top: 20px  !important;
  }

  .thankyou-sec {
    padding: 150px 0px 80px 0px !important;
  }

  .thank-you-inner svg {
    width: 100px;
    height: 100px;
  }

  .bg-book {
    padding: 40px 0px 65px 0px !important;
  }

  .cse-new .btn-submit {
    font-size: 16px !important;
  }

  .bok-iner-head h2 {
    font-size: 22px !important;
  }

  .book-sec-project .book-inner-projt {
    max-width: 410px !important;
  }

  .info-privacy-sec {
    text-align: left;
    font-size: 15px !important;
    line-height: 23px !important;
  }

  .line-after-case:after {
    display: none !important;
  }

  .service-sec {
    background-size: initial !important;
    background-repeat: repeat-y !important;
  }

  .career-newbg {
    background-size: initial !important;
    background-repeat: repeat-y !important;
  }

  .project-accodion-sec .MuiPaper-root .MuiButtonBase-root .MuiAccordionSummary-content .MuiTypography-root .acordian-head .acordian-head-left p {
    font-size: 16px !important;

  }

  .project-accodion-sec .MuiPaper-root .MuiButtonBase-root .MuiAccordionSummary-content .MuiTypography-root .acordian-head .acordian-head-right {
    float: none;
    width: 100%;
    position: relative;
    display: flex;
    padding-top: 22px;
  }
  .nav-new-menu a.active:before{
    display: none;
  }
  .nav-new {
    padding: 10px 9px !important;
}
body .nav-new {
  padding: 10px 9px !important;
}
.nav-new{
  margin-left: 0px !important;
}
.nav-link:hover:before{
  display: none !important;
}

}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .case-pre-new {
    width: 100%;
    position: relative;
    display: block;
    margin-bottom: 15px;
  }

  .modal-cases .MuiDialog-container .MuiPaper-root {
    max-width: 490px !important;
  }

  .case-stud-data .reol-ul-sec li p {
    display: block !important;
  }

  .CookieConsent {
    font-size: 14px !important;
    line-height: 25px !important;
    margin: 0px 0px !important;

    div {
      margin: 10px auto 0px auto !important;
      width: 100% !important;
    }

  }

  .reol-ul-sec .case-left {
    width: 100% !important;
    font-size: 17px !important;
    float: none !important;
  }

  .reol-ul-sec .case-right {
    width: 100% !important;
    font-size: 15px !important;
  }

  .out-ul-sec li {
    font-size: 15px !important;
  }

  .progrme-info-sec p {
    font-size: 16px !important;
    line-height: 27px !important;
  }

  .progrme-info-sec h2 {
    font-size: 28px !important;
    margin-bottom: 18px !important;
  }

  .progrme-info-sec {
    padding: 50px 0px 40px 0px !important;
  }

  .progrme-info-sec img {
    width: 100% !important
  }

  .footer-sec .footer-left p {
    width: 100% !important;
    max-width: 100% !important;
  }

  .watch-video-sec .watcch-inner-sec .mobile-watch {
    background-color: transparent;
    padding: 13px 0px !important;
    box-shadow: none;
  }

  .watch-video-sec .watcch-inner-sec .mobile-watch p {
    font-size: 28px !important;
    padding: 0px 30px !important;
  }

  .watch-video-sec .watcch-inner-sec .watch-video-play .watch-vid-play-inner .media-sec {
    width: 100% !important;
    height: auto !important;
  }

  .desk-watch {
    display: none !important;
  }

  .mobile-watch {
    display: block !important;
  }

  .service-inner-sec .service-iiner:after {
    display: block;
  }

  .project-width {
    width: 100%;
  }

  .service-desc-sec {
    padding: 50px 50px 50px 50px;
  }

  .no-padding-right {
    padding-right: 0px;
    padding-left: 0px;
  }

  .service-head h2 {
    margin-bottom: 60px;
  }

  .no-padding-left {
    padding-right: 0px;
    padding-left: 0px;
  }

  .service-desc-inner {
    margin-bottom: 30px;
  }

  .navbar-toggler {
    border: none;
  }

  .nav-item {
    .nav-link {
      .fa-house {
        position: relative;
        top: 2px;
      }
    }
  }

  .project-acn-sec-main .project-acn-sec {
    padding-left: 19px;
    bottom: 20px;
  }

  .project-acn-sec .proj-actn-left {
    margin-bottom: 3px;
  }

  .btn-call {
    font-size: 13px;
    padding: 9px 13px 7px 17px;
  }

  .watch-video-sec .watcch-inner-sec .watch-video-play .watch-vid-play-inner {
    box-shadow: none;
    height: auto;
  }

  .watch-video-sec .watcch-inner-sec .watch-sec {
    padding: 53px 0px;
    width: 100%;
  }

  .watch-video-sec {
    padding: 20px 0px 60px 0px;
  }

  .watch-video-sec .watcch-inner-sec .watch-video-play .watch-vid-play-inner .media-sec {}

  .watch-video-sec .watcch-inner-sec .watch-video-play {
    width: 100%;
  }

  .main-sec-homes {
    height: 39vh;
  }

  .navbar {
    padding-top: 11px;
    padding-bottom: 11px;
  }

  .banner-content {
    bottom: 5vh !important;
    left: 8% !important;
  }

  .pre-expended-sec {
    height: 39vh !important;
  }

  .pre-expended-inner {
    bottom: 75px !important;
    position: absolute;
    bottom: -10px;
    text-align: center;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }

  body .about-main {
    height: 26vh !important;
  }

  .service-inner-sec img {
    height: 55px;
  }

  .banner-content {
    h1 {
      font-size: 45px !important;
      margin-bottom: 3px;
    }

    h2 {
      font-size: 45px !important;
      margin-bottom: 16px;
    }

    p {
      font-size: 19px !important;
      line-height: 35px !important;
    }
  }

  .service-sec {
    background-size: 100% 55%;
  }

  .expert-sec h2 {
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 67px;
    margin-top: 74px;
  }

  .expert-sec p {
    font-size: 25px;
    line-height: 43px;
    margin-top: 30px;
  }

  .servic-dis-sec h2 {
    font-size: 25px !important;
    line-height: 40px !important;
    padding-top: 25px !important;
    margin-bottom: 30px;
  }

  .gap-sec {
    margin-left: 12px;
    margin-right: 12px;
  }

  .gap-sec a p {
    padding: 10px 0px 0px 0px;
    font-size: 14px;
    font-weight: 700;
  }

  .abut-banner-cont {
    h1 {
      font-size: 76px;
    }
  }

  .proj-sec-para p {
    font-size: 20px !important;
    line-height: 35px !important;
  }

  .text-projt p {
    font-size: 15px;
    line-height: 25px;
  }

  .career-para p {
    font-size: 18px !important;
    line-height: 31px !important;
  }

  .career-sec {
    padding: 0px 0px 60px 0px;
  }

  .project-accodion-sec .MuiPaper-root .MuiButtonBase-root .MuiAccordionSummary-content .MuiTypography-root .acordian-head .acordian-head-left img {
    height: 24px;
    margin-right: 6px;
  }

  .pre-expended-inner {
    p {
      font-size: 37px;
      line-height: 54px;
    }
  }

  .proj-sec-para {
    padding: 60px 0px 15px 0px;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root {
    max-width: 470px;
  }

  .case-banner-below-inner h3 {
    font-size: 24px !important;
  }

  .case-stud-data p {
    font-size: 16px;
    line-height: 31px;
  }

  .roles-desc-sec ul li span {
    font-size: 15px;
    line-height: 29px;
  }

  .case-banner-below {
    padding: 60px 0px 50px 0px;
  }

  .project-accodion-sec .MuiPaper-root .MuiButtonBase-root .MuiAccordionSummary-content .MuiTypography-root .acordian-head .acordian-head-left p {
    font-size: 16px;
    top: 13px;
  }

  .accordion-inner-para p {
    font-size: 15px;
    line-height: 27px;
  }

  .img-sc {
    top: 7px;
  }

  .roles-desc-sec ul {
    margin-top: 25px;
  }

  .serveff-se {
    a {
      p {
        margin-bottom: 9px;
      }
    }
  }

  .margn-case {
    margin-top: 30px;
  }

  .servic-dis-sec {
    padding: 41px 0px 51px 0px;
  }

  .case-stud-sec-inner h3 {
    font-size: 22px !important;
    margin-bottom: 40px !important;
  }

  .accordion-inner-para h3 {
    font-size: 18px !important;
    margin-top: 35px;
  }

  .contact-heading h2 {
    font-size: 25px !important;
    margin-bottom: 15px;
  }

  .career-newbgss {
    margin-top: 0px;
  }

  .nav-link::after {
    display: none;
  }

  .main-sec-home {
    background-position: 78% 100%;
  }

  .service-head h2 {
    font-size: 28px !important;
    margin-top: 0px;
    margin-bottom: 50px;
  }

  .service-inner-sec h3 {
    font-size: 15px;
    line-height: 23px;
  }

  .service-inner-sec .service-iiner {
    width: 50%;
    margin-bottom: 25px;
  }

  .service-five {
    margin-top: 30px;
  }

  .service-two {
    height: 20vh;
  }

  .pre-expended-inner p {
    font-size: 20px !important;
    line-height: 41px !important;
  }

  .project-inner-sec h3 {
    max-width: 270px;
    font-size: 21px !important;
    line-height: 33px;
  }

  .project-sec-width {
    margin-right: 2px;
    margin-bottom: 5px;
    width: 49.5%;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root .MuiButtonBase-root {
    width: 60px;
    height: 60px;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root .MuiButtonBase-root svg {
    font-size: 36px;
    right: -1px;
  }

  .project-acn-sec-main .project-acn-sec .proj-actn-left a span {
    margin-right: 3px;
  }

  .partner-inner h2 {
    font-size: 20px;
  }

  .partner-inner h3 {
    font-size: 18px;
  }

  .footer-sec {
    padding: 60px 0px 10px 0px;
  }

  .partner-inner h3::after {
    margin-top: 22px;
  }

  .talk-inner h2 {
    font-size: 20px;
    line-height: 41px;
  }

  .partner-inner p {
    font-size: 15px;
    line-height: 30px;
  }

  .callltoactn {
    img {
      height: 23px;
    }
  }

  .vision-img {
    height: 65px;
    margin-bottom: 20px;
    float: left;
  }

  .vision-inner h2 {
    font-size: 22px;
  }

  .footer-width {
    width: 50%;
    margin-bottom: 15px;
  }

  .footer-sec .footer-left p {
    line-height: 25px;
    font-size: 14px;
  }

  .navbar-brand img {
    height: 23px;
  }

  .footer-sec .footer-left img {
    height: 26px;
  }

  .footer-sec .footer-center h3 {
    font-size: 18px;
  }

  .footer-sec .footer-mid-center h3 {
    font-size: 18px;
  }

  .footer-sec .footer-right h3 {
    font-size: 18px;
  }

  .abut-banner-cont {
    bottom: 6vh !important;

    h1 {
      font-size: 30px !important;
    }

    p {
      font-size: 19px !important;
    }
  }

  .expert-sec h2 {
    margin-bottom: 57px;
    margin-top: 54px;
  }

  .expert-sec h2 {
    font-size: 23px !important;
    line-height: 40px;
  }

  .expert-sec p {
    font-size: 17px !important;
    line-height: 32px !important;
  }

  .servic-inner-sec p {
    font-size: 18px !important;
    line-height: 34px !important;
  }

  .servic-visi {
    padding: 40px 0px 40px 0px;
  }

  .interven-sec img {
    right: 0px;
    height: 83px;
  }

  .expert-sec {
    padding: 65px 20px 10px 20px;
  }

  .case-heading h4 {
    font-size: 19px;
    bottom: 4px;
  }

  .watch-video-sec .watcch-inner-sec .watch-sec p {
    font-size: 32px;
    padding: 0px 30px;
  }

  .interven-sec h3 {
    font-size: 19px;
    line-height: 31px;
    padding: 25px 0px 26px 0px;
  }

  .nav-link {
    padding: 10px 9px !important;
    font-size: 14px;
  }

  .btn-call img {
    top: -1px;
    height: 15px;
  }

  .service-desc-inner h3 {
    margin: 15px 20px 25px 20px;
    line-height: 30px;
  }

  .service-desc-inner p {
    font-size: 15px;
    padding: 0px 35px;
    max-width: 500px;
  }

  .project-acn-sec-main .project-acn-sec .proj-actn-right {
    right: 7px;
    float: right;
  }

  .project-acn-sec-main .project-acn-sec {
    padding-left: 11px;
  }

  .project-acn-sec-main .project-acn-sec .proj-actn-right a {
    font-size: 14px;
  }

  .project-acn-sec-main .project-acn-sec .proj-actn-left a {
    font-size: 14px;
  }

  .project-acn-sec-main .project-acn-sec .proj-actn-left {
    margin-top: 0px;
  }

  .btn-sec {
    font-size: 14px;
  }

  .unique-sec {
    margin-top: 70px;
    padding-bottom: 30px;
  }

  .project-inner-sec img {
    height: 240px;
  }

  .vision-inner h3 {
    font-size: 16px;
    line-height: 27px;
    margin-top: 12px;
  }

  .partner-inner {
    padding: 40px 20px 40px 30px;
  }

  .talk-inner {
    padding: 71px 20px 79px 40px;
  }

  .footer-sec .footer-center ul li a span {
    font-size: 14px;
    font-weight: 500;
  }

  .footer-sec .footer-mid-center ul li a span {
    font-size: 14px;
    margin-left: 4px;
    font-weight: 500;
  }

  .footer-sec .footer-right ul li a span {
    font-size: 14px;
  }

  .hr-sec {
    margin: 50px 0px 40px 0px;
  }

  .btn-sec {
    padding: 12px 22px 9px 22px;
  }

  .gap-sec {
    padding: 10px 6px 10px 6px;

    img {
      height: 35px;
    }
  }

  .servic-njsmart-sec a img {
    height: 35px;
  }

  .footer-bottom-right {
    float: left;
    margin-top: 14px;
  }

  .servic-njsmart-sec a p {
    padding: 60px 2px 15px 1px;
    font-size: 14px;
  }

  .servic-njsmart-sec a img {
    padding: 0px 14px 0px 2px;
  }

  .overlay-text {
    padding: 10px 10px 30px 10px;
    font-size: 16px;
  }

  .main-projt-watch {
    top: 195px;
  }

  .custom-play-button {
    top: 50%;
  }

  .case-btn-sec {
    margin-top: 30px;
  }

  .case-stud-sec {
    padding: 50px 0px 40px 0px;
  }

  .video-main-secs {
    height: 65px;
    width: 65px;
  }

  .custom-play-button svg {
    height: 27px;
    transform: translate(-43%, 76%);
  }

  .contact-details .contact-info {
    left: 0px;
    width: 100%;
    padding: 40px 15px 10px 17px;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .career-newbgss {
    padding-top: 30px;
  }

  .contact-details {
    padding: 40px 0px;
  }

  .contact-details .contact-info h2 {
    font-size: 18px;
    margin-bottom: 45px;
  }

  .contact-details .contact-info .margin-cont-top {
    font-size: 15px;
  }

  .contact-details .contact-info p {
    margin-bottom: 17px;
    font-size: 15px;
  }

  .contact-details .contact-info p i {
    top: 1px;
  }

  .contact-form {
    padding: 20px 50px 35px 30px;
  }

  .footer-sec .footer-right ul li a img {
    height: 18px;
  }

  .text-projt {
    left: 5%;
  }

  .main-projt-watch .projet-over-right a {
    font-size: 15px;
  }

  .main-projt-watch .projet-over-left a {
    font-size: 15px;
    cursor: pointer;
  }

  .navbar-collapse {
    border-top: 1px solid #b5b5b54f;
    margin-top: 10px;
  }

  .navbar-toggler:focus {
    box-shadow: none;
  }

  .mob-no {
    display: none;
  }

  .pre-expended-inner-new p {
    font-size: 19px !important;
    line-height: 34px !important;
  }

  .vision-sec {
    margin-top: 50px;
    margin-bottom: 0px;
  }

  .service-one {
    margin-bottom: 0px !important;
  }

  .service-five {
    margin-top: 0px;
  }

  .service-iiner {
    margin-top: 20px !important;
  }

  .service {
    margin-bottom: 50px;
  }

  .invalid-feedback .error-msg {
    padding-top: 0px;
  }

  .modal-no .MuiDialog-container .MuiPaper-root {
    padding: 30px 20px 20px 20px;
  }

  .modal-no .MuiDialog-container .MuiPaper-root .MuiDialogContent-root p {
    margin-top: 8px !important;
    font-weight: 600;
    margin-bottom: 10px;
    font-size: 20px;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root {
    margin: 0px;
    width: 100%;
    max-width: 400px;
    padding: 16px 25px 21px 25px;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root .MuiDialogContent-root h2 {
    font-size: 22px;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root .MuiDialogContent-root p {
    font-size: 18px;
    margin-bottom: 0px;
    margin-top: 10px;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .button-sec {
    margin-top: 15px;
  }

  .service-headss p {
    font-size: 20px !important;
    font-weight: 600;
    line-height: 25px !important;
    margin-bottom: 13px !important;
  }

  .service-desc-inner {
    min-height: calc(70vh - 200px) !important;
  }

  .service-desc-new {
    padding: 30px 25px 60px 25px !important;
  }

  .service-five {
    margin-top: 50px !important;
  }

  .vision-inner p {
    font-size: 16px !important;
  }

  .servics-main-secsss {
    padding: 0px 55px 0px 55px !important;
  }

  .servic-other-sec .gap-new-sec {
    height: 23vh;
  }

  .service-iew-sec {
    padding-left: 1px !important;
  }

  .service-iew-sec .serv-left-view a span {
    font-size: 14px !important;
  }

  .service-iew-sec .serv-left-view a i {
    position: relative;
    top: 2px;
    font-size: 15px !important;
  }

  .njsmart-bg {
    background-position: 90% 100% !important;
  }

  .workflow-bg {
    background-position: 90% 100% !important;
  }

  .effice-bg {
    background-position: 90% 100% !important;
  }

  .gap-analys-bg {
    background-position: 99% 100% !important;
  }

  .programming-head-img {
    background-position: 90% 100% !important;
  }

  .servicess-inner-para p {
    font-size: 15px !important;
    line-height: 30px !important;
  }

  .servicess-divid {
    padding: 50px 0px 10px 0px !important;
  }

  .servicess-divid .srvicess-left img {
    height: 90px !important;
    margin-bottom: 60px !important;
    top: 0px !important;
  }

  .servicess-divid .srvicess-right p {
    font-size: 15px !important;
    line-height: 29px !important;
  }

  .servicess-inner-sec {
    padding: 50px 0px !important;
  }

  .srvicess-left:after {
    display: none !important;
  }

  .servicess-divid .srvicess-right h2 {
    font-size: 25px !important;
    margin-bottom: 15px !important;
  }

  .serv-inner-desk {
    display: none !important;
  }

  .serv-inner-mob {
    display: block !important;
  }

  .point-sec-service {
    padding: 20px 0px 0px 0px !important;
  }

  .point-srvicess-left img {
    top: 10px !important;
  }

  .srvicess-right ul li {
    font-size: 17px !important;
  }

  .loader {
    margin: 60% auto !important;
  }

  .multi-proj-sec {
    padding: 0px 50px !important;
  }

  .bg-sec-new {
    background-size: 100% 45% !important;
  }

  .wth-contact {
    width: 50% !important;
  }

  .connect-mail-new {
    font-size: 15px;
  }

  .small-serv-left-view {
    padding-left: 1px !important;
  }

  .abut-banner-cont h1 {
    line-height: 39px !important;
  }

  .service-banner {
    bottom: 5vh !important;
  }

  .service-btn-sec .sev-banr-btn {
    padding: 10px 16px 8px 16px !important;
  }

  .service {
    margin-bottom: 50px !important;
  }

  .service .touch-sec {
    margin-top: 20px !important;
  }

  .leader-sec a {
    font-size: 16px !important;
  }


  .book-demo-sec .btn-book-sec {
    position: relative !important;

    float: left !important;
  }

  .case-new-insd h3 {
    margin-bottom: 50px !important;
  }
  .thank-amaze{
    max-width: 436px !important;
  }

  .case-new-mar {
    margin-bottom: 30px !important;
  }

  .otp-modal-new .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .otp-head {
    font-size: 17px !important;
    line-height: 28px !important;
  }

  .otp-modal-new .MuiDialogContent-root .validate-sec-mode .error-msg-sec .error-msg {
    font-size: 14px !important;
    margin-top: 5px !important;
  }

  .otp-modal-new .MuiDialog-container .MuiPaper-root {
    width: 100% !important;
    max-width: 500px !important;
  }

  .final-modal .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .new-demo-para {
    font-size: 16px !important;
    line-height: 26px !important;
  }

  .final-modal .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .new-demo-head {
    font-size: 16px !important;
  }

  .thank-you-inner {
    width: 100%;
    max-width: 500px !important;
  }

  .thankyou-sec h2 {
    font-size: 30px !important;
    margin: 15px 0px 12px 0px !important;
  }


      .thank-you-inner svg{
        width: 100px;
        height: 100px;
      }
      .bg-book {
        padding:45px 0px 75px 0px !important;
    }
    .cse-new .btn-submit{
      font-size: 16px !important;
    }
    .bok-iner-head h2 {
      font-size: 23px !important;}
      .prev-sec-service:after{
        right: -21px !important;
        height: 29px !important;
        margin-top: -26px !important;
      }
      .line-after-case:after{
        display: none !important;
      }
      .service-sec {
        background-size: initial !important;
        background-repeat: repeat-y !important;
    }
    .nav-new-menu a.active:before{
      display: none;
    }
    body .nav-new {
      padding: 10px 9px !important;
  }
  .nav-new{
    margin-left: 0px !important;
  }
  .nav-link:hover:before{
    display: none !important;
  }

  .thankyou-sec p {
    font-size: 16px !important;
  }

  .vasestremco {
    font-size: 12px !important;
    line-height: 47px   !important;
    color: #0078ae  !important;
    margin-top: 20px  !important;
  }

  .thankyou-sec {
    padding: 150px 0px 80px 0px !important;
  }

  .thank-you-inner svg {
    width: 100px;
    height: 100px;
  }

  .bg-book {
    padding: 45px 0px 75px 0px !important;
  }

  .cse-new .btn-submit {
    font-size: 16px !important;
  }

  .bok-iner-head h2 {
    font-size: 23px !important;
  }

  .prev-sec-service:after {
    right: -21px !important;
    height: 29px !important;
    margin-top: -26px !important;
  }

  .line-after-case:after {
    display: none !important;
  }

  .service-sec {
    background-size: initial !important;
    background-repeat: repeat-y !important;
  }
  .thank-you-inner .thank-presting{
    font-size: 15px !important;
  }

}



@media only screen and (min-width: 768px) and (max-width: 991px) {
  .thank-presting{
    max-width: 470px !important;
  }
  .bg-book {
    padding: 45px 0px 75px 0px !important;
  }

  .cse-new .btn-submit {
    font-size: 16px !important;
  }

  .service-btn-sec .sev-banr-btn {
    padding: 10px 16px 8px 16px !important;
  }

  .reol-ul-sec .case-left {
    width: 30% !important;
  }

  .reol-ul-sec .case-right {
    width: 100% !important;
  }

  .progrme-info-sec p {
    font-size: 16px !important;
    line-height: 27px !important;
  }

  .progrme-info-sec h2 {
    font-size: 28px !important;
    margin-bottom: 18px !important;
  }

  .progrme-info-sec {
    padding: 50px 0px 40px 0px !important;
  }

  .progrme-info-sec img {
    width: 100% !important
  }

  .serv-inner-desk {
    display: block;
  }

  .serv-inner-mob {
    display: none;
  }

  .loader {
    margin: 45% auto !important;
  }

  .privacy-inner .privcy-head-sec .after-head {
    font-size: 24px !important;
    margin-bottom: 40px;
  }

  .servics-sec .servic-njsmart-sec-new {
    height: 43.4vh !important;
  }

  // .modal-casestudy
  //   .MuiDialog-container
  //   .MuiPaper-root
  //   .MuiDialogContent-root
  //   .button-sec
  //   .btn-yes {
  //   width: 85px !important;
  // }
  // .modal-casestudy
  //   .MuiDialog-container
  //   .MuiPaper-root
  //   .MuiDialogContent-root
  //   .button-sec
  //   .btn-no {
  //   width: 85px !important;
  // }
  .cookies-next {
    font-size: 21px;
  }

  .service-full-width {
    width: 100% !important;
  }

  .project-width {
    width: 50%;
  }

  .service-desc-sec {
    padding: 10px 0px 60px 0px !important;
  }

  .no-padding-right {
    padding-right: 0px;
    padding-left: 0px;
  }

  .service-head h2 {
    margin-bottom: 60px;
  }

  .no-padding-left {
    padding-right: 0px;
    padding-left: 0px;
  }

  .service-desc-inner {
    margin-bottom: 16px;
    min-height: calc(58vh - 200px) !important;
  }

  .navbar-toggler {
    border: none;
  }

  .nav-item {
    .nav-link {
      .fa-house {
        position: relative;
        top: 2px;
      }
    }
  }

  .project-acn-sec-main .project-acn-sec {
    padding-left: 19px;
    bottom: 20px;
  }

  .project-acn-sec .proj-actn-left {
    margin-bottom: 3px;
  }

  .btn-call {
    font-size: 13px;
    padding: 9px 13px 7px 17px;
  }

  .watch-video-sec .watcch-inner-sec .watch-video-play .watch-vid-play-inner {
    box-shadow: none;
  }

  .watch-video-sec .watcch-inner-sec .watch-sec {
    padding: 53px 0px;
  }

  .watch-video-sec {
    padding: 60px 0px 60px 0px;
  }

  .watch-video-sec .watcch-inner-sec .watch-video-play .watch-vid-play-inner .media-sec {}

  .main-sec-homes {
    height: 40vh;
  }

  .navbar {
    padding-top: 12px;
    padding-bottom: 11px;
  }

  .banner-content {
    bottom: 8vh !important;
    left: 8% !important;
  }

  .footer-sec .footer-mid-center ul {
    margin-top: 14px;
  }

  .footer-sec .footer-right ul {
    margin-top: 14px;
  }

  .footer-sec .footer-left p {
    margin-top: 14px;
  }

  .footer-sec .footer-center ul {
    margin-top: 14px;
  }

  .pre-expended-sec {
    height: 39vh !important;
  }

  .pre-expended-inner {
    bottom: 75px !important;
    position: absolute;
    bottom: -10px;
    text-align: center;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }

  body .about-main {
    height: 25vh !important;
  }

  .service-inner-sec img {
    height: 55px;
  }

  .banner-content {
    h1 {
      font-size: 55px !important;
      margin-bottom: 3px;
    }

    h2 {
      font-size: 55px !important;
    }

    p {
      font-size: 23px !important;
      line-height: 39px !important;
    }
  }

  .expert-sec h2 {
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 67px;
    margin-top: 74px;
  }

  .expert-sec p {
    font-size: 25px;
    line-height: 43px;
    margin-top: 30px;
  }

  .servic-dis-sec h2 {
    font-size: 28px !important;
    line-height: 45px !important;
    padding-top: 0px !important;
    padding-bottom: 35px;
  }

  .servicess-divid .srvicess-right p {
    font-size: 15px !important;
    line-height: 28px !important;
  }

  .gap-sec {
    margin-left: 12px;
    margin-right: 12px;
  }

  .gap-sec a p {
    padding: 10px 0px 0px 0px;
    font-size: 14px;
    font-weight: 700;
  }

  .abut-banner-cont {
    h1 {
      font-size: 76px;
    }
  }

  .proj-sec-para p {
    font-size: 23px !important;
    line-height: 39px !important;
  }

  .text-projt p {
    font-size: 14px;
    line-height: 25px;
  }

  .career-para p {
    font-size: 19px !important;
    line-height: 38px !important;
  }

  .pre-expended-inner {
    p {
      font-size: 37px;
      line-height: 54px;
    }
  }

  .proj-sec-para {
    padding: 60px 0px 45px 0px;
  }

  .view-proj-sec i {
    position: relative;
    top: 1px;
  }

  .case-banner-below-inner h3 {
    font-size: 26px !important;
  }

  .case-stud-data p {
    font-size: 15px;
    line-height: 27px;
  }

  .roles-desc-sec ul li span {
    font-size: 15px !important;
    line-height: 25px !important;
  }

  .accordion-inner-para h3 {
    margin-top: 30px !important;
  }

  .contact-form-new {
    max-width: 590px !important;
  }

  .img-sc {
    position: relative;
    top: 4px;
  }

  .case-banner-below {
    padding: 60px 0px 50px 0px;
  }

  .project-accodion-sec .MuiPaper-root .MuiButtonBase-root .MuiAccordionSummary-content .MuiTypography-root .acordian-head .acordian-head-left p {
    font-size: 19px;
    top: 13px;
  }

  .accordion-inner-para p {
    font-size: 15px;
    line-height: 27px;
  }

  .img-sc {
    top: 7px;
  }

  .roles-desc-sec ul {
    margin-top: 25px;
  }

  .serveff-se {
    a {
      p {
        margin-bottom: 9px;
      }
    }
  }

  .margn-case {
    margin-top: 35px;
  }

  .reol-ul-sec .case-left {
    width: 25% !important;
    float: left;
    font-weight: 700;
    font-size: 17px !important;
    line-height: 30px !important;
  }

  .reol-ul-sec .case-right {
    width: 100% !important;
    font-weight: 500;
    font-size: 15px !important;
    position: relative;
    display: block;
    margin-left: 5px !important;
    // float: left !important;
    line-height: 25px !important;
  }

  .out-ul-sec li {
    font-size: 15px !important;
    margin-bottom: 12px !important;
  }

  .privacy-inner p {
    font-size: 15px !important;
    line-height: 30px !important;
  }

  .servic-dis-sec {
    padding: 61px 0px;
  }

  .case-stud-sec-inner h3 {
    font-size: 25px !important;
    // margin-bottom: 51px !important;
  }

  .case-new-mar {
    margin-bottom: 80px !important;
  }

  .accordion-inner-para h3 {
    font-size: 18px !important;
  }

  .contact-heading h2 {
    font-size: 26px !important;
  }

  .career-newbgss {
    margin-top: 0px;
  }

  .nav-link::after {
    display: none;
  }

  .main-sec-home {
    background-position: 82% 100%;
  }

  .service-head h2 {
    font-size: 28px !important;
    margin-top: 0px;
  }

  .service-inner-sec h3 {
    font-size: 15px;
    line-height: 23px;
    font-weight: 600;
  }

  .service-inner-sec .service-iiner {
    width: 50%;
    float: left;
    /* clear: inline-end; */
    margin-bottom: 60px;
  }

  .service {
    margin-bottom: 20px !important;
  }

  .pre-expended-inner p {
    font-size: 23px !important;
    line-height: 46px !important;
  }

  .project-inner-sec h3 {
    max-width: 320px;
    font-size: 25px !important;
    line-height: 41px;
  }

  .project-sec-width {
    margin-right: 3px;
    margin-bottom: 5px;
    width: 49.8%;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root .MuiButtonBase-root {
    width: 60px;
    height: 60px;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root .MuiButtonBase-root svg {
    font-size: 36px;
    right: -1px;
  }

  .project-acn-sec-main .project-acn-sec .proj-actn-left a span {
    margin-right: 3px;
  }

  .partner-inner h2 {
    font-size: 20px;
  }

  .partner-inner h3 {
    font-size: 16px !important;
    line-height: 27px !important;
  }

  .footer-sec {
    padding: 60px 0px 10px 0px;
  }

  .partner-inner h3::after {
    margin-top: 22px;
  }

  .talk-inner h2 {
    font-size: 20px;
    line-height: 41px;
  }

  .partner-inner p {
    font-size: 15px;
    line-height: 30px;
  }

  .callltoactn {
    img {
      height: 23px;
    }
  }

  .vision-img {
    height: 58px;
  }

  .vision-inner h2 {
    font-size: 23px;
  }

  .footer-width {
    width: 50%;
    margin-bottom: 15px;
  }

  .footer-sec .footer-left p {
    line-height: 25px;
    font-size: 14px;
  }

  .navbar-brand img {
    height: 23px;
  }

  .footer-sec .footer-left img {
    height: 26px;
  }

  .footer-sec .footer-center h3 {
    font-size: 18px;
  }

  .footer-sec .footer-mid-center h3 {
    font-size: 18px;
  }

  .footer-sec .footer-right h3 {
    font-size: 18px;
  }

  body .servic-visi {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    padding: 60px 0px 50px 0px !important;
  }

  .expert-sec {
    padding: 60px 20px 60px 20px;
  }

  .service-iew-sec {
    padding-left: 1px !important;
  }

  .abut-banner-cont {
    bottom: 9vh !important;

    h1 {
      font-size: 48px !important;
      line-height: 64px;
    }

    p {
      font-size: 22px !important;
    }
  }

  .servicess-divid .srvicess-right h2 {
    font-size: 26px !important;
    margin-bottom: 13px !important;
    font-weight: 700 !important;
  }

  .point-srvicess-left:after {
    left: 60px !important;
    height: 410px !important;
  }

  .srvicess-left:after {
    margin-top: -95px !important;
  }

  .servicess-divid {
    padding: 60px 15px 10px 15px !important;
  }

  .point-sec-service {
    padding: 70px 0px 0px 0px !important;
  }

  .progrme-info-sec {
    img {
      width: 100% !important;
    }
  }

  .progrme-info-sec p {
    font-size: 17px !important;
    line-height: 27px !important;
  }

  .servicess-inner-sec {
    padding: 70px 0px !important;
  }

  .progrme-info-sec h2 {
    font-size: 28px !important;
    margin-bottom: 19px !important;
  }

  .servicess-divid .srvicess-left img {
    height: 90px !important;
  }

  .servicess-inner-para p {
    font-size: 16px !important;
    line-height: 31px !important;
  }

  .expert-sec h2 {
    font-size: 25px !important;
    margin-top: 64px !important;
  }

  .expert-sec p {
    font-size: 16px !important;
    line-height: 30px !important;
  }

  .servic-inner-sec p {
    font-size: 20px !important;
    line-height: 38px !important;
  }

  .interven-sec img {
    right: 0px;
    height: 95px;
  }

  .case-heading h4 {
    font-size: 19px;
    bottom: 4px;
  }

  .watch-video-sec .watcch-inner-sec .watch-sec p {
    font-size: 32px;
    padding: 0px 30px;
  }

  .interven-sec h3 {
    font-size: 20px;
    line-height: 35px;
  }

  .nav-link {
    padding: 10px 9px !important;
    font-size: 14px;
  }

  .btn-call img {
    top: -1px;
    height: 15px;
  }

  .service-desc-inner h3 {
    margin: 15px 5px 18px 5px;
    line-height: 30px;
  }

  .service-desc-inner p {
    font-size: 15px;
    padding: 0px 5px;
    max-width: 340px !important;
  }

  .service-headss {
    margin-bottom: 50px !important;
  }

  .project-acn-sec-main .project-acn-sec .proj-actn-right {
    right: 20px;
    float: right;
  }

  .project-acn-sec-main .project-acn-sec {
    padding-left: 22px;
  }

  .project-acn-sec-main .project-acn-sec .proj-actn-right a {
    font-size: 15px;
  }

  .project-acn-sec-main .project-acn-sec .proj-actn-left a {
    font-size: 15px;
  }

  .project-acn-sec-main .project-acn-sec .proj-actn-left {
    margin-top: 0px;
  }

  .btn-sec {
    font-size: 14px;
  }

  .unique-sec {
    margin-top: 70px;
    padding-bottom: 10px;
  }

  .vision-inner p {
    font-size: 16px !important;
    line-height: 26px !important;
  }

  .project-inner-sec img {
    height: 250px;
  }

  .vision-inner h3 {
    font-size: 16px;
    line-height: 27px;
    margin-top: 12px;
  }

  .partner-inner {
    padding: 40px 20px 40px 30px;
  }

  .talk-inner {
    padding: 50px 20px 145px 40px;
  }

  .desk-watch {
    display: none !important;
  }

  .footer-sec .footer-center ul li a span {
    font-size: 14px;
    font-weight: 500;
  }

  .case-btn-sec {
    margin-top: 80px !important;
  }

  .watch-video-sec .watcch-inner-sec .watch-video-play {
    width: 100%;
  }

  .watch-video-sec .watcch-inner-sec .watch-sec {
    padding: 53px 0px;
    width: 100%;
  }

  .watch-video-sec .watcch-inner-sec .mobile-watch {
    background-color: transparent;
    padding: 13px 0px !important;
    box-shadow: none;
  }

  .footer-sec .footer-mid-center ul li a span {
    font-size: 14px;
    margin-left: 4px;
    font-weight: 500;
  }

  .footer-sec .footer-right ul li a span {
    font-size: 14px;
  }

  .mobile-watch {
    display: block !important;
  }

  .hr-sec {
    margin: 30px 0px 30px 0px;
  }

  .btn-sec {
    padding: 13px 22px 9px 22px;
  }

  .gap-sec {
    padding: 10px 5px 10px 5px;

    img {
      height: 50px;
    }
  }

  .servic-njsmart-sec a p {
    padding: 60px 2px 15px 1px;
    font-size: 14px;
  }

  .servic-njsmart-sec a img {
    padding: 0px 14px 0px 2px;
  }

  .overlay-text {
    padding: 10px 10px 30px 10px;
    font-size: 15px;
  }

  .view-proj .text-projt p .view-proj-sec {
    font-size: 13px !important;
  }

  .main-projt-watch {
    top: 155px;
  }

  .custom-play-button {
    top: 46%;
  }

  .case-btn-sec {
    margin-top: -100px;
  }

  .case-stud-sec {
    padding: 57px 0px 40px 0px;
  }

  .video-main-secs {
    height: 65px;
    width: 65px;
  }

  .custom-play-button svg {
    height: 27px;
    transform: translate(-43%, 76%);
  }

  .contact-details .contact-info {
    left: -20px;
    width: 184%;
    padding: 60px 15px 20px 17px;
    margin-top: 40px;
  }

  .interven-sec h3 {
    padding: 40px 0px 32px 0px !important;
  }

  .career-newbgss {
    padding-top: 30px;
  }

  .contact-details {
    padding: 60px 0px;
  }

  .contact-details .contact-info h2 {
    font-size: 18px;
    margin-bottom: 45px;
  }

  .contact-details .contact-info .margin-cont-top {
    font-size: 15px;
  }

  .contact-details .contact-info p {
    margin-bottom: 27px;
    font-size: 15px;
  }

  .contact-details .contact-info p i {
    top: 1px;
  }

  .contact-form {
    padding: 30px 40px 35px 40px;
  }

  .footer-sec .footer-right ul li a img {
    height: 18px;
  }

  .text-projt {
    left: 5%;
  }

  .main-projt-watch .projet-over-right a {
    font-size: 13px;
  }

  .connect-mail-new {
    font-size: 15px;
  }

  .main-projt-watch .projet-over-left a {
    font-size: 13px;
    cursor: pointer;
  }

  .navbar-collapse {
    border-top: 1px solid #b5b5b54f;
    margin-top: 10px;
  }

  .navbar-toggler:focus {
    box-shadow: none;
  }

  .mob-no {
    display: none;
  }

  .pre-expended-inner-new p {
    font-size: 25px !important;
    line-height: 41px !important;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root {
    margin: 0px;
    width: 100%;
    max-width: 470px;
    padding: 16px 25px 21px 25px;
  }

  .servic-other-sec .gap-new-sec {
    height: 21vh !important;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root .MuiDialogContent-root h2 {
    font-size: 22px;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root .MuiDialogContent-root p {
    font-size: 18px;
    margin-bottom: 0px;
    margin-top: 10px;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .button-sec {
    margin-top: 15px;
  }

  .service-headss p {
    font-size: 25px !important;
    line-height: 37px !important;
  }

  .project-sec-width:nth-child(2) {
    margin-right: 0px !important;
  }

  .vision-sec {
    margin-top: 70px !important;
    margin-bottom: 50px !important;
  }

  .service-iew-sec .serv-left-view a span {
    font-size: 14px !important;
  }

  .njsmart-bg {
    background-position: 90% 100% !important;
  }

  .workflow-bg {
    background-position: 90% 100% !important;
  }

  .effice-bg {
    background-position: 90% 100% !important;
  }

  .gap-analys-bg {
    background-position: 99% 100% !important;
  }

  .programming-head-img {
    background-position: 90% 100% !important;
  }

  .main-sec-homes .service-banner {
    bottom: 3vh !important;
  }

  .service {
    margin-bottom: 60px !important;
  }

  .service .touch-sec {
    margin-top: 20px !important;
  }

  .leader-sec a {
    font-size: 16px !important;
  }

  .book-demo-sec .btn-book-sec {
    position: relative !important;

    float: left !important;
  }

  .case-new-insd h3 {
    margin-bottom: 50px !important;
  }

  .case-new-mar {
    margin-bottom: 30px !important;
  }

  .otp-modal-new .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .otp-head {
    font-size: 17px !important;
    line-height: 28px !important;
  }

  .otp-modal-new .MuiDialogContent-root .validate-sec-mode .error-msg-sec .error-msg {
    font-size: 14px !important;
    margin-top: 5px !important;
  }

  .otp-modal-new .MuiDialog-container .MuiPaper-root {
    width: 100% !important;
    max-width: 500px !important;
  }

  .final-modal .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .new-demo-para {
    font-size: 16px !important;
    line-height: 26px !important;
  }

  .final-modal .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .new-demo-head {
    font-size: 16px !important;
  }

  .thank-you-inner {
    width: 100%;
    max-width: 560px !important;
  }

  .thankyou-sec h2 {
    font-size: 31px !important;
    margin: 14px 0px !important;
  }

  .thankyou-sec p {
    font-size: 16px !important;
  }
  .thank-amaze{
    max-width: 459px !important;
  }
  .vasestremco {
    font-size: 12px !important;
    line-height: 47px   !important;
    color: #0078ae  !important;
    margin-top: 20px  !important;
  }

  .thankyou-sec {
    padding: 150px 0px 80px 0px !important;
  }

  .thank-you-inner svg {
    width: 110px;
    height: 110px;
  }

  .bok-iner-head h2 {
    font-size: 23px !important;
  }

  .prev-sec-service:after {
    right: -21px !important;
    height: 29px !important;
    margin-top: -27px !important;
  }

  .line-after-case:after {
    height: 32px !important;
    margin-top: -28px !important;
  }

  .service-sec {
    background-size: initial !important;
    background-repeat: repeat-y !important;
  }

    .nav-new-menu a.active:before{
      display: none;
    }
    body .nav-new {
      padding: 10px 9px !important;
  }
  .nav-new{
    margin-left: 0px !important;
  }
  .nav-link:hover:before{
    display: none !important;
  }

}


@media only screen and (min-width: 912px) and (max-width: 980px) {
  body .nav-new {
    padding: 10px 9px !important;
}
  .service-sec {
    background-size: initial !important;
    background-repeat: repeat-y !important;
  }

  .prev-sec-service:after {
    right: -21px !important;
    height: 29px !important;
    margin-top: -27px !important;
  }

  .leader-sec a {
    font-size: 16px !important;
  }

  .service {
    margin-bottom: 60px !important;
  }

  .main-sec-homes .service-banner {
    bottom: 3vh !important;
  }

  .progrme-info-sec p {
    font-size: 16px !important;
    line-height: 27px !important;
  }

  .progrme-info-sec h2 {
    font-size: 28px !important;
    margin-bottom: 18px !important;
  }

  .progrme-info-sec {
    padding: 50px 0px 40px 0px !important;
  }

  .progrme-info-sec img {
    width: 100% !important
  }

  .serv-inner-desk {
    display: block;
  }

  .serv-inner-mob {
    display: none;
  }

  .service-desc-inner {
    margin-bottom: 16px;
    min-height: calc(70vh - 200px) !important;
  }

  .talk-inner {
    padding: 50px 20px 122px 40px;
  }

  .main-sec-homes {
    height: 43vh;
  }

  .servic-other-sec .gap-new-sec {
    height: 20vh !important;
  }

  .servics-sec .servic-njsmart-sec-new {
    height: 42vh !important;
  }

  .njsmart-bg {
    background-position: 90% 100% !important;
  }

  .workflow-bg {
    background-position: 90% 100% !important;
  }

  .effice-bg {
    background-position: 90% 100% !important;
  }

  .gap-analys-bg {
    background-position: 99% 100% !important;
  }

  .programming-head-img {
    background-position: 90% 100% !important;
  }

  .otp-modal-new .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .otp-head {
    font-size: 17px !important;
    line-height: 28px !important;
  }

  .otp-modal-new .MuiDialogContent-root .validate-sec-mode .error-msg-sec .error-msg {
    font-size: 14px !important;
    margin-top: 5px !important;
  }

  .final-modal .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .new-demo-para {
    font-size: 16px !important;
  }

  .final-modal .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .new-demo-head {
    font-size: 16px !important;
  }

  .thank-you-inner {
    width: 100%;
    max-width: 600px !important;
  }

  .thankyou-sec h2 {
    font-size: 33px !important;
  }

  .thankyou-sec p {
    font-size: 17px !important;
  }

  .vasestremco {
    font-size: 12px !important;
    line-height: 47px   !important;
    color: #0078ae  !important;
    margin-top: 20px  !important;
  }

  .thankyou-sec {
    padding: 160px 0px 90px 0px !important;
  }

  .bok-iner-head h2 {
    font-size: 24px !important;
  }

  .line-after-case:after {
    height: 32px !important;
    margin-top: -28px !important;
  }

}


@media only screen and (min-width: 992px) and (max-width: 1023px) {
  .bok-iner-head h2 {
    font-size: 24px !important;
  }

  .main-sec-homes .service-banner {
    bottom: 6vh !important;
  }

  .progrme-info-sec p {
    font-size: 16px !important;
    line-height: 27px !important;
  }

  .progrme-info-sec h2 {
    font-size: 28px !important;
    margin-bottom: 18px !important;
  }

  .progrme-info-sec {
    padding: 50px 0px 40px 0px !important;
  }

  .progrme-info-sec img {
    width: 100% !important
  }

  .serv-inner-desk {
    display: block;
  }

  .serv-inner-mob {
    display: none;
  }

  .nav-item {
    .nav-link {
      .fa-house {
        position: relative;
        top: 2px;
      }
    }
  }

  .project-acn-sec-main .project-acn-sec {
    padding-left: 19px;
    bottom: 20px;
  }

  .project-acn-sec .proj-actn-left {
    margin-bottom: 3px;
  }

  .btn-call {
    font-size: 13px;
    padding: 9px 13px 7px 17px;
  }

  .watch-video-sec .watcch-inner-sec .watch-video-play .watch-vid-play-inner {
    box-shadow: none;
  }

  .watch-video-sec .watcch-inner-sec .watch-sec {
    padding: 53px 0px;
  }

  .watch-video-sec {
    padding: 60px 0px 60px 0px;
  }

  .watch-video-sec .watcch-inner-sec .watch-video-play .watch-vid-play-inner .media-sec {}

  .main-sec-homes {
    height: 55vh;
  }

  .navbar {
    padding-top: 12px;
    padding-bottom: 11px;
  }

  .banner-content {
    bottom: 9vh !important;
    left: 5% !important;
  }

  .pre-expended-sec {
    height: 43vh !important;
  }

  .pre-expended-inner {
    bottom: 85px !important;
  }

  body .about-main {
    height: 40vh !important;
  }

  .service-inner-sec img {
    height: 55px;
  }

  .banner-content {
    h1 {
      font-size: 64px !important;
      margin-bottom: 3px;
    }

    h2 {
      font-size: 64px !important;
    }

    p {
      font-size: 24px !important;
      line-height: 39px !important;
    }
  }

  .expert-sec h2 {
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 67px;
    margin-top: 74px;
  }

  .expert-sec p {
    font-size: 25px;
    line-height: 43px;
    margin-top: 30px;
  }

  .servic-dis-sec h2 {
    font-size: 33px !important;
    line-height: 53px !important;
    padding-top: 84px !important;
  }

  .gap-sec {
    margin-left: 16px;
    margin-right: 16px;
  }

  .gap-sec a p {
    padding: 10px 0px 0px 0px;
    font-size: 14px;
    font-weight: 700;
  }

  // .eff-sec{
  //     a{
  //         p{
  //             margin-bottom: 23px;
  //         }
  //     }
  // }
  .abut-banner-cont {
    h1 {
      font-size: 76px;
    }
  }

  .proj-sec-para p {
    font-size: 24px !important;
    line-height: 42px !important;
  }

  .text-projt p {
    font-size: 14px;
    line-height: 25px;
  }

  .view-proj .text-projt p .view-proj-sec {
    font-size: 14px !important;
  }

  .career-para p {
    font-size: 24px !important;
    line-height: 47px !important;
  }

  .pre-expended-inner {
    p {
      font-size: 37px;
      line-height: 54px;
    }
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root .MuiDialogContent-root p {
    font-size: 18px;
    margin-top: 10px;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .button-sec {
    margin-top: 20px;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root .MuiDialogContent-root h2 {
    font-size: 24px;
  }

  .proj-sec-para {
    padding: 60px 0px 45px 0px;
  }

  .case-banner-below-inner h3 {
    font-size: 26px !important;
  }

  .case-stud-data p {
    font-size: 16px;
    line-height: 30px;
  }

  .interven-sec img {
    height: 105px !important;
  }

  .roles-desc-sec ul li span {
    font-size: 16px;
  }

  .case-banner-below {
    padding: 60px 0px 50px 0px;
  }

  .project-accodion-sec .MuiPaper-root .MuiButtonBase-root .MuiAccordionSummary-content .MuiTypography-root .acordian-head .acordian-head-left p {
    font-size: 21px;
    top: 12px;
  }

  .serveff-se {
    a {
      p {
        margin-bottom: 9px;
      }
    }
  }

  .margn-case {
    margin-top: 35px;
  }

  .reol-ul-sec .case-right {
    font-size: 15px !important;
  }

  .servic-dis-sec {
    padding: 100px 0px;
  }

  .case-stud-sec-inner h3 {
    font-size: 27px !important;
    // margin-bottom: 69px !important;
  }

  .case-new-mar {
    margin-bottom: 100px !important;
  }

  .accordion-inner-para h3 {
    font-size: 18px !important;
  }

  .contact-heading h2 {
    font-size: 28px !important;
  }

  .career-newbgss {
    margin-top: 0px;
  }

  .main-sec-home {
    background-position: 87% 100%;
  }

  .service-head h2 {
    font-size: 30px !important;
    margin-top: 0px;
  }

  .service-inner-sec h3 {
    font-size: 15px;
    line-height: 23px;
  }

  .service-inner-sec .service-iiner {
    width: 20%;
  }

  .pre-expended-inner p {
    font-size: 25px !important;
    line-height: 51px !important;
  }

  .project-inner-sec h3 {
    max-width: 228px !important;
    font-size: 20px !important;
    line-height: 35px;
  }

  .project-sec-width {
    margin-right: 3px;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root .MuiButtonBase-root {
    width: 60px;
    height: 60px;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root .MuiButtonBase-root svg {
    font-size: 36px;
    right: -1px;
  }

  .project-acn-sec-main .project-acn-sec .proj-actn-left a span {
    margin-right: 3px;
  }

  .partner-inner h2 {
    font-size: 24px;
  }

  .partner-inner h3 {
    font-size: 18px;
  }

  .footer-sec {
    padding: 60px 0px 10px 0px;
  }

  .partner-inner h3::after {
    margin-top: 22px;
  }

  .talk-inner h2 {
    font-size: 25px;
    line-height: 47px;
  }

  .partner-inner p {
    font-size: 15px;
    line-height: 30px;
  }

  .callltoactn {
    img {
      height: 23px;
    }
  }

  .vision-img {
    height: 65px;
  }

  .vision-inner h2 {
    font-size: 25px;
  }

  .footer-sec .footer-left p {
    line-height: 25px;
    font-size: 14px;
  }

  .navbar-brand img {
    height: 23px;
  }

  .footer-sec .footer-left img {
    height: 26px;
  }

  .footer-sec .footer-center h3 {
    font-size: 18px;
  }

  .footer-sec .footer-mid-center h3 {
    font-size: 18px;
  }

  .footer-sec .footer-right h3 {
    font-size: 18px;
  }

  .abut-banner-cont {
    bottom: 12vh !important;

    h1 {
      font-size: 50px !important;
    }

    p {
      font-size: 22px !important;
    }
  }

  .expert-sec h2 {
    font-size: 27px !important;
  }

  .expert-sec p {
    font-size: 19px !important;
    line-height: 36px !important;
  }

  .servic-inner-sec p {
    font-size: 19px !important;
    line-height: 35px !important;
  }

  .interven-sec img {
    right: 0px;
  }

  .case-heading h4 {
    font-size: 19px;
    bottom: 4px;
  }

  .watch-video-sec .watcch-inner-sec .watch-sec p {
    font-size: 38px;
    padding: 26px 30px;
  }

  .interven-sec h3 {
    font-size: 22px;
  }

  .nav-link {
    padding: 10px 11px !important;
    font-size: 14px;
  }

  .btn-call img {
    top: -1px;
    height: 15px;
  }

  .service-desc-inner h3 {
    margin: 15px 5px 18px 5px;
    line-height: 28px;
    font-size: 17px;
  }

  .service {
    margin-bottom: 50px;
  }

  .service-desc-inner p {
    font-size: 15px;
    padding: 0px 5px;
  }

  .project-acn-sec-main .project-acn-sec .proj-actn-right {
    right: 0px;
    float: left;
  }

  .project-acn-sec-main .project-acn-sec {
    padding-left: 22px;
  }

  .project-acn-sec-main .project-acn-sec .proj-actn-right a {
    font-size: 14px;
  }

  .project-acn-sec-main .project-acn-sec .proj-actn-left a {
    font-size: 14px;
  }

  .project-acn-sec-main .project-acn-sec .proj-actn-left {
    margin-top: 15px;
  }

  .btn-sec {
    font-size: 14px;
  }

  .unique-sec {
    margin-top: 70px;
    padding-bottom: 30px;
  }

  .project-inner-sec img {
    height: 230px;
  }

  .vision-inner h3 {
    font-size: 16px;
    line-height: 27px;
    margin-top: 12px;
  }

  .vision-sec {
    margin-top: 80px;
    margin-bottom: 0px;
  }

  .partner-inner {
    padding: 70px 40px 75px 60px;
  }

  .talk-inner {
    padding: 70px 40px 145px 60px;
  }

  .footer-sec .footer-center ul li a span {
    font-size: 14px;
    font-weight: 500;
  }

  .footer-sec .footer-mid-center ul li a span {
    font-size: 14px;
    margin-left: 4px;
    font-weight: 500;
  }

  .footer-sec .footer-right ul li a span {
    font-size: 14px;
  }

  .hr-sec {
    margin: 50px 0px 40px 0px;
  }

  .btn-sec {
    padding: 13px 22px 9px 22px;
  }

  .gap-sec {
    padding: 10px 6px 10px 6px;

    img {
      height: 50px;
    }
  }

  .servic-njsmart-sec a p {
    padding: 60px 2px 15px 1px;
  }

  .servic-njsmart-sec a img {
    padding: 0px 14px 0px 2px;
  }

  .overlay-text {
    padding: 10px 10px 20px 10px;
    font-size: 15px;
  }

  .main-projt-watch {
    top: 135px;
  }

  .custom-play-button {
    top: 40%;
  }

  .case-btn-sec {
    margin-top: -20px;
  }

  .case-stud-sec {
    padding: 80px 0px 60px 0px;
  }

  .video-main-secs {
    height: 85px;
    width: 85px;
  }

  .custom-play-button svg {
    height: 35px;
  }

  .contact-details .contact-info {
    left: 0px;
    width: 143%;
    padding: 60px 25px 20px 23px;
  }

  .career-newbgss {
    padding-top: 30px;
  }

  .contact-form {
    padding: 50px 60px;
  }

  .footer-sec .footer-right ul li a img {
    height: 18px;
  }

  .text-projt {
    left: 5%;
  }

  .main-projt-watch .projet-over-right a {
    font-size: 13px;
  }

  .main-projt-watch .projet-over-left a {
    font-size: 13px;
    cursor: pointer;
  }

  .pre-expended-inner-new p {
    font-size: 30px !important;
    line-height: 49px !important;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root {
    margin: 0px;
    width: 100%;
    max-width: 520px;
    padding: 26px 25px 21px 25px;
  }

  .service-headss p {
    font-size: 29px !important;
    line-height: 40px !important;
  }

  .service-headss {
    margin-bottom: 50px !important;
  }

  .service-desc-inner {
    min-height: calc(75vh - 200px) !important;
  }

  .unique-sec {
    margin-bottom: 0px !important;
    margin-top: 40px !important;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .servic-visi {
    margin-top: 0px !important;
  }

  .service-iew-sec {
    padding-left: 1px !important;
  }

  .footer-mid-center {
    ul {
      li {
        a {
          i {
            font-size: 14px !important;
          }
        }
      }
    }
  }

  .servic-other-sec .gap-new-sec {
    height: 23vh !important;
  }

  .footer-sec .footer-mid-center ul li a span {
    margin-left: 2px !important;
  }

  .servics-sec .servic-njsmart-sec-new {
    height: 47.9vh !important;
  }

  .service-iew-sec .serv-left-view a span {
    font-size: 14px !important;
  }

  .servicess-inner-sec {
    padding: 60px 0px !important;
  }

  .servicess-divid {
    padding: 60px 15px 10px 16px !important;
  }

  .point-sec-service {
    padding: 70px 0px 20px 0px !important;
  }

  .servicess-inner-para p {
    font-size: 17px !important;
    line-height: 32px !important;
  }

  .progrme-info-sec p {
    font-size: 16px !important;
    line-height: 29px !important;
  }

  .progrme-info-sec img {
    width: 100% !important;
  }

  .progrme-info-sec h2 {
    font-size: 30px !important;
    margin-bottom: 23px !important;
  }

  .servicess-divid {
    padding: 70px 0px 0px 0px;
  }

  .servicess-divid .srvicess-right h2 {
    font-size: 27px !important;
  }

  .servicess-divid .srvicess-left img {
    height: 100px !important;
  }

  .srvicess-right ul li {
    font-size: 17px !important;
  }

  .servicess-divid .srvicess-right h2 {
    margin-bottom: 15px !important;
  }

  .point-srvicess-left:after {
    height: 370px !important;
  }

  .small-serv-left-view {
    padding-left: 2px !important;
  }

  .reol-ul-sec .case-right {
    width: 80%;
    font-size: 16px !important;
  }

  .reol-ul-sec .case-left {
    width: 20%;
    font-size: 16px !important;
  }

  .out-ul-sec li {
    font-size: 16px !important;
  }

  .contact-form-new {
    width: 100%;
    max-width: 750px !important;
  }

  .njsmart-bg {
    background-position: 90% 100% !important;
  }

  .workflow-bg {
    background-position: 90% 100% !important;
  }

  .effice-bg {
    background-position: 90% 100% !important;
  }

  .gap-analys-bg {
    background-position: 99% 100% !important;
  }

  .programming-head-img {
    background-position: 90% 100% !important;
  }

  .service {
    margin-bottom: 60px !important;
  }

  .otp-modal-new .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .otp-head {
    font-size: 17px !important;
  }

  .more-btn {
    padding: 15px 18px 13px 18px !important;
    font-size: 16px !important;
  }

  .final-modal .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .new-demo-para {
    font-size: 16px !important;
  }

  .final-modal .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .new-demo-head {
    font-size: 16px !important;
  }

  .thank-you-inner {
    width: 100%;
    max-width: 600px !important;
  }

  .thankyou-sec h2 {
    font-size: 33px !important;
  }

  .thankyou-sec p {
    font-size: 17px !important;
  }

  .vasestremco {
    font-size: 12px !important;
    line-height: 47px   !important;
    color: #0078ae  !important;
    margin-top: 20px  !important;
  }

  .prev-sec-service:after {
    right: -20px !important;
    height: 29px !important;
    margin-top: -27px !important;
  }

  .line-after-case:after {
    height: 32px !important;
    margin-top: -28px !important;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1199px) {
  .line-after-case:after {
    height: 32px !important;
    margin-top: -28px !important;
  }

  .prev-sec-service:after {
    right: -21px !important;
    height: 32px !important;
  }

  .thank-you-inner {
    width: 100%;
    max-width: 600px !important;
  }

  .thankyou-sec h2 {
    font-size: 33px !important;
  }

  .thankyou-sec p {
    font-size: 17px !important;
  }

  .vasestremco {
    font-size: 12px !important;
    line-height: 47px   !important;
    color: #0078ae  !important;
    margin-top: 20px  !important;
  }

  .more-btn {
    padding: 15px 18px 13px 18px !important;
    font-size: 16px !important;
  }

  .service {
    margin-bottom: 60px !important;
  }

  .main-sec-homes .service-banner {
    bottom: 6vh !important;
  }

  .progrme-info-sec p {
    font-size: 16px !important;
    line-height: 27px !important;
  }

  .progrme-info-sec h2 {
    font-size: 28px !important;
    margin-bottom: 18px !important;
  }

  .progrme-info-sec {
    padding: 50px 0px 40px 0px !important;
  }

  .progrme-info-sec img {
    width: 100% !important
  }

  .serv-inner-desk {
    display: block;
  }

  .serv-inner-mob {
    display: none;
  }

  .njsmart-bg {
    background-position: 90% 100% !important;
  }

  .workflow-bg {
    background-position: 90% 100% !important;
  }

  .effice-bg {
    background-position: 90% 100% !important;
  }

  .gap-analys-bg {
    background-position: 99% 100% !important;
  }

  .programming-head-img {
    background-position: 90% 100% !important;
  }

  .nav-item {
    .nav-link {
      .fa-house {
        position: relative;
        top: 2px;
      }
    }
  }

  .project-acn-sec-main .project-acn-sec {
    padding-left: 19px;
  }

  .btn-call {
    font-size: 13px;
    padding: 9px 13px 7px 17px;
  }

  .watch-video-sec .watcch-inner-sec .watch-video-play .watch-vid-play-inner {
    box-shadow: none;
  }

  .watch-video-sec .watcch-inner-sec .watch-sec {
    padding: 53px 0px;
  }

  .watch-video-sec {
    padding: 60px 0px 60px 0px;
  }

  .watch-video-sec .watcch-inner-sec .watch-video-play .watch-vid-play-inner .media-sec {}

  .main-sec-homes {
    height: 58vh;
  }

  .service-headss p {
    font-size: 30px !important;
    line-height: 42px !important;
  }

  .navbar {
    padding-top: 12px;
    padding-bottom: 11px;
  }

  .banner-content {
    bottom: 9vh !important;
    left: 8% !important;
  }

  .pre-expended-sec {
    height: 43vh !important;
  }

  .pre-expended-inner {
    bottom: 85px !important;
  }

  body .about-main {
    height: 35vh !important;
  }

  .service-inner-sec img {
    height: 55px;
  }

  .srvicess-left:after {
    margin-top: -100px !important;
  }

  .banner-content {
    h1 {
      font-size: 65px !important;
      margin-bottom: 5px;
    }

    h2 {
      font-size: 65px !important;
    }

    p {
      font-size: 24px !important;
      line-height: 39px !important;
    }
  }

  .expert-sec h2 {
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 67px;
    margin-top: 74px;
  }

  .expert-sec p {
    font-size: 25px;
    line-height: 43px;
    margin-top: 30px;
  }

  .servic-dis-sec h2 {
    font-size: 37px !important;
    line-height: 59px !important;
    padding-top: 95px !important;
  }

  .gap-sec {
    margin-left: 16px;
    margin-right: 16px;
  }

  .gap-sec a p {
    padding: 10px 0px 0px 0px;
    font-size: 14px;
    font-weight: 700;
  }

  // .eff-sec{
  //     a{
  //         p{
  //             margin-bottom: 23px;
  //         }
  //     }
  // }
  .abut-banner-cont {
    h1 {
      font-size: 76px;
    }
  }

  .proj-sec-para p {
    font-size: 26px !important;
    line-height: 44px !important;
  }

  .text-projt p {
    font-size: 14px;
    line-height: 24px;
  }

  .career-para p {
    font-size: 24px !important;
    line-height: 45px !important;
  }

  .pre-expended-inner {
    p {
      font-size: 37px;
      line-height: 54px;
    }
  }

  .proj-sec-para {
    padding: 60px 0px 65px 0px;
  }

  .case-banner-below-inner h3 {
    font-size: 27px !important;
  }

  .case-stud-data p {
    font-size: 16px;
    line-height: 30px;
  }

  .roles-desc-sec ul li span {
    font-size: 16px;
  }

  .case-banner-below {
    padding: 60px 0px 50px 0px;
  }

  .project-accodion-sec .MuiPaper-root .MuiButtonBase-root .MuiAccordionSummary-content .MuiTypography-root .acordian-head .acordian-head-left p {
    font-size: 20px;
    top: 12px;
  }

  .serveff-se {
    a {
      p {
        margin-bottom: 9px;
      }
    }
  }

  .margn-case {
    margin-top: 50px;
  }

  .servic-dis-sec {
    padding: 100px 0px;
  }

  .case-stud-sec-inner h3 {
    font-size: 27px !important;
    // margin-bottom: 59px !important;
  }

  .accordion-inner-para h3 {
    font-size: 19px !important;
  }

  .contact-heading h2 {
    font-size: 29px !important;
  }

  .career-newbgss {
    margin-top: 0px;
  }

  .main-sec-home {
    background-position: 70% 100%;
  }

  .service-head h2 {
    font-size: 31px !important;
    margin-top: 0px;
  }

  .service-inner-sec h3 {
    font-size: 15px;
    line-height: 23px;
  }

  .service-inner-sec .service-iiner {
    width: 20%;
  }

  .pre-expended-inner p {
    font-size: 26px !important;
    line-height: 51px !important;
  }

  .project-inner-sec h3 {
    max-width: 228px !important;
    font-size: 20px !important;
    line-height: 35px;
  }

  .project-sec-width {
    margin-right: 4px;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root .MuiButtonBase-root {
    width: 60px;
    height: 60px;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root .MuiButtonBase-root svg {
    font-size: 36px;
    right: -1px;
  }

  .project-acn-sec-main .project-acn-sec .proj-actn-left a span {
    margin-right: 3px;
  }

  .partner-inner h2 {
    font-size: 25px;
  }

  .partner-inner h3 {
    font-size: 18px;
  }

  .partner-inner h3::after {
    margin-top: 22px;
  }

  .talk-inner h2 {
    font-size: 25px;
    line-height: 47px;
  }

  .partner-inner p {
    font-size: 15px;
    line-height: 30px;
  }

  .callltoactn {
    img {
      height: 23px;
    }
  }

  .vision-img {
    height: 65px;
  }

  .vision-inner h2 {
    font-size: 25px;
  }

  .footer-sec .footer-left p {
    line-height: 25px;
    font-size: 14px;
  }

  .navbar-brand img {
    height: 23px;
  }

  .footer-sec .footer-left img {
    height: 26px;
  }

  .footer-sec .footer-center h3 {
    font-size: 18px;
  }

  .footer-sec .footer-mid-center h3 {
    font-size: 18px;
  }

  .footer-sec .footer-right h3 {
    font-size: 18px;
  }

  .abut-banner-cont {
    bottom: 10vh !important;

    h1 {
      font-size: 52px !important;
    }

    p {
      font-size: 22px !important;
    }
  }

  .reol-ul-sec .case-right {
    font-size: 16px !important;
  }

  .reol-ul-sec .case-left {
    font-size: 17px !important;
  }

  .servicess-inner-sec {
    padding: 70px 0px !important;
  }

  .expert-sec h2 {
    font-size: 27px !important;
  }

  .expert-sec p {
    font-size: 19px !important;
    line-height: 35px !important;
  }

  .servic-inner-sec p {
    font-size: 20px !important;
    line-height: 35px !important;
  }

  .interven-sec img {
    right: 0px;
    height: 105px;
  }

  .case-heading h4 {
    font-size: 18px;
    bottom: 5px;
  }

  .watch-video-sec .watcch-inner-sec .watch-sec p {
    font-size: 38px;
    padding: 26px 30px;
  }

  .interven-sec h3 {
    font-size: 22px;
  }

  .nav-link {
    padding: 10px 12px !important;
    font-size: 14px;
  }

  .btn-call img {
    top: -1px;
    height: 15px;
  }

  .service-desc-inner h3 {
    margin: 15px 3px 25px 3px;
    line-height: 29px;
    font-size: 18px;
  }

  .service-desc-inner {
    min-height: calc(75vh - 200px) !important;
  }

  .service-desc-inner p {
    font-size: 15px;
    padding: 0px 5px;
    line-height: 27px;
  }

  .project-acn-sec-main .project-acn-sec .proj-actn-right {
    right: 0px;
    float: left;
  }

  .project-acn-sec-main .project-acn-sec {
    padding-left: 22px;
    bottom: 20px;
  }

  .project-acn-sec .proj-actn-left {
    margin-bottom: 4px;
  }

  .project-acn-sec-main .project-acn-sec .proj-actn-right a {
    font-size: 14px;
  }

  .project-acn-sec-main .project-acn-sec .proj-actn-left a {
    font-size: 14px;
  }

  .project-acn-sec-main .project-acn-sec .proj-actn-left {
    margin-top: 15px;
  }

  .btn-sec {
    font-size: 14px;
  }

  .unique-sec {
    margin-top: 70px;
    padding-bottom: 20px;
    margin-bottom: 0px !important;
  }

  .vision-sec {
    margin-bottom: 70px;
  }

  .footer-mid-center {
    ul {
      li {
        a {
          i {
            font-size: 14px;
            margin-right: 0px;
          }
        }
      }
    }
  }

  .project-inner-sec img {
    height: 250px;
  }

  .vision-inner h3 {
    font-size: 16px;
    line-height: 27px;
    margin-top: 12px;
  }

  .partner-inner {
    padding: 70px 40px 75px 60px;
  }

  .talk-inner {
    padding: 70px 40px 145px 60px;
  }

  .footer-sec .footer-center ul li a span {
    font-size: 14px;
    font-weight: 500;
  }

  .footer-sec .footer-mid-center ul li a span {
    font-size: 14px;
    margin-left: 2px;
    font-weight: 500;
  }

  .footer-sec .footer-right ul li a span {
    font-size: 14px;
  }

  .hr-sec {
    margin: 50px 0px 40px 0px;
  }

  .btn-sec {
    padding: 13px 22px 9px 22px;
  }

  .gap-sec {
    padding: 10px 6px 10px 6px;

    img {
      height: 50px;
    }
  }

  .servic-njsmart-sec a p {
    padding: 60px 2px 15px 1px;
  }

  .servic-njsmart-sec a img {
    padding: 0px 14px 0px 2px;
  }

  .overlay-text {
    padding: 10px 10px 25px 10px;
    font-size: 16px;
  }

  .main-projt-watch {
    top: 135px;
  }

  .custom-play-button {
    top: 35%;
  }

  .case-btn-sec {
    margin-top: -20px;
  }

  .case-stud-sec {
    padding: 80px 0px 60px 0px;
  }

  .video-main-secs {
    height: 75px;
    width: 75px;
  }

  .custom-play-button svg {
    height: 27px;
    transform: translate(-45%, 93%);
  }

  .contact-details .contact-info {
    left: 0px;
    width: 143%;
    padding: 60px 25px 20px 23px;
  }

  .career-newbgss {
    padding-top: 30px;
  }

  .contact-form {
    padding: 50px 50px;
  }

  .footer-sec .footer-right ul li a img {
    height: 18px;
  }

  .text-projt {
    left: 5%;
  }

  .main-projt-watch .projet-over-right a {
    font-size: 13px;
  }

  .main-projt-watch .projet-over-left a {
    font-size: 13px;
    cursor: pointer;
  }

  .pre-expended-inner-new p {
    font-size: 30px !important;
    line-height: 46px !important;
  }

  .modal-yes .MuiDialog-container .MuiPaper-root {
    margin: 0px;
    width: 100%;
    max-width: 470px;
    padding: 20px 30px 15px 30px;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root .MuiDialogContent-root h2 {
    font-size: 25px;
    font-weight: 700;
    line-height: 31px;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root .MuiDialogContent-root p {
    font-size: 17px;
    line-height: 27px;
    margin-bottom: 9px;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root {
    margin: 0px;
    width: 100%;
    max-width: 520px;
    padding: 26px 25px 21px 25px;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .button-sec {
    margin-top: 20px;
  }

  .servic-visi {
    padding: 50px 0px 45px 0px;
    margin-bottom: 0px !important;
  }

  .service-iew-sec {
    padding-left: 1px !important;
  }

  .servics-sec .servic-njsmart-sec-new {
    height: 56vh !important;
  }

  .point-sec-service {
    padding: 100px 0px 0px 0px !important;
  }

  .servicess-inner-para p {
    font-size: 17px !important;
    line-height: 32px !important;
  }

  .servicess-divid {
    padding: 70px 0px 0px 0px;
  }

  .servicess-divid .srvicess-right h2 {
    font-size: 27px !important;
  }

  .servicess-divid .srvicess-left img {
    height: 100px !important;
  }

  .srvicess-right ul li {
    font-size: 17px !important;
  }

  .servicess-divid .srvicess-right h2 {
    margin-bottom: 15px !important;
  }

  .point-srvicess-left:after {
    height: 370px !important;
  }

  .small-serv-left-view {
    padding-left: 2px !important;
  }

  .progrme-info-sec h2 {
    font-size: 33px !important;
    margin-bottom: 25px !important;
  }

  .progrme-info-sec {
    img {
      width: 100%;
    }
  }

  .progrme-info-sec p {
    font-size: 17px !important;
    text-align: center !important;
    margin-bottom: 20px !important;
    line-height: 29px;
  }

  .view-proj .text-projt p .view-proj-sec {
    font-size: 14px !important;
  }

  .out-ul-sec li {
    font-size: 16px !important;
  }

  .reol-ul-sec li {
    margin-bottom: 9px;
  }

  .img-sc {
    top: 7px;
  }

  .roles-desc-sec ul {
    margin-top: 30px !important;
  }

  .career-sec {
    padding: 0px 0px 60px 0px;
  }

  .contact-details {
    padding: 70px 0px !important;
  }

  .rc-anchor-light {
    width: 100% !important;
  }

  .privacy-inner p {
    font-size: 16px !important;
    line-height: 31px !important;
    margin-bottom: 11px !important;
  }

  .privacy-main-sec {
    padding: 35px 0px !important;
  }

  .privacy-inner .privcy-head-sec .after-head {
    font-size: 23px !important;
  }

  .privacy-inner .privcy-head-sec h3 {
    font-size: 21px !important;
  }

  .cookies-next {
    font-size: 21px !important;
    margin-top: 24px !important;
    margin-bottom: 13px !important;
  }

  .privacy-inner .personal-data-align-sec .data-align-right {
    width: 75% !important;
  }

  .privacy-inner .personal-data-align-sec .data-align-left {
    width: 21% !important;
    margin-right: 6px !important;
  }

  .privacy-inner ul li {
    margin-bottom: 5px !important;
    font-size: 16px !important;
  }

  .privacy-inner .privcy-head-sec h2 {
    font-size: 21px !important;
    margin-bottom: 32px !important;
  }

  .privacy-inner .privcy-head-sec .after-head:after {
    margin-top: 4px;
  }

  .privacy-inner .privcy-head-sec {
    margin-top: 20px !important;
  }

  .case-new-mar {
    margin-bottom: 100px !important;
  }

  .otp-modal-new .otp-head {
    font-size: 17px !important;
  }

  .final-modal .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .new-demo-para {
    font-size: 16px !important;
  }

  .final-modal .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .new-demo-head {
    font-size: 16px !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1299px) {
  .line-after-case:after {
    height: 31px;
    margin-top: -29px;
    right: -19px;
  }

  .prev-sec-service:after {
    margin-top: -27px;
    height: 32px;
    right: -21px !important;

  }

  .final-modal .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .new-demo-para {
    font-size: 16px !important;
  }

  .final-modal .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .new-demo-head {
    font-size: 16px !important;
  }

  .case-new-mar {
    margin-bottom: 100px !important;
  }

  .touch-sec {
    margin-top: 30px !important;
  }

  .privacy-inner p {
    font-size: 16px !important;
    line-height: 32px !important;
    font-weight: 400;
    margin-bottom: 12px !important;
  }

  .reol-ul-sec {
    margin-top: 25px;
    margin-bottom: 0px;

    .case-left {
      width: 20%;
      float: left;
      font-weight: 700;
      font-size: 17px !important;
      line-height: 30px;
    }

    .case-right {
      width: 80%;
      // float: left;
      font-weight: 500;
      font-size: 17px !important;
      position: relative;
      display: block;
    }
  }

  .servicess-inner-para p {
    font-size: 17px !important;
    line-height: 32px !important;
  }

  .point-sec-service {
    padding: 120px 0px 60px 0px !important;
  }

  .servicess-divid .srvicess-right h2 {
    font-size: 28px !important;
  }

  .progrme-info-sec h2 {
    font-size: 32px !important;
  }

  .modal-yes .MuiDialog-container .MuiPaper-root {
    padding: 13px 33px 25px 40px;
    max-width: 460px;
  }

  .invalid-feedback .error-msg {
    font-size: 13px;
  }

  .happy-modal .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .MuiTypography-root .form-sec-main .form-sec {
    margin-bottom: 20px !important;
  }

  body .modal-casestudy .MuiDialogContent-root .validate-sec-mode .success-msg-sec .succ-msg {
    font-size: 14px !important;
  }

  body .modal-casestudy .MuiDialogContent-root .validate-sec-mode .error-msg-sec .error-msg {
    font-size: 14px !important;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root .MuiDialogContent-root h2 {
    font-size: 25px;
  }

  .modal-yes .MuiDialog-container .MuiPaper-root .MuiDialogContent-root p {
    font-size: 18px;
    margin-bottom: 8px;
  }

  .watch-video-sec .watcch-inner-sec .watch-video-play .watch-vid-play-inner {
    box-shadow: none;
  }

  .watch-video-sec .watcch-inner-sec .watch-sec {
    padding: 71px 0px;
  }

  .main-sec-homes {
    height: 75vh;
  }

  .navbar {
    padding-top: 12px;
    padding-bottom: 11px;
  }

  .banner-content {
    bottom: 12vh !important;
    left: 4% !important;
  }

  .pre-expended-sec {
    height: 33vh;
  }

  .pre-expended-inner {
    bottom: 13px !important;
  }

  .about-main {
    height: 46vh !important;
  }

  .banner-content {
    h1 {
      font-size: 73px !important;
      margin-bottom: 5px;
    }

    h2 {
      font-size: 73px !important;
    }

    p {
      font-size: 27px !important;
    }
  }

  .expert-sec h2 {
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 67px;
    margin-top: 74px;
  }

  .expert-sec p {
    font-size: 25px;
    line-height: 43px;
    margin-top: 30px;
  }

  .servic-dis-sec h2 {
    font-size: 39px !important;
    line-height: 59px !important;
    padding-top: 55px !important;
  }

  // .eff-sec{
  //     a{
  //         p{
  //             margin-bottom: 23px;
  //         }
  //     }
  // }
  .abut-banner-cont {
    h1 {
      font-size: 76px;
    }
  }

  .proj-sec-para p {
    font-size: 29px !important;
    line-height: 49px !important;
  }

  .career-para p {
    font-size: 27px !important;
    line-height: 54px !important;
  }

  .pre-expended-inner {
    p {
      font-size: 37px;
      line-height: 54px;
    }
  }

  .proj-sec-para {
    padding: 80px 0px 65px 0px;
  }

  .case-banner-below-inner h3 {
    font-size: 32px !important;
  }

  .case-stud-data p {
    font-size: 17px;
    line-height: 31px;
  }

  .roles-desc-sec ul li span {
    font-size: 16px;
  }

  .project-accodion-sec .MuiPaper-root .MuiButtonBase-root .MuiAccordionSummary-content .MuiTypography-root .acordian-head .acordian-head-left p {
    font-size: 21px;
    top: 12px;
  }

  .serveff-se {
    a {
      p {
        margin-bottom: 9px;
      }
    }
  }

  .servic-dis-sec {
    padding: 100px 0px;
  }

  .case-stud-sec-inner h3 {
    font-size: 31px !important;
    // margin-bottom: 89px !important;
  }

  .accordion-inner-para h3 {
    font-size: 19px !important;
  }

  .contact-heading h2 {
    font-size: 31px !important;
  }

  .career-newbgss {
    margin-top: 0px;
  }

  .main-sec-home {
    background-position: 70% 100%;
  }

  .service-head h2 {
    font-size: 33px !important;
    margin-top: 10px;
  }

  .service-inner-sec h3 {
    font-size: 17px;
  }

  .pre-expended-inner p {
    font-size: 30px !important;
    line-height: 51px !important;
  }

  .project-inner-sec h3 {
    max-width: 278px !important;
    font-size: 23px !important;
    line-height: 35px;
  }

  .project-sec-width {
    margin-right: 4px;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root .MuiButtonBase-root {
    width: 60px;
    height: 60px;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root .MuiButtonBase-root svg {
    font-size: 36px;
    right: -1px;
  }

  .project-acn-sec-main .project-acn-sec .proj-actn-left a span {
    margin-right: 3px;
  }

  .partner-inner h2 {
    font-size: 27px;
  }

  .partner-inner h3 {
    font-size: 20px;
  }

  .talk-inner h2 {
    font-size: 27px;
    line-height: 47px;
  }

  .callltoactn {
    img {
      height: 25px;
    }
  }

  .vision-img {
    height: 75px;
  }

  .vision-inner h2 {
    font-size: 25px;
  }

  .footer-sec .footer-left p {
    line-height: 26px;
    font-size: 15px;
  }

  .navbar-brand img {
    height: 26px;
  }

  .footer-sec .footer-left img {
    height: 26px;
  }

  .footer-sec .footer-center h3 {
    font-size: 19px;
  }

  .footer-sec .footer-mid-center h3 {
    font-size: 19px;
  }

  .footer-sec .footer-right h3 {
    font-size: 19px;
  }

  .abut-banner-cont {
    bottom: 12vh !important;

    h1 {
      font-size: 61px !important;
    }

    p {
      font-size: 24px !important;
    }
  }

  .expert-sec h2 {
    font-size: 31px !important;
  }

  .expert-sec p {
    font-size: 21px !important;
    line-height: 39px !important;
  }

  .servic-inner-sec p {
    font-size: 23px !important;
    line-height: 40px !important;
  }

  .interven-sec img {
    right: 0px;
  }

  .case-heading h4 {
    font-size: 21px;
  }

  .watch-video-sec .watcch-inner-sec .watch-sec p {
    font-size: 43px;
    padding: 26px 30px;
  }

  .interven-sec h3 {
    font-size: 26px;
  }

  .nav-link {
    padding: 10px 14px !important;
  }

  .service-desc-inner h3 {
    margin: 15px 5px 25px 5px;
    line-height: 30px;
  }

  .service-desc-inner p {
    font-size: 15px;
  }

  .project-acn-sec-main .project-acn-sec .proj-actn-right {
    right: 10px;
  }

  .project-acn-sec-main .project-acn-sec {
    padding-left: 12px;
  }

  .project-acn-sec-main .project-acn-sec .proj-actn-right a {
    font-size: 14px;
  }

  .project-acn-sec-main .project-acn-sec .proj-actn-left a {
    font-size: 14px;
  }

  .project-inner-sec img {
    height: 270px;
  }

  .vision-inner h3 {
    font-size: 18px;

    line-height: 27px;
  }

  .partner-inner {
    padding: 140px 40px 118px 60px;
  }

  .talk-inner {
    padding: 149px 50px 144px 90px;
  }

  .footer-sec .footer-center ul li a span {
    font-size: 15px;
  }

  .footer-sec .footer-mid-center ul li a span {
    font-size: 15px;
  }

  .footer-sec .footer-right ul li a span {
    font-size: 15px;
  }

  .btn-sec {
    padding: 14px 22px 12px 22px;
  }

  .gap-sec {
    padding: 10px 6px 10px 6px;

    img {
      height: 50px;
    }
  }

  .servic-njsmart-sec a p {
    padding: 60px 2px 15px 1px;
  }

  .servic-njsmart-sec a img {
    padding: 0px 14px 0px 2px;
  }

  .overlay-text {
    padding: 10px 10px 30px 10px;
    font-size: 17px;
  }

  .main-projt-watch {
    top: 170px;
  }

  .custom-play-button {
    top: 40%;
  }

  .case-btn-sec {
    margin-top: 10px;
  }

  .case-stud-sec {
    padding: 80px 0px 60px 0px;
  }

  .contact-details .contact-info {
    left: 20px;
    width: 124%;
  }

  .career-newbgss {
    padding-top: 30px;
  }

  .footer-sec .footer-right ul li a img {
    height: 18px;
  }

  .pre-expended-inner-new p {
    font-size: 32px !important;
    line-height: 50px !important;
  }

  .service-desc-inner {
    min-height: calc(95vh - 200px) !important;
  }

  .servic-njsmart-sec {
    height: 44.7vh;
  }

  .out-ul-sec li {
    font-size: 17px !important;
    margin-bottom: 12px;
  }

  .servicess-inner-sec {
    padding: 30px 0px;
  }

  .efficiency-content {
    padding-bottom: 60px !important;
  }

  .privacy-inner .personal-data-align-sec .data-align-left {
    width: 21% !important;
  }

  .privacy-inner .personal-data-align-sec .data-align-right {
    width: 77% !important;
  }

  .privacy-inner .privcy-head-sec .after-head {
    font-size: 24px !important;
    margin-bottom: 40px;
  }

  .privacy-inner .privcy-head-sec h2 {
    font-size: 23px !important;
  }

  .service-iew-sec {
    padding-left: 1px !important;
  }

  .otp-modal-new .otp-head {
    font-size: 17px !important;
    font-weight: 700 !important;
    line-height: 29px !important;
  }

  .otp-modal-new .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .button-sec .btn-sub {
    margin-top: 0px !important;
  }

  .otp-modal-new .MuiDialog-container .MuiPaper-root {
    padding: 20px 0px 20px 0px !important;
  }

  .final-no-modal .MuiDialog-container .MuiPaper-root {
    height: 77vh !important;
  }
}

@media only screen and (min-width: 1300px) and (max-width: 1449px) {
  .final-modal .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .MuiTypography-root .new-demo-para {
    font-size: 17px !important;
  }

  .final-modal .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .MuiTypography-root .new-demo-head {
    font-size: 17px !important;
  }

  .final-no-modal .MuiDialog-container .MuiPaper-root {
    height: 77vh !important;
  }

  .privacy-inner .personal-data-align-sec .data-align-left {
    width: 20% !important;
  }

  .privacy-inner .personal-data-align-sec .data-align-right {
    width: 78% !important;
  }

  .servic-njsmart-sec {
    height: 44.2vh;
  }

  .servic-njsmart-sec-new {
    height: 52.5vh;
  }

  .servicess-inner-sec {
    padding: 70px 0px 10px 0px !important;
  }

  .modal-yes .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .button-sec .btn-yes {
    margin-top: 11px;
  }

  .progrme-info-sec h2 {
    font-size: 34px !important;
    margin-bottom: 35px;
  }

  .progrme-info-sec p {
    font-size: 17px !important;
  }

  .modal-yes .MuiDialog-container .MuiPaper-root {
    padding: 20px 50px 24px 50px;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root .MuiDialogContent-root h2 {
    font-size: 24px;
  }

  .modal-yes .MuiDialog-container .MuiPaper-root .MuiDialogContent-root p {
    font-size: 18px;
  }

  .watch-video-sec .watcch-inner-sec .watch-video-play .watch-vid-play-inner {
    box-shadow: none;
  }

  .watch-video-sec .watcch-inner-sec .watch-sec {
    padding: 71px 0px;
  }

  .main-sec-homes {
    height: 75vh;
  }

  .navbar {
    padding-top: 12px;
    padding-bottom: 11px;
  }

  .banner-content {
    bottom: 13vh !important;
    left: 7% !important;
  }

  .pre-expended-sec {
    height: 43vh !important;
  }

  .pre-expended-inner {
    bottom: 13px !important;
  }

  .about-main {
    height: 46vh !important;
  }

  .banner-content {
    h1 {
      font-size: 75px !important;
      margin-bottom: 5px;
    }

    h2 {
      font-size: 75px !important;
    }

    p {
      font-size: 27px !important;
    }
  }

  .expert-sec h2 {
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 67px;
    margin-top: 74px;
  }

  .expert-sec p {
    font-size: 25px;
    line-height: 43px;
    margin-top: 30px;
  }

  .servic-dis-sec h2 {
    font-size: 36px !important;
    line-height: 59px !important;
    padding-top: 75px !important;
  }

  .abut-banner-cont {
    h1 {
      font-size: 76px;
    }
  }

  .proj-sec-para p {
    font-size: 29px !important;
    line-height: 47px !important;
  }

  .career-para p {
    font-size: 29px !important;
    line-height: 54px !important;
  }

  .pre-expended-inner {
    p {
      font-size: 37px;
      line-height: 54px;
    }
  }

  .proj-sec-para {
    padding: 80px 0px 65px 0px;
  }

  .case-banner-below-inner h3 {
    font-size: 33px !important;
  }

  .case-stud-sec-inner h3 {
    font-size: 31px !important;
    // margin-bottom: 89px !important;
  }

  .accordion-inner-para h3 {
    font-size: 20px !important;
  }

  .contact-heading h2 {
    font-size: 32px !important;
  }

  .career-newbgss {
    margin-top: 0px;
  }

  .main-sec-home {
    background-position: 70% 100%;
  }

  .service-head h2 {
    font-size: 33px !important;
    margin-top: 10px;
  }

  .service-inner-sec h3 {
    font-size: 17px;
  }

  .pre-expended-inner p {
    font-size: 32px !important;
    line-height: 52px !important;
  }

  .project-inner-sec h3 {
    max-width: 301px !important;
    font-size: 23px !important;
    line-height: 39px;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root .MuiButtonBase-root {
    width: 60px;
    height: 60px;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root .MuiButtonBase-root svg {
    font-size: 36px;
    right: -1px;
  }

  .partner-inner h2 {
    font-size: 27px;
  }

  .partner-inner h3 {
    font-size: 20px;
  }

  .talk-inner h2 {
    font-size: 27px;
    line-height: 52px;
  }

  .callltoactn {
    img {
      height: 25px;
    }
  }

  .vision-img {
    height: 82px;
  }

  .vision-inner h2 {
    font-size: 27px;
  }

  .footer-sec .footer-left p {
    line-height: 26px;
    font-size: 15px;
  }

  .navbar-brand img {
    height: 26px;
  }

  .footer-sec .footer-left img {
    height: 26px;
  }

  .footer-sec .footer-center h3 {
    font-size: 19px;
  }

  .footer-sec .footer-mid-center h3 {
    font-size: 19px;
  }

  .footer-sec .footer-right h3 {
    font-size: 19px;
  }

  .abut-banner-cont {
    bottom: 12vh !important;

    h1 {
      font-size: 65px !important;
    }

    p {
      font-size: 26px !important;
    }
  }

  .expert-sec h2 {
    font-size: 32px !important;
  }

  .expert-sec p {
    font-size: 22px !important;
    line-height: 41px !important;
  }

  .servic-inner-sec p {
    font-size: 23px !important;
    line-height: 42px !important;
  }

  .interven-sec img {
    right: 0px;
  }

  .case-heading h4 {
    font-size: 21px;
  }

  .watch-video-sec .watcch-inner-sec .watch-sec p {
    font-size: 45px;
    padding: 22px 30px;
  }

  .interven-sec h3 {
    font-size: 26px;
  }

  .nav-link {
    padding: 10px 14px !important;
  }

  .service-desc-inner h3 {
    margin: 15px 5px 25px 5px;
    line-height: 30px;
  }

  .service-desc-inner p {
    font-size: 15px;
  }

  .project-acn-sec-main .project-acn-sec .proj-actn-right {
    right: 10px;
  }

  .project-acn-sec-main .project-acn-sec {
    padding-left: 12px;
  }

  .project-acn-sec-main .project-acn-sec .proj-actn-right a {
    font-size: 15px;
  }

  .project-acn-sec-main .project-acn-sec .proj-actn-left a {
    font-size: 15px;
  }

  .project-inner-sec img {
    height: 270px;
  }

  .vision-inner h3 {
    font-size: 18px;

    line-height: 27px;
  }

  .partner-inner {
    padding: 140px 50px 125px 90px;
  }

  .talk-inner {
    padding: 149px 50px 147px 90px;
  }

  .footer-sec .footer-center ul li a span {
    font-size: 15px;
  }

  .footer-sec .footer-mid-center ul li a span {
    font-size: 15px;
  }

  .footer-sec .footer-right ul li a span {
    font-size: 15px;
  }

  .btn-sec {
    padding: 14px 22px 12px 22px;
  }

  .gap-sec {
    padding: 10px 6px 10px 6px;

    img {
      height: 50px;
    }
  }

  .servic-other-sec .gap-new-sec {
    height: 27vh;
  }

  .servics-sec .servic-njsmart-sec-new {
    height: 56.4vh !important;
  }

  .servic-njsmart-sec a p {
    padding: 60px 2px 15px 1px;
  }

  .servic-njsmart-sec a img {
    padding: 0px 14px 0px 2px;
  }

  .overlay-text {
    padding: 10px 10px 30px 10px;
    font-size: 17px;
  }

  .main-projt-watch {
    top: 170px;
  }

  .custom-play-button {
    top: 40%;
  }

  .case-btn-sec {
    margin-top: 10px;
  }

  .case-stud-sec {
    padding: 80px 0px 60px 0px;
  }

  .contact-details .contact-info {
    left: 20px;
    width: 124%;
  }

  .career-newbgss {
    padding-top: 30px;
  }

  .pre-expended-inner-new p {
    font-size: 34px !important;
  }

  .service-desc-inner {
    min-height: calc(90vh - 200px) !important;
  }

  .happy-modal .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .MuiTypography-root .form-sec-main .form-sec {
    margin-bottom: 20px !important;
  }

  .modal-casestudy .MuiDialogContent-root .validate-sec-mode .error-msg-sec .error-msg {
    font-size: 14px !important;
  }

  .modal-casestudy .MuiDialogContent-root .validate-sec-mode .success-msg-sec .succ-msg {
    font-size: 16px;
  }

  .modal-yes .MuiDialog-container .MuiPaper-root {
    max-width: 460px;
  }

  .reol-ul-sec .case-left {
    font-size: 17px !important;
  }

  .reol-ul-sec .case-right {
    font-size: 17px !important;
  }

  .out-ul-sec li {
    font-size: 17px !important;
  }

  .efficiency-content {
    padding-bottom: 90px !important;
  }

  .service-iew-sec {
    padding-left: 1px !important;
  }

  .privacy-inner .privcy-head-sec .after-head {
    font-size: 24px !important;
    margin-bottom: 40px;
  }

  .otp-modal-new .otp-head {
    font-size: 17px !important;
    font-weight: 700 !important;
    line-height: 29px !important;
  }

  .otp-modal-new .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .button-sec .btn-sub {
    margin-top: 0px !important;
  }
}

@media only screen and (min-width: 1450px) and (max-width: 1599px) {
  .final-no-modal .MuiDialog-container .MuiPaper-elevation {
    height: 77vh !important;
  }

  .servics-sec .servic-njsmart-sec-new {
    height: 60.5vh !important;
  }

  .servic-other-sec .gap-new-sec {
    height: 29vh !important;
  }

  .servic-njsmart-sec {
    height: 44.2vh;
  }

  .main-sec-homes {
    height: 75vh;
  }

  body .about-main {
    height: 56vh !important;
  }

  .navbar {
    padding-top: 12px;
    padding-bottom: 11px;
  }

  .banner-content {
    bottom: 14vh !important;
    left: 5% !important;
  }

  .pre-expended-sec {
    height: 33vh;
  }

  .pre-expended-inner {
    bottom: 17px;
  }

  .about-main {
    height: 46vh !important;
  }

  .banner-content {
    h1 {
      font-size: 80px !important;
    }

    h2 {
      font-size: 80px !important;
    }

    p {
      font-size: 28px !important;
    }
  }

  .privacy-inner .privcy-head-sec .after-head {
    font-size: 24px !important;
    margin-bottom: 40px;
  }

  .expert-sec h2 {
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 67px;
    margin-top: 74px;
  }

  .expert-sec p {
    font-size: 25px;
    line-height: 43px;
    margin-top: 30px;
  }

  .servic-dis-sec h2 {
    font-size: 45px !important;
    line-height: 64px !important;
    padding-top: 45px !important;
  }

  .abut-banner-cont {
    h1 {
      font-size: 76px;
    }
  }

  .proj-sec-para p {
    font-size: 33px !important;
    line-height: 57px !important;
  }

  .career-para p {
    font-size: 31px !important;
    line-height: 55px !important;
  }

  .pre-expended-inner {
    p {
      font-size: 37px;
      line-height: 54px;
    }
  }

  .case-banner-below-inner h3 {
    font-size: 33px !important;
  }

  .case-stud-sec-inner h3 {
    font-size: 34px !important;
    // margin-bottom: 109px !important;
  }

  .accordion-inner-para h3 {
    font-size: 20px !important;
  }

  .contact-heading h2 {
    font-size: 32px !important;
  }

  .career-newbgss {
    margin-top: 0px;
  }

  .main-sec-home {
    background-position: 70% 100%;
  }

  .service-head h2 {
    font-size: 34px !important;
  }

  .service-inner-sec h3 {
    font-size: 18px;
  }

  .pre-expended-inner p {
    font-size: 35px !important;
    line-height: 52px !important;
  }

  .project-inner-sec h3 {
    max-width: 326px !important;
    font-size: 27px !important;
    line-height: 40px;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root .MuiButtonBase-root {
    width: 60px;
    height: 60px;
  }

  .modal-casestudy .MuiDialog-container .MuiPaper-root .MuiButtonBase-root svg {
    font-size: 36px;
    right: -1px;
  }

  .partner-inner h2 {
    font-size: 27px;
  }

  .partner-inner h3 {
    font-size: 20px;
  }

  .talk-inner h2 {
    font-size: 28px;
    line-height: 52px;
  }

  .callltoactn {
    img {
      height: 25px;
    }
  }

  .vision-img {
    height: 85px;
  }

  .vision-inner h2 {
    font-size: 29px;
  }

  .footer-sec .footer-left p {
    line-height: 28px;
  }

  .navbar-brand img {
    height: 26px;
  }

  .footer-sec .footer-left img {
    height: 26px;
  }

  .footer-sec .footer-center h3 {
    font-size: 20px;
  }

  .footer-sec .footer-mid-center h3 {
    font-size: 20px;
  }

  .footer-sec .footer-right h3 {
    font-size: 20px;
  }

  .abut-banner-cont {
    bottom: 12vh !important;

    h1 {
      font-size: 71px !important;
    }

    p {
      font-size: 26px !important;
    }
  }

  .pre-expended-inner-new p {
    font-size: 35px !important;
  }

  .expert-sec h2 {
    font-size: 34px !important;
  }

  .expert-sec p {
    font-size: 24px !important;
    line-height: 40px !important;
  }

  .servic-inner-sec p {
    font-size: 24px !important;
    line-height: 42px !important;
  }

  .interven-sec img {
    right: 0px;
  }

  .case-heading h4 {
    font-size: 21px;
  }

  .watch-video-sec .watcch-inner-sec .watch-sec p {
    font-size: 50px;
    padding: 22px 30px;
  }

  .interven-sec h3 {
    font-size: 26px;
  }

  .service-desc-inner {
    min-height: calc(80vh - 200px);
  }

  .point-sec-service {
    padding: 100px 0px 10px 0px;
  }

  .efficiency-content {
    padding-bottom: 60px;
  }

  .otp-head {
    font-size: 17px !important;
    font-weight: 700 !important;
    line-height: 29px !important;
  }

  .final-modal .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .new-demo-para {
    margin-top: 0px !important;
  }

  .final-modal .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .new-demo-head {
    margin-top: 7px !important;
  }

  .final-modal .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .MuiTypography-root .new-demo-head {
    font-size: 17px !important;
  }

  .final-modal .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .MuiTypography-root .new-demo-para {
    font-size: 17px !important;
  }




}

@media only screen and (max-width: 1600px) {
  .modal-cases .MuiDialog-container .MuiPaper-root {
    height: auto !important;
  }

  .banner-content {
    bottom: 13vh;
    left: 9%;
  }

  .pre-expended-sec {
    height: 33vh;
  }

  .pre-expended-inner {
    bottom: 17px;
  }

  .about-main {
    height: 46vh !important;
  }

  .banner-content {
    h1 {
      font-size: 85px;
    }

    h2 {
      font-size: 85px;
    }

    p {
      font-size: 30px;
    }
  }

  .expert-sec h2 {
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 67px;
    margin-top: 74px;
  }

  .pre-expended-inner-new p {
    font-size: 37px;
  }

  .expert-sec p {
    font-size: 25px;
    line-height: 43px;
    margin-top: 30px;
  }

  .servic-dis-sec h2 {
    font-size: 46px;
    line-height: 64px;
    padding-top: 50px;
  }

  .abut-banner-cont {
    h1 {
      font-size: 76px;
    }
  }

  .proj-sec-para p {
    font-size: 35px;
    line-height: 61px;
  }

  .pre-expended-inner {
    p {
      font-size: 37px;
      line-height: 54px;
    }
  }

  .case-banner-below-inner h3 {
    font-size: 34px;
  }

  .case-stud-sec-inner h3 {
    font-size: 38px;
    margin-bottom: 109px;
  }

  .accordion-inner-para h3 {
    font-size: 21px;
  }

  .contact-heading h2 {
    font-size: 33px;
  }

  .career-newbgss {
    margin-top: 0px;
  }

  .service-desc-inner {
    min-height: calc(85vh - 200px);
  }

  .servic-other-sec .gap-new-sec {
    height: 27vh;
  }

  .servics-sec .servic-njsmart-sec-new {
    height: 56.3vh;
  }

  .final-modal .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .new-demo-para {
    margin-top: 0px !important;
  }

  .final-modal .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .new-demo-head {
    margin-top: 7px !important;
  }

  // .final-modal .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .MuiTypography-root .new-demo-head{
  //   font-size: 17px !important;
  // }
  // .final-modal .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .MuiTypography-root .new-demo-para{
  //   font-size: 17px !important;
  // }
}

@media only screen and (min-width: 1700px) and (max-width: 1799px) {
  .talk-inner {
    padding: 180px 50px 156px 90px;
  }

  .main-sec-homes {
    height: 85vh;
  }

  .banner-content {
    bottom: 12vh;
    left: 11%;
  }

  .pre-expended-sec {
    height: 33vh;
  }

  .pre-expended-inner {
    bottom: 17px;
  }

  .about-main {
    height: 44vh !important;
  }

  .banner-content {
    h1 {
      font-size: 86px;
    }

    h2 {
      font-size: 86px;
    }
  }

  .servic-dis-sec h2 {
    font-size: 48px;
    line-height: 66px;
    padding-top: 51px;
  }

  .abut-banner-cont {
    h1 {
      font-size: 81px;
    }
  }

  .proj-sec-para p {
    font-size: 35px;
    line-height: 61px;
  }

  .main-sec-home {
    background-position: 100% 100%;
  }

  .final-modal .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .new-demo-para {
    margin-top: 0px !important;
  }

  .final-modal .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .new-demo-head {
    margin-top: 7px !important;
  }
}

@media only screen and (min-width: 1800px) and (max-width: 1900px) {
  .final-modal .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .new-demo-para {
    margin-top: 0px !important;
  }

  .final-modal .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .new-demo-head {
    margin-top: 7px !important;
  }

  .main-sec-homes {
    height: 85vh;
  }

  .banner-content {
    bottom: 12vh;
  }

  .pre-expended-sec {
    height: 33vh;
  }

  .pre-expended-inner {
    bottom: 17px;
  }

  .about-main {
    height: 44vh !important;
  }

  .banner-content {
    h1 {
      font-size: 86px;
    }

    h2 {
      font-size: 86px;
    }
  }

  .servic-dis-sec h2 {
    font-size: 48px;
    line-height: 66px;
    padding-top: 51px;
  }

  .abut-banner-cont {
    h1 {
      font-size: 81px;
    }
  }

  .proj-sec-para p {
    font-size: 35px;
    line-height: 61px;
  }

}

@media only screen and (max-width: 480px) {
  .case-btn-sec .more-btn {
    padding: 15px 18px 13px 18px !important;
    font-size: 15px !important;
  }

  .talk-to-sec {
    position: relative !important;
    bottom: 0px !important;
    right: 0px !important;
    margin-bottom: 10px !important;
    float: none !important;
  }

  .talk-sec .talk-btn-sec {
    margin-bottom: 20px !important;
  }

  .navbar-toggler {
    border: none;
  }

  .navbar-brand {
    padding: 0px 19px;
  }

  .main-sec-homes {
    width: 100%;
    position: relative;
    height: 53vh;
    overflow: hidden;
  }

  .main-sec-home {
    height: 100%;
    background-size: cover;
    position: absolute;
    left: 0;
    top: 47px;
    width: 100%;
    background-position: 70% 60%;
  }

  .banner-content {
    position: absolute;
    bottom: 2vh;
    max-width: 820px;
    left: 8%;
    text-align: left;
  }

  .banner-content h1 {
    font-size: 27px;
    text-shadow: 2px 2px rgba(128, 128, 128, 0.6);
    font-weight: 800;
    margin-bottom: 3px;
  }

  .banner-content h2 {
    font-size: 27px;
    text-shadow: 2px 4px rgba(128, 128, 128, 0.23);
    font-weight: 800;
    margin-bottom: 5px;
  }

  .banner-content p {
    margin-bottom: 0px;
    font-size: 16px;
    width: 100%;
    line-height: 32px;
    font-weight: 400;
  }

  .navbar-brand img {
    height: 22px;
  }

  .nav-link::after {
    display: none;
  }

  .service-desc-sec {
    padding: 28px 0px 28px 0px;
  }

  .service-desc-inner {
    margin-bottom: 70px;
  }

  .no-padding-right {
    padding-right: 0px;
    padding-left: 0px;
  }

  .service-desc-inner {
    h3 {
      font-size: 17px;
      margin: 15px 20px 17px 20px;
    }
  }

  .service-desc-inner {
    p {
      max-width: 360px;
      font-size: 15px;
    }
  }

  .no-padding-left {
    padding-right: 0px;
  }

  .service-head h2 {
    margin-top: 10px;
    margin-bottom: 50px;
    font-size: 29px;
  }

  .service-inner-sec .service-iiner {
    width: 100%;
    margin-bottom: 40px;
  }

  .service-inner-sec .service-iiner:after {
    display: none;
  }

  .project-sec-width {
    width: 100%;
    float: left;
    margin-right: 5px;
    cursor: pointer;
    margin-bottom: 20px;
  }

  .vision-img {
    float: left;
    height: 80px;
  }

  .vision-sec {
    margin-top: 70px;
    margin-bottom: 20px;
  }

  .vision-inner h2 {
    font-size: 26px;
  }

  .vision-inner {
    text-align: left;
    margin-top: 30px;
  }

  .vision-inner h3 {
    font-size: 18px;
    line-height: 27px;
    margin-top: 20px;
  }

  .pre-expended-inner p {
    font-size: 15px;
    font-weight: 500;
    position: relative;
    line-height: 28px;
  }

  .partner-inner {
    padding: 80px 25px 85px 25px;
  }

  .partner-inner h2 {
    font-size: 25px;
  }

  .partner-inner {
    h3 {
      font-size: 18px;
      margin-top: 13px;
    }

    p {}
  }

  .talk-inner {
    padding: 60px 20px 82px 30px;

    h2 {
      font-size: 26px;
      line-height: 46px;
    }
  }

  .callltoactn {
    img {
      height: 21px;
    }
  }

  .unique-sec {
    margin-top: 50px;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }

  .unique-sec {
    .vision-inner {
      p {
        margin-bottom: 70px;
      }
    }
  }

  .footer-sec .footer-left img {
    text-align: left;
    height: 22px;
  }

  .footer-sec .footer-left p {
    margin-bottom: 0px;
    margin-top: 18px;
    font-size: 15px;
    font-weight: 300;
    line-height: 24px;
    margin-bottom: 30px;
  }

  .footer-sec {
    padding: 50px 10px 10px 10px;
  }

  .footer-center {
    margin-bottom: 40px;
  }

  .footer-sec .footer-center ul {
    margin-top: 18px;
  }

  .footer-sec .footer-left {
    margin-bottom: 10px;
  }

  .footer-sec .footer-center h3 {
    font-size: 20px;
  }

  .footer-sec .footer-mid-center h3 {
    font-size: 20px;
  }

  .footer-sec .footer-right h3 {
    font-size: 20px;
  }

  .footer-mid-center {
    margin-bottom: 40px;
  }

  .hr-sec {
    margin: 40px 0px 40px 0px;
  }

  .footer-bottom-left p {
    margin-bottom: 11px;
    text-align: center;
  }
}

// responsive code end

.pre-expended-inner-new {
  position: relative;
  text-align: center;

  // bottom: 40px;
  // text-align: center;
  // left: 31%;
  // transform: translate(-22%, -50%);
  p {
    color: $offwhitecolor;
    font-size: 39px;
    font-weight: 500;
    position: relative;
    line-height: 55px;

    &::after {
      content: "";
      display: block;
      width: 70px;
      height: 2px;
      background: $main-color;
      margin-top: 20px;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 50%);
    }
  }
}

.pre-expended-inner-new {
  padding: 40px 0px;
}

.pre-expended-sec-new {
  background-image: url("../img/prep-expanded.jpg");

  padding: 1rem;

  background-repeat: no-repeat;
  // height: 30vh;
}

.mobile-watch {
  display: none;
}

.contact-form {
  .form-sec-main {
    form {
      .row {
        margin-bottom: 15px;
      }
    }
  }
}

.recaptcha-sec {
  margin-top: 20px;
}

.service-one-line:hover {
  background-color: #9fad2fcf;
  cursor: pointer;
  color: $bgcolor;

  h3 {
    color: $bgcolor;
  }

  img {
    opacity: 0;
  }

  .whitelogo {
    opacity: 1;
  }
}

.service-desc-inner img {
  opacity: 1;
}

.service-desc-inner .whitelogo {
  opacity: 0;

  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
}

.service-two-line:hover {
  background-color: #2bb0bfd4;
  cursor: pointer;
  color: $bgcolor;

  h3 {
    color: $bgcolor;
  }

  img {
    opacity: 0;
  }

  .whitelogo {
    opacity: 1;
  }
}

.service-three-line:hover {
  background-color: #c054e5d1;
  cursor: pointer;
  color: $bgcolor;

  h3 {
    color: $bgcolor;
  }

  img {
    opacity: 0;
  }

  .whitelogo {
    opacity: 1;
  }
}

.service-four-line:hover {
  background-color: #e9a609eb;
  cursor: pointer;
  color: $bgcolor;

  h3 {
    color: $bgcolor;
  }

  img {
    opacity: 0;
  }

  .whitelogo {
    opacity: 1;
  }
}

.whitelogo {
  opacity: 0;
}

.happy-modal {
  .MuiDialog-container {
    .MuiPaper-root {
      .MuiDialogContent-root {
        .MuiTypography-root {
          .form-sec-main {
            .form-sec {
              margin-bottom: 22px;
            }
          }
        }
      }
    }
  }
}

.CookieConsent {
  background-color: $bgcolor !important;
      border-top: 1px solid #323232 !important;
      padding: 13px 20px !important;
}

.accptbtn {
  background-color: $blue-color !important;
  color: $bgcolor !important;
  border: 1px solid $blue-color !important;
  font-size: 15px !important;

  &:hover {
    background-color: $blue-color !important;
    color: $bgcolor !important;
  }
}

.declinbtn {
  background-color: transparent !important;
  color: $bgcolor !important;
  border: 1px solid $blue-color !important;
  font-size: 15px !important;
  margin: 0px 0px !important;

  &:hover {
    background-color: $bgcolor !important;
    color: $blue-color !important;
  }
}

.validate-sec {
  position: relative;
  width: 100%;
  display: block;

  .success-msg-sec {
    position: relative;

    .succ-msg {
      font-size: 16px;
      font-weight: 600;
      color: $successcolor;
      margin-bottom: 0px;
      position: absolute;
    }
  }

  .error-msg-sec {
    position: relative;

    .error-msg {
      font-size: 16px;
      font-weight: 600;
      color: $successcolor;
      margin-bottom: 0px;
      color: $redcolor;
      position: absolute;
    }
  }
}

.contact-submit-sec {
  margin-bottom: 0px !important;
}

.mob-sec-new {
  display: none;
}

.modal-casestudy .MuiDialogContent-root .validate-sec-mode {
  position: relative;
  width: 100%;
  display: block;

  .success-msg-sec {
    position: relative;

    .succ-msg {
      font-size: 16px !important;
      font-weight: 600 !important;
      color: $successcolor !important;
      margin-bottom: 0px !important;
      position: absolute !important;
    }
  }

  .error-msg-sec {
    position: relative;

    .error-msg {
      font-size: 16px !important;
      font-weight: 600 !important;
      color: $successcolor !important;
      margin-bottom: 0px !important;
      color: $redcolor !important;
      position: absolute !important;
    }
  }
}

.progrme-info-sec {
  width: 100%;
  position: relative;
  display: block;
  margin-top: 64px;
  padding: 80px 0px 80px 0px;

  p {
    font-size: 18px;
    text-align: center;
    margin-bottom: 35px;
  }

  h2 {
    font-size: 36px;
    margin-bottom: 35px;
  }
}

/* Loader style */

.loader {
  width: 48px;
  height: 48px;
  display: block;
  // margin: 15px auto;
  position: relative;
  color: #ccc;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  margin: 18% auto;
}

.loader::after,
.loader::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  width: 24px;
  height: 24px;
  top: 50%;
  left: 50%;
  transform: scale(0.5) translate(0, 0);
  background-color: #ccc;
  border-radius: 50%;
  animation: animloader 1s infinite ease-in-out;
}

.loader::before {
  background-color: $greytextcolor;
  transform: scale(0.5) translate(-48px, -48px);
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animloader {
  50% {
    transform: scale(1) translate(-50%, -50%);
  }
}

/* END Loader style */

.modal-career .MuiDialog-container .MuiPaper-root {
  max-width: 480px;
  padding: 30px 25px 25px 25px;
}

.modal-career .MuiDialog-container .MuiPaper-root .MuiDialogContent-root h2 {
  font-size: 23px;
  font-weight: 700;
  line-height: 40px;
  margin-bottom: 0px;
}

.modal-casestudy .MuiDialog-container .MuiPaper-root .MuiButtonBase-root {
  width: 55px;
  height: 55px;
}

.modal-casestudy .MuiDialog-container .MuiPaper-root .MuiButtonBase-root svg {
  color: $blue-color;
  font-size: 37px;
}

.view-proj {
  .text-projt {
    p {
      .view-proj-sec {
        color: $bgcolor;
        text-decoration: none;
        font-size: 15px;
      }
    }
  }
}

.reol-ul-sec {
  margin-top: 25px;
  margin-bottom: 0px;

  .case-left {
    width: 20%;
    float: left;
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
  }

  .case-right {
    width: 78%;
    // float: left;
    font-weight: 500;
    font-size: 18px;
    position: relative;
    display: block;
    margin-left: 5px;
  }
}

.case-stud-data {
  .reol-ul-sec {
    li {
      p {
        width: 100%;
        position: relative;
        display: flex;
      }
    }
  }
}

.out-ul-sec {
  li {
    font-size: 18px;
    margin-bottom: 15px;
    font-weight: 400;
  }
}

.reol-ul-sec {
  li {
    margin-bottom: 13px;
  }
}

.no-videos {
  padding-top: 0px !important;
}

.no-vide--btn {
  margin-top: 0px !important;
}

.career-newbgss {
  margin-top: 24px;
}

.contact-thanku .MuiDialog-container .MuiPaper-root {
  max-width: 440px;
  padding: 40px 25px 35px 25px;
}

.contact-thanku .MuiDialog-container .MuiPaper-root .MuiDialogContent-root h2 {
  font-size: 26px;
}

.contact-thanku .MuiDialog-container .MuiPaper-root .MuiDialogContent-root p {
  font-size: 18px;
}

.contact-thanku .MuiDialog-container .MuiPaper-root .MuiButtonBase-root {
  top: -30px;
  right: -30px;
}

.contact-thanku .MuiDialog-container .MuiPaper-root .MuiButtonBase-root {
  width: 45px;
  height: 45px;
}

.modal-casestudy .MuiDialog-container .MuiPaper-root .MuiButtonBase-root svg {
  font-size: 31px;
}

.servicess-inner-sec {
  width: 100%;
  position: relative;
  margin-top: 64px;
  padding: 90px 0px;
}

.servicess-inner-para {
  p {
    font-size: 20px;
    line-height: 38px;
    font-weight: 500;
  }
}

.servicess-divid {
  padding: 90px 0px 10px 0px;

  .srvicess-left {
    width: 100%;
    position: relative;

    img {
      height: 130px;
      margin: 0 auto;
      height: 110px;
      margin: 0 auto;
      position: relative;
      top: 20px;
    }
  }

  .srvicess-right {
    h2 {
      text-align: left;
      margin-bottom: 20px;
      font-size: 32px;
      font-weight: 600;
    }

    p {
      text-align: left;
      font-size: 16px;
      line-height: 32px;
      font-weight: 500;
    }
  }
}

.srvicess-left:after {
  content: "";
  display: block;
  width: 2px;
  height: 192px;
  background: #d7d7d7bd;
  margin-top: -115px;
  position: absolute;
  right: 60px;
}

.point-srvicess-left:after {
  left: 60px;
  height: 320px;
}

.point-sec-service {
  padding: 140px 0px 10px 0px;
}

.srvicess-right {
  ul {
    padding-left: 0px;

    li {
      text-align: left;
      font-weight: 700;
      font-size: 18px;
      color: $greytextcolor;
    }

    p {
      margin-bottom: 15px;
      font-size: 16px !important;
      line-height: 27px !important;
      margin-top: 5px;
    }
  }
}

.point-srvicess-left img {
  height: 140px !important;
  margin: 0 auto !important;
  position: relative !important;
  top: 90px !important;
}

.reol-ul-sec {
  li::marker {
    color: $blue-color;
  }
}

.out-ul-sec {
  li::marker {
    color: $blue-color;
  }
}

.service-desc-new {
  padding: 30px 0px 60px 0px;
}

.service-headss {
  text-align: center;

  p {
    color: $greytextcolor;
    font-size: 32px;
    font-weight: 600;
    line-height: 45px;
    margin-bottom: 15px;
  }
}

.service-seccc {
  margin-top: 30px;
}

.service-headss {
  margin-bottom: 80px;
}

.loader-left {
  position: relative;
  left: 5px;
}

.workflow-content {
  bottom: 12vh;
}

.efficiency-content {
  padding-bottom: 60px;
}

// css start for contactnew page
.contact-form-new {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.connect-sec-new {
  width: 100%;
  max-width: 430px;
  margin: 0 auto;
  padding-top: 30px;
}

.contact-info-new {
  text-align: center;
  margin: 0 auto;
  display: inline-block;

  .mail-sec-new {
    width: 50px;
    height: 50px;
    background-color: $blue-color;
    color: $bgcolor;
    border-radius: 50%;
    position: relative;
    display: block;
    margin-bottom: 0px;
    display: block;
    margin-bottom: 20px !important;
    text-align: center;
    margin: 0 auto;

    .fa-envelope {
      position: relative;
      top: 14px;
      font-size: 17px;
      color: $bgcolor;
    }

    .connect-mail-new {
      margin-bottom: 0px;
      font-weight: 500;
      cursor: pointer;
    }

    img {}
  }
}

.connect-mail-new {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 0px;

  a {
    color: $blue-color;
    text-decoration: none;
  }
}

.img-call-new {
  position: relative;
  top: 13px;
  height: 18px;
}

.margin-load {
  position: absolute;
  /* left: -2px; */
  font-size: 12px;
  margin-left: 3px;
  margin-top: 1px;
}

// css end for contactnew page

// privacypolicy page code start
.privacy-main-sec {
  width: 100%;
  position: relative;
  margin-top: 90px;
  padding: 50px 0px;
  display: block;
  text-align: left;
}

.privacy-inner {
  width: 100%;
  position: relative;

  p {
    font-size: 17px;
    line-height: 33px;
    font-weight: 400;
    margin-bottom: 15px;
  }

  .privcy-head-sec {
    width: 100%;
    position: relative;
    margin-top: 30px;

    h2 {
      font-size: 24px;
      font-weight: 700;
      line-height: 35px;
      margin-bottom: 19px;
      color: $black-color;
      // &:after{
      //     content: "";
      //     display: block;
      //     width: 70px;
      //     height: 2px;
      //     background: $greytextcolor;
      //     margin-top: 8px;
      //     position: absolute;
      //     margin-left: 2px;
      // }
    }

    .after-head {
      font-size: 26px;
      margin-bottom: 40px;
    }

    .after-head:after {
      content: "";
      display: block;
      width: 85px;
      height: 2px;
      background: $greytextcolor;
      margin-top: 8px;
      position: absolute;
      margin-left: 2px;
    }

    h3 {
      font-size: 22px;
      font-weight: 700;
      line-height: 33px;
      margin-top: 30px;
      margin-bottom: 12px;
      color: $black-color;
    }

    h4 {
      font-size: 19px;
      color: $black-color;
      font-weight: 700;
      margin-top: 29px;
      margin-bottom: 18px;
    }
  }

  .bold-sec {
    font-weight: 900;
    color: $black-color;
  }

  ul {
    li {
      margin-bottom: 10px;
      font-size: 17px;
      line-height: 33px;
    }
  }

  .personal-data-align-sec {
    width: 100%;
    position: relative;
    display: flex;

    .data-align-left {
      float: left;
      font-weight: 700;
      color: $black-color;
      font-size: 18px;
      margin-right: 17px;
      width: 18%;
      line-height: 28px;
    }

    .data-align-md {
      width: 2%;
    }

    .data-align-right {
      width: 82%;
    }
  }
}

// .cooks-sec{
//     width:100%;
//     position: relative;
//     display: flex;
//     .cooks-sec-left{
//         width: 14% !important;
//         float: left;
//         font-weight: 600;
//         color:$black-color;
//         font-size: 18px;
//         margin-right: 17px;
//         line-height: 28px;
//     }
//     .cooks-sec-md{
//         width:2% !important;
//     }
//     .cooks-sec-right{
//         width: 86% !important;
//     }
// }
.link-color {
  color: $blue-color;
}

.bottom-main {
  margin-bottom: 12px !important;
}

.personal-data-ul {
  ul {
    li {
      margin-bottom: 2px;
    }
  }
}

// privacypolicy page code end
// service view sec start
.service-iew-sec {
  position: relative;
  width: 100%;
  padding-left: 11px;

  .serv-left-view {
    margin-bottom: 14px;
    color: $blue-color;

    a {
      span {
        font-size: 15px;
        font-weight: 600;
        margin-right: 4px;
      }

      i {
        position: relative;
        top: 2px;
      }
    }
  }
}

.small-serv-left-view {
  padding-left: 2px !important;

  .smal-left-sec {
    margin-bottom: 0px;
    margin-top: 9px;
  }
}

.gap-new-sec {
  height: 25vh;
}

.servic-njsmart-sec-new {
  height: 52.2vh;
}

.btn-prebck {
  text-align: center;
}

.cookies-next {
  font-size: 23px;
  color: #000000;
  font-weight: 700;
  margin-top: 29px;
  margin-bottom: 18px;
}

.prev-sec-btn {
  background-color: $bgcolor;
  border: 2px solid $blue-color;
  border-radius: 5px;
  padding: 9px 6px 9px 6px;
  width: 100%;
  max-width: 85px;
  font-size: 16px;
  line-height: 20px;
  margin-right: 9px;
  text-align: center;
  color: $blue-color;

  svg {
    font-size: 27px;
    position: relative;
    top: -1px;
    width: 21px;
    height: 21px;
  }
}

.prev-sec-btn {
  .fa-angle-left {
    margin-right: 5px;
  }
}

.next-sec-btn {
  .fa-angle-right {
    margin-left: 5px;
  }
}

.prev-sec-btn:hover {
  background-color: $blue-color;
  color: $bgcolor;
}

.btn-prebck {
  margin-top: 35px;
}

.transfer-data {
  margin-top: 0px;
}

.privacy-cont-sec {
  text-align: left;
  margin-bottom: 0px;
  font-size: 15px;
  font-weight: 600;
}

.link-priva {
  color: $blue-color !important;
  text-decoration: none;
  cursor: pointer;
}

// service view sec end

// tooltipsect start
.tooltipnew {
  position: relative;
  display: inline-block;
  color: $redcolor;
  width: 100%;

  .fa-info-circle {
    color: $redcolor !important;
    position: absolute;
    right: 0px;
    font-size: 14px;
    bottom: 32px;
  }
}

.tooltipnew .tooltiptext {
  visibility: hidden;
  width: 190px;
  background-color: $greytextcolor;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 52px;
  right: -20px;
  margin-left: -60px;
  font-size: 13px;
}

.tooltipnew .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 86%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.tooltipnew:hover .tooltiptext {
  visibility: visible;
}

// tooltipsec end

.modal-yes .MuiDialog-container .MuiPaper-root .MuiDialogContent-root p .form-sec-main .form-sec .veri-secss {
  text-align: right;
  margin-top: 0px !important;
  margin-bottom: 0px !important;

  span {
    text-align: right;
    font-size: 12px;
    margin: 4px 0px 0px 0px;
  }
}

.modal-casestudy .MuiDialog-container .MuiPaper-root .MuiDialogContent-root p .form-sec-main .form-sec .case-privacy {
  text-align: left;
  font-size: 15px;
  font-weight: 600;
}

.modal-casestudy .MuiDialog-container .MuiPaper-root .MuiDialogContent-root p .form-sec-main .form-sec .case-privacy .case-link-prv {
  color: $blue-color !important;
  font-size: 15px;
  text-decoration: none;
}

.modal-casestudy .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .MuiTypography-root .form-sec-main .form-radio-sec .MuiFormControl-root {
  label {
    font-family: $main-font !important;
    position: relative;
    display: block;
    float: left;
    text-align: left;

    .MuiTypography-root {
      font-family: $main-font !important;
      margin-left: 5px;
      font-size: 15px;
    }

    .MuiRadio-root {
      box-shadow: none !important;
      position: relative;
      width: 30px;
      height: 40px;
      float: left;
      background-color: transparent;
      right: 0px;
      top: -1px;
    }
  }
}

.contactng-sec {
  text-align: left !important;
  font-weight: 600 !important;
  color: $greytextcolor !important;
  font-size: 17px !important;
  margin-bottom: 10px !important;
}

.modal-cases .MuiDialog-container .MuiPaper-root {
  margin: 0px;
  width: 100%;
  max-width: 530px;
  padding: 20px 0px 10px 0px;
  height: 80vh;
}

.modal-cases .MuiDialog-container .MuiPaper-root .MuiButtonBase-root svg {
  font-size: 27px !important;
}

.modal-cases .MuiDialog-container .MuiPaper-root .MuiDialogContent-root {
  overflow-y: scroll !important;
  overflow-x: hidden !important;
  padding: 10px 35px !important;
}

.modal-case .MuiDialog-container .MuiPaper-root .MuiButtonBase-root {
  top: -4px !important;
}

.contactng-sec {
  font-size: 19px !important;
}

.modal-case .form-sec .MuiBox-root .MuiFormControl-root {
  padding-right: 0px !important;
}

.invalid-feedbackcontact {
  position: relative;

  .error-msg {
    position: absolute;
    color: $redcolor;
    font-size: 14px;
    padding-top: 0px;
  }
}

.serv-inner-desk {
  display: block;
}

.serv-inner-mob {
  display: none;
}

.veri-secss {
  display: block;
}

// feedback section code start

.service-banner {
  bottom: 12vh;

  .service-btn-sec {
    margin-top: 8px;
    margin-bottom: 0px;
    line-height: 42px;

  }
}

.sev-banr-btn {
  padding: 12px 18px 10px 18px !important;
}

.leader-sec {
  margin-bottom: 0px !important;
}

.talk-sec {
  margin-bottom: 0px !important;

}

.talk-btn-sec {
  text-transform: uppercase;
}

.explore-btn {
  background-color: transparent;
  color: $blue-color;
  margin-left: 15px;

  &:hover {
    background-color: $blue-color;
    color: $bgcolor;
  }
}

.touch-sec {
  margin-top: 40px !important;
  margin-bottom: 0px !important;

  .touch-btn-se {
    text-transform: uppercase;
  }
}

.leader-sec {
  color: $blue-color;
  font-weight: 700;

  a {
    font-size: 17px;
    cursor: pointer;
    transition: background 0.5s ease;

    i {
      margin-left: 7px;
      position: relative;
      top: 1px;


    }
  }
}

.arrow-one {
  animation: slide1 1s ease-in-out infinite;
  margin-left: 9px;
}

@keyframes slide1 {

  0%,
  100% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(5px, 0);
  }
}

.prev-sec-service {
  margin-right: 40px;
}

.ser-page-sec {
  a {
    color: $blue-color !important;
    text-decoration: none !important;
  }
}

.prev-sec-service {
  i {
    margin-right: 10px;
  }
}

.btn-ser-explre {
  margin-top: 80px;
}

.talk-to-sec {
  margin-top: 0px;
  margin-bottom: 30px;
  float: right;

  display: inline-block;
  /* background-color: #FF9800; */
  // width: 40px;
  /* height: 50px; */
  text-align: center;
  border-radius: 4px;
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 999;
}

.talk-btn-sec {
  img {
    height: 110px;
  }
}

.book-demo-sec {
  position: relative;
  display: block;

  .btn-book-sec {
    position: absolute;
    right: 0;
    bottom: 0px;
    text-transform: uppercase;
  }
}

.case-new-insd {
  margin-bottom: 0px;

  h3 {
    margin-bottom: 0px;
  }


}

.case-new-mar {
  margin-bottom: 120px;
}

.final-modal .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .MuiTypography-root .form-sec-main .form-sec {
  margin-bottom: 0px !important;
}

.final-modal .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .MuiTypography-root .form-sec-main .form-radio-sec .MuiFormControl-root label .MuiRadio-root {
  top: 2px !important
}

.final-modal .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .MuiTypography-root .form-sec-main .form-radio-sec .MuiFormControl-root label .MuiTypography-root {

  margin-top: 0px !important;
  display: block;
}

.final-modal .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .MuiTypography-root .form-sec-main .form-radio-sec .MuiFormControl-root label .MuiRadio-root {
  height: 0px !important;
}

.final-modal .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .MuiTypography-root .form-sec-main .form-radio-sec .MuiFormControl-root label {
  display: flex !important;
  margin-right: 0px !important;
  margin-bottom: 15px !important;
}

.final-modal .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .button-sec .btn-yes {
  width: 100% !important;
  font-size: 17px !important;
}

.final-modal .MuiDialog-container .MuiPaper-root .MuiDialogContent-root {
  padding: 10px 26px !important;
}

.final-modal .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .MuiTypography-root .form-sec-main .form-radio-sec .MuiFormControl-root label {
  align-items: initial !important;
}

.final-modal .MuiDialog-container .MuiPaper-root {
  padding: 0px 0px 10px 0px !important;
}

// otp modal secton start
.otp-modal-new .MuiDialog-container .MuiPaper-root {
  height: auto !important;
}

.otp-head {
  font-size: 18px !important;
  font-weight: 700 !important;
  line-height: 27px !important;
}

.modal-cases .MuiDialog-container .MuiPaper-root .MuiDialogContent-root {
  padding: 15px 28px !important;
}

.otp-sec-input {
  padding: 10px 0px !important;
  margin-right: 20px !important;
  box-shadow: 2px 1px 5px #4d4d4d30 !important;
}

.otp-form {
  width: 100% !important;
  max-width: 300px !important;
  margin: 0 auto !important;
}

.otp-modal-new .btn-otp {
  width: 100%;
  max-width: 300px !important;
  margin: 0 auto;

  .btn-sub {
    font-size: 16px !important;
  }
}

.otp-modal-new .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .btn-otp .btn-sub {
  font-size: 15px !important;
}

.otp-modal-new .MuiDialog-container .MuiPaper-root .MuiDialogContent-root {
  overflow-y: initial !important;
}

.otp-modal-new .MuiDialog-container .MuiPaper-root .MuiButtonBase-root {
  right: -30px !important;
}

.otp-modal-new .MuiDialog-container .MuiPaper-root .MuiDialogContent-root {
  overflow-x: visible !important;
}

.otp-modal-new .width-otp {
  width: 100% !important;
  max-width: 340px !important;
  margin: 0 auto !important;
  font-size: 14px;

  .error-msg-sec {
    .error-msg {
      width: 100% !important;
      font-size: 14px !important;
    }
  }
}

.otp-modal-new .MuiDialogContent-root .validate-sec-mode .error-msg-sec .error-msg {
  font-size: 14px !important;
}

.otp-modal-new .MuiDialog-container .MuiPaper-root {
  padding: 20px 0px 20px 0px !important;
}

// otp section end

.modal-thanku-connet .MuiDialog-container .MuiPaper-root .MuiDialogContent-root h2 {
  font-size: 26px;
  margin-bottom: 13px;
  color: $successcolor;
}

.modal-thanku-connet .MuiDialog-container .MuiPaper-root .MuiDialogContent-root p {
  font-size: 16px;
  line-height: 25px;
}

.modal-thanku-connet .MuiDialog-container .MuiPaper-root {
  max-width: 460px;
  padding: 40px 25px 35px 25px;
}

.modal-thanku-connet .MuiDialog-container .MuiPaper-root .MuiButtonBase-root svg {
  font-size: 27px !important;
}

.case-pre-new {
  margin-right: 35px;
}

.left-arrow {
  margin-right: 13px !important;
}

.case-main-arow-sec {
  margin-top: 0px !important;

  .button-sec {
    margin-top: 0px !important;
  }
}

.with-video-case {
  margin-top: 40px !important;
}

.date-modal-case {
  position: relative;
  top: -20px;
  margin-top: 5px !important;

  .MuiStack-root {
    padding-top: 0px !important;

    .MuiFormControl-root {
      overflow: hidden !important;

      label {
        top: 0px;
        left: -12px;
        font-family: $main-font !important;
        padding: 8px 0px !important;
        height: auto !important;
        font-size: 15px !important;
        color: $colortextfiled !important;
      }

      .MuiFormLabel-filled {
        top: 7px;
        left: -13px;
        color: $blue-color !important;
      }

      .MuiInputBase-root {
        .MuiOutlinedInput-notchedOutline {
          border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
          border-left: none !important;
          border-right: none !important;
          border-top: none !important;
          border-radius: 0px !important;
        }

        .MuiInputBase-input {
          font-family: $main-font !important;
          padding: 16.5px 0px 12px 0px !important;
          position: relative;
          top: 5px;
        }
      }
    }
  }
}

.form-modals {
  margin-top: 15px !important;
}

.MuiButton-textPrimary {
  color: $blue-color !important;
}

.MuiPickersDay-root.Mui-selected {
  background-color: $blue-color !important;
  color: $bgcolor !important;
}

.MuiClockPointer-root {
  background-color: $blue-color !important;
}

.MuiClockPointer-thumb {
  border: 16px solid $blue-color !important;
  background-color: $blue-color !important;
}

.data-modal-mob {
  position: relative;
  top: -15px !important
}

.date-modal .invalid-feedback {
  height: 3px;
  margin-left: 0px !important;
}

.date-modal .invalid-feedback .error-msg {
  padding-top: 0px;
}

.more-btn {
  color: $bgcolor !important;
}

.more-sec-main {
  a {
    color: $bgcolor !important;
  }
}

.more-btn:hover {
  color: $blue-color !important;
}

.final-no-modal .MuiDialog-container .MuiPaper-root {
  height: 67vh;
}

.invalid-feedback {
  margin-left: 0px !important;
}

.final-modal .new-demo-head {
  margin: 0px 0px 5px 0px !important;
  font-size: 18px !important;
  line-height: 21px !important;
  display: inline-block !important;
  position: relative !important;
  padding-top: 4px !important;
}

.final-modal .new-demo-para {
  font-size: 18px !important;
  line-height: 30px !important;
  margin: 0px 0px !important;
}

.final-modal .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .new-demo-para {
  margin-top: 0px !important;
}

.final-modal .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .new-demo-head {
  margin-top: 7px !important;
}

.prev-sec-service {
  position: relative;
}

.prev-sec-service:after {
  content: "";
  display: block;
  width: 2px;
  height: 37px;
  background: #0078ae82;
  margin-top: -29px;
  position: absolute;
  right: -22px;
}

.banner-thank {
  // background-color: #0000002b;
  margin-bottom: 0px;
  padding: 80px 60px;
  box-shadow: 0 2px 10px rgb(0 0 0 / 4%);
  bottom: 20vh;

  h2 {
    margin-bottom: 0px;
    font-size: 95px;
  }
}

.thankyou-sec {
  padding: 180px 0px 110px 0px;
  background-image: url("../img/bg.png");
  background-size: 100% 105%;

  background-repeat: no-repeat;

  h2 {
    font-size: 37px;
    font-weight: 700;
    margin: 15px 0px 22px 0px;
  }

  p {
    font-size: 17px;
    font-weight: 600;
    line-height: 26px;
    margin-bottom: 0px;

  }

  .vasestremco {
    font-size: 12px !important;
    line-height: 23px !important;
    color: #0078ae !important;
    padding-top: 15px !important;
    font-style: italic;
    font-weight: 700;
  }


}

.thank-you-inner {
  width: 100%;
  max-width: 630px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.161);
  margin: 0 auto;
  display: block;
  position: relative;
  padding: 30px 30px;
  background-color: $bgcolor;
}

// checked thank you code start
#check-group {
  animation: 0.32s ease-in-out 1.03s check-group;
  transform-origin: center;
}

#check-group #check {
  animation: 0.34s cubic-bezier(0.65, 0, 1, 1) 0.8s forwards check;
  stroke-dasharray: 0, 75px;
  stroke-linecap: round;
  stroke-linejoin: round;
}

#check-group #outline {
  animation: 0.38s ease-in outline;
  transform: rotate(0deg);
  transform-origin: center;
}

#check-group #white-circle {
  animation: 0.35s ease-in 0.35s forwards circle;
  transform: none;
  transform-origin: center;
}

@keyframes outline {
  from {
    stroke-dasharray: 0, 345.576px;
  }

  to {
    stroke-dasharray: 345.576px, 345.576px;
  }
}

@keyframes circle {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(0);
  }
}

@keyframes check {
  from {
    stroke-dasharray: 0, 75px;
  }

  to {
    stroke-dasharray: 75px, 75px;
  }
}

@keyframes check-group {
  from {
    transform: scale(1);
  }

  50% {
    transform: scale(1.09);
  }

  to {
    transform: scale(1);
  }
}


// checked thank you code end

.book-sec-project {
  width: 100%;
  position: relative;
  margin-top: 90px;
  padding: 100px 0px;

  .book-inner-projt {
    max-width: 480px;
    margin: 0 auto;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.161);
    background-color: $bgcolor;
    padding: 30px 44px;
  }

}

.bg-book {
  padding: 60px 0px 80px 0px;
  background-image: url("../img/bg.png");
  background-size: 100% 105%;
  background-repeat: no-repeat;
}

.bok-iner-head {
  width: 100%;
  max-width: 480px;
  margin: 0 auto;

  h2 {
    font-size: 23px;
    line-height: 35px;
    margin-bottom: 21px;
    font-weight: 600;
    text-align: center;
  }
}

.form-select-sec {
  .MuiFormControl-root {
    margin: 0px !important;
    margin-bottom: 30px !important;
    width: 100% !important;

    .MuiFormLabel-root {
      font-family: $main-font !important;
      font-size: 15px !important;
      color: $colortextfiled !important;
    }

    .MuiFormLabel-filled {
      color: $blue-color !important;
      top: 5px !important;
      left: -1px !important;
    }

    .MuiInputBase-root {
      font-family: $main-font !important;
      text-align: left !important;
    }
  }
}

.MuiMenuItem-root {
  font-family: $main-font !important;
}

.info-privacy-sec {
  text-align: left;
}

.data-new-time {
  margin-top: 9px !important;
}

.cse-new {
  .btn-submit {
    width: 100%;
    max-width: 220px;
    font-size: 17px;
    line-height: 22px;
  }
}

.cse-new {
  margin-top: 30px;
}

.MuiList-root {
  width: 100% !important;
}

.prev-no-line:after {
  display: none !important;
}

.eff-btn-margin {
  position: relative;
  top: 55px;
}

.line-after-case {
  position: relative;
}

.line-after-case:after {
  content: "";
  display: block;
  width: 2px;
  height: 34px;
  background: rgba(0, 120, 174, 0.5098039216);
  margin-top: -29px;
  position: absolute;
  right: -19px;
}

.book-new-thanyou {
  text-decoration: none !important;
  margin-top: 20px !important;
  position: relative;
  display: block;
  font-weight: 700;

  a {
    color: $blue-color !important;
    text-decoration: none !important;
    margin-right: 0px !important;
  }

}

.anpara {
  margin-top: 30px;
  margin-bottom: 30px;
}


// casestudy pdf section start
.casestudy-pdf-main {
  width: 100%;
  position: relative;
  display: block;
  padding: 20px 0px 40px 0px;
  // max-width: 1120px;
  margin: 0 auto;
  margin-top: 20px;
  border-top: 5px solid $blue-color !important;

}

.casestudypf-head {
  position: relative;
  width: 100%;

  img {
    height: 28px;
    margin-bottom: 24px;
  }

  h1 {
    font-size: 26px;
    line-height: 34px;
    font-weight: 600;
    margin-bottom: 5px;
    color: $greytextcolor;
  }

  p {
    font-size: 20px;
    font-weight: 500;
    line-height: 33px;
  }

}

.pdf-interven-sec h3 {
  float: left;
  padding: 32px 0px 0px 0px;
  margin-bottom: 0px;
  font-size: 22px;
}

.pdf-interven-sec img {
  position: relative;
  top: -36px;
  height: 85px;
  right: 0px;
}

.case-stud-sec-pdf {
  padding: 40px 0px;
}

.case-new-insd-pdf h3 {
  font-size: 28px;
}

.case-new-mar-pdf {
  margin-bottom: 40px;
}

.margn-case-pdf {
  margin-top: 45px;
}

.bg-sec-newss-pdf {
  background-image: url("../img/bg.png");
  background-size: initial;
  background-repeat: repeat-y;
}


@media print {
  @page {
    margin-top: 0;
    margin-bottom: 0;
  }

  body {
    -webkit-print-color-adjust: exact;
  }

  .pagebrpr {
    page-break-after: always;
  }
}

@page {

  margin-top: 20px;
  margin-bottom: 20px;
}

// casestudy pdf section end

.banner-isde-img img {
  width: 100%;
}


// new menu code start from here

.nav-new-menu a{
  padding: 5px 5px;
  // margin-left: 11px;
}

.nav-new-menu{
  margin-left: 5px;
}

.nav-new-menu a.active:before {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  height: 15px;
  width: 15px;
  border-right: 2px solid $blue-color;
  border-bottom: 2px solid $blue-color;

  transition: ease .3s;
}

.nav-new-menu a.active:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 15px;
  width: 15px;
  border-left: 2px solid $blue-color;
  border-top: 2px solid $blue-color;

  transition: ease .3s;
}

.nav-new-menu a.active:hover:after, .nav-new-menu a.active:hover:before {
  width: calc(100% - 0px) !important;
  height: calc(100% - 0px) !important;
  background-color: transparent !important;
}
.nav-link:before {
  transition: ease .3s;
}
.nav-link:after {
  transition: ease .3s;
}

.nav-link:hover:before {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  height: 15px !important;
  width: 15px !important;
  border-right: 2px solid $blue-color !important;
  border-bottom: 2px solid $blue-color !important;
  // transition: linear .3s;
}

.nav-link:hover:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 15px !important;
  width: 15px !important;
  border-left: 2px solid $blue-color !important;
  border-top: 2px solid $blue-color !important;
  // transition: linear .3s;
}
.nav-new{
  padding: 11px 15px 8px 15px !important;
  margin-left:5px;
}

// new menu code end from here



.footer-center ul {
  display: flex;
  flex-direction: column;
  align-items: start;
  list-style-type: none;

  li {
    

    a {
      position: relative;
      display: block;
      padding: 0px 0;
    
  
      text-decoration: none;
     
      transition: 0.5s;

      &::after {
        position: absolute;
        content: "";
        top: 100%;
        left: 17px;
        width: 85%;
        height: 1px;
        background: $bgcolor;
        transform: scaleX(0);
        transform-origin: right;
        transition: transform 0.5s;
      }

      &:hover {
        color: $bgcolor;
      }

      &:hover::after {
        transform: scaleX(1);
        transform-origin: left;
      }
    }
  }
}

.footer-bottom-right ul {
  // display: flex;
  // flex-direction: column;
  // align-items: start;
  // list-style-type: none;
  padding-left: 0px;
  list-style-type: none;
  display: ruby-text;
  li {
    

    a {
      position: relative;
      display: block;
      padding: 0px 0;
    
  
      text-decoration: none;
     
      transition: 0.5s;

      &::after {
        position: absolute;
        content: "";
        top: 100%;
        left: 0;
        width: 100%;
        height: 1px;
        background: $bgcolor;
        transform: scaleX(0);
        transform-origin: right;
        transition: transform 0.5s;
      }

      &:hover {
        color: $bgcolor;
      }

      &:hover::after {
        transform: scaleX(1);
        transform-origin: left;
      }
    }
  }
}

.thankyou-msg{
  width: 100%;
  max-width: 470px;
  margin: 0 auto;
  text-align: center;
}

.thankyou-callback{
  width: 100%;
  max-width: 350px !important;
  margin: 0 auto;
  text-align: center;
  line-height: 28px !important;
}

.thank-amaze{
  width: 100%;
  max-width: 469px;
  margin: 0 auto;
  text-align: center;
  line-height: 29px !important;
}


.thank-presting{
  width: 100%;
  max-width: 490px;
  margin: 0 auto;
  text-align: center;
  line-height: 29px;

}


.data-scroll-modal{
  .MuiStack-root{
    overflow: hidden !important;
    .calend-icon{
      position: relative !important;
      width: 100%;
      display: block;
      margin-top: 0px !important;
      cursor: pointer;
      svg{
        position: absolute;
        right: 0px;
        bottom: 8px;
        color: #585858;
        font-size: 20px;
      }
    }
  }
}

.para-cookk{width: 100%;
    margin-bottom: 10px;
    margin-top: 10px;
  color: $greytextcolor;
  text-align: left;
  }

  .declinbtn{
    color:$blue-color !important;
    top: 20px;
    position: relative;
  }
  .accptbtn{
    top: 20px;
    position: relative;
  }
  .head-cookie{
    color:$greytextcolor !important;
    font-size: 19px;
    font-weight: 700;
    text-align: left;
    margin-bottom: 15px;
  }


  



  

.up-down-anim{
  /* animation: up-down linear 4s;
  animation-iteration-count: infinite; */
  transform-origin: 50% 50%;
  animation:up-down 7.5s ease-in-out infinite;
  /* -webkit-animation: up-down linear 4s;
  -webkit-animation-iteration-count: infinite;
  -webkit-transform-origin: 50% 50%;
  -moz-animation: up-down linear 4s;
  -moz-animation-iteration-count: infinite;
  -moz-transform-origin: 50% 50%;
  -o-animation: up-down linear 4s;
  -o-animation-iteration-count: infinite;
  -o-transform-origin: 50% 50%;
  -ms-animation: up-down linear 4s;
  -ms-animation-iteration-count: infinite;
  -ms-transform-origin: 50% 50%; */
}

/* @keyframes up-down{
  0% {
    transform:  translate(1px,20px)  ;
  }
  24% {
    transform:  translate(1px,30px)  ;
  }
  50% {
    transform:  translate(1px,12px)  ;
  }
  74% {
    transform:  translate(1px,22px)  ;
  }
  100% {
    transform:  translate(1px,22px)  ;
  }
} */

@keyframes up-down {
  0% {
    transform: translate(0);
  }
  12.5% {
    transform: translate(0, 12%);
  }
  25% {
    transform: translate(0);
  }
  37.5% {
    transform: translate(0, 12%);
  }
  50% {
    transform: translate(0);
  }
  62.5% {
    transform: translate(0, 12%);
  }
  75% {
    transform: translate(0);
  }
  87.5% {
    transform: translate(0, 12%);
  }
  100% {
    transform: translate(0);
  }
}

.newchatimgcl{
  bottom: 10px;
  position: fixed;
  right: 60px;
  z-index: 1000;
}